@media only screen and (max-width: 767px) {
  body {
    font-size: 16px;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pt-70 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
  .default-btn {
    padding: 13px 25px 7px 25px;
    font-size: 16px;
  }
  .optional-btn {
    padding: 13px 30px 7px 30px;
    font-size: 16px;
  }
  .section-title {
    max-width: 100%;
    margin-bottom: 35px;
    margin-left: 0;
    margin-right: 0;
    margin-top: -5px;
  }
  .section-title .sub-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .section-title h2 {
    font-size: 28px;
  }
  .container {
    max-width: 100%;
  }
  p {
    font-size: 14px;
  }
  .form-control {
    font-size: 12px;
  }
  .main-banner {
    padding-top: 185px;
    padding-bottom: 60px;
  }
  .main-banner::before {
    display: none;
  }
  .main-banner-content {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .main-banner-content .sub-title {
    margin-bottom: 13px;
    letter-spacing: 5px;
    font-size: 18px;
  }
  .main-banner-content .logo {
    margin-bottom: 20px;
  }
  .main-banner-content h6 {
    font-size: 18px;
  }
  .main-banner-content .btn-box {
    margin-top: 30px;
  }
  .main-banner-content .btn-box .default-btn {
    margin-right: 8px;
  }
  .main-banner-content .btn-box .optional-btn {
    margin-left: 8px;
  }
  .banner-video-slides {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }
  .banner-video-slides .owl-item {
    padding: 5px;
  }
  .banner-video-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 25px;
    left: -10px;
  }
  .banner-video-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    right: -10px;
  }
  .banner-video-slides.owl-theme .owl-dots {
    margin-top: 10px;
  }
  .banner-video-slides.owl-theme .owl-dots .owl-dot span {
    width: 30px;
    margin: 0 5px;
  }
  .banner-video-box::before {
    margin: -5px;
  }
  .banner-video-box .content .title {
    margin-top: 20px;
    font-size: 15px;
  }
  .banner-video-box .content .video-btn {
    width: 60px;
    line-height: 60px;
    height: 60px;
    font-size: 28px;
  }
  .single-banner-item {
    padding-top: 165px;
    padding-bottom: 120px;
  }
  .single-banner-item .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
  .home-slides .main-banner-content {
    padding-right: 0;
    text-align: center;
  }
  .home-slides.owl-theme .owl-dots {
    bottom: 60px;
  }
  .home-slides.owl-theme .owl-nav {
    display: none !important;
  }
  .main-banner-image {
    margin-top: 30px;
  }
  .hero-banner-area {
    padding-bottom: 60px;
    padding-top: 120px;
  }
  .hero-banner-image {
    margin-right: 0;
    width: 100%;
    border-radius: 0;
    height: 100%;
  }
  .hero-banner-image img {
    border-radius: 0;
  }
  .hero-banner-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin-left: 0;
  }
  .hero-banner-content .content {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    -webkit-transform: unset;
            transform: unset;
    z-index: 1;
    text-align: center;
    padding: 30px 20px;
  }
  .hero-banner-content .content h1 {
    font-size: 28px;
    margin-bottom: 12px;
  }
  .hero-banner-content .content .btn-box {
    margin-top: 25px;
    margin-bottom: -10px;
  }
  .hero-banner-content .content .btn-box .video-btn {
    position: relative;
    margin-left: 10px;
    font-size: 15px;
    top: -15px;
  }
  .hero-banner-content .content .btn-box .video-btn i {
    width: 40px;
    height: 40px;
    line-height: 42px;
    margin-right: 5px;
    margin-top: 0;
  }
  .hero-banner-content::before {
    border-radius: 0;
    opacity: 1;
  }
  .banner-area {
    padding-top: 150px;
    padding-bottom: 60px;
  }
  .banner-content {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .banner-content .sub-title {
    margin-bottom: 13px;
    letter-spacing: 5px;
    font-size: 18px;
  }
  .banner-content .logo {
    margin-bottom: 20px;
  }
  .banner-content h6 {
    font-size: 18px;
  }
  .banner-wrapper-area {
    padding-top: 180px;
    padding-bottom: 70px;
  }
  .banner-wrapper-content {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .banner-wrapper-content .logo {
    margin-bottom: 35px;
  }
  .banner-wrapper-content h1 {
    font-size: 30px;
  }
  .banner-wrapper-content .sub-title {
    letter-spacing: 5px;
    font-size: 17px;
  }
  .single-matches-box {
    margin-bottom: 50px;
  }
  .single-matches-box .matches-team {
    padding-left: 68px;
    padding-top: 15px;
  }
  .single-matches-box .matches-team img {
    max-width: 90px;
  }
  .single-matches-box .matches-team .content h3 {
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 18px;
    padding-right: 20px;
    background-color: #1a0c09;
    background-image: unset !important;
  }
  .single-matches-box .matches-team .content .watch-list {
    padding-right: 20px;
  }
  .single-matches-box .matches-team.right-image {
    padding-left: 0;
    padding-right: 68px;
  }
  .single-matches-box .matches-team.right-image .content h3 {
    padding-left: 20px;
  }
  .single-matches-box .matches-team.right-image .content ul {
    padding-left: 20px;
  }
  .single-matches-box .matches-result {
    padding-top: 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .single-matches-box .matches-result ul li {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 18px;
  }
  .single-matches-box .matches-result ul li::before {
    left: -23px;
    top: 5px;
    font-size: 22px;
  }
  .single-matches-box .matches-result .date-time {
    margin-top: 5px;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 600;
  }
  .matches-tabs .nav-tabs {
    display: block;
    margin-bottom: 40px;
  }
  .matches-tabs .nav-tabs .nav-item {
    margin-bottom: 0;
    display: block;
  }
  .matches-tabs .nav-tabs .nav-item .nav-link {
    padding: 14px 45px 7px 20px;
    letter-spacing: 1px;
    font-size: 18px;
    display: block;
    text-align: center;
    -webkit-clip-path: unset !important;
            clip-path: unset !important;
    border-bottom: 1px solid #272626;
  }
  .matches-tabs .nav-tabs .nav-item:first-child {
    border-top: 1px solid #272626;
  }
  .matches-tabs .tab-content {
    background-color: transparent;
    padding: 0;
  }
  .awards-image .shape {
    -webkit-animation: unset !important;
            animation: unset !important;
  }
  .awards-content {
    margin-top: 35px;
  }
  .awards-content .sub-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .awards-content h2 {
    font-size: 28px;
  }
  .awards-content .shape {
    display: none;
  }
  .awards-content .image {
    margin-top: 35px;
  }
  .awards-content .row {
    padding-left: 0;
    padding-right: 0;
  }
  .search-overlay .search-overlay-form {
    max-width: 290px;
    width: 290px;
  }
  .modal.right .modal-dialog {
    width: 100%;
  }
  .team-area .section-title {
    max-width: 100%;
    text-align: center;
    margin-bottom: 35px;
    margin-left: 0;
    margin-right: 0;
  }
  .single-team-member .content {
    padding: 15px;
  }
  .single-team-member .content h3 {
    font-size: 20px;
  }
  .single-team-member .content span {
    margin-top: 5px;
    font-size: 15px;
  }
  .top-team-slides.owl-theme .owl-dots .owl-dot span {
    width: 30px;
  }
  .top-team-slides.owl-theme .owl-nav {
    display: none !important;
  }
  .services-area .section-title {
    text-align: center;
    margin-bottom: 35px;
  }
  .single-services-box {
    text-align: center;
  }
  .single-services-box .icon {
    font-size: 40px;
  }
  .single-services-box h3 {
    margin-bottom: 10px;
    font-size: 21px;
  }
  .row .col-lg-3:nth-child(2) .single-services-box, .row .col-lg-3:nth-child(3) .single-services-box {
    margin-bottom: 30px;
  }
  .shape1, .shape2, .shape3, .shape4 {
    display: none;
  }
  .single-magazine-box .content {
    padding: 20px;
  }
  .single-magazine-box .content .meta li {
    font-size: 15px;
  }
  .single-magazine-box .content .meta li::before {
    top: 6px;
  }
  .single-magazine-box .content h3 {
    font-size: 18px;
  }
  .col-lg-6 .single-magazine-box .content {
    width: 100%;
  }
  .blog-area .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .single-blog-post-item .post-image {
    background-image: unset !important;
    height: auto;
  }
  .single-blog-post-item .post-image img {
    display: inline-block;
  }
  .single-blog-post-item .post-content {
    padding: 25px 20px;
  }
  .single-blog-post-item .post-content .sub-title {
    font-size: 16px;
  }
  .single-blog-post-item .post-content h3 {
    font-size: 22px;
  }
  .blog-slides.owl-theme .owl-nav {
    display: none;
  }
  .single-blog-post .post-content .sub-title {
    font-size: 16px;
  }
  .single-blog-post .post-content h3 {
    font-size: 22px;
  }
  .blog-slides-two.owl-theme .owl-nav {
    display: none !important;
  }
  .blog-slides-two.owl-theme .owl-dots {
    margin-top: -5px;
  }
  .blog-slides-two.owl-theme .owl-dots .owl-dot span {
    width: 30px;
    margin: 0 5px;
  }
  .single-blog-post-box .post-content {
    padding: 20px;
  }
  .single-blog-post-box .post-content h3 {
    font-size: 20px;
  }
  .single-blog-post-box .post-content .post-meta {
    margin-bottom: 12px;
  }
  .single-blog-post-box .post-content .post-meta li {
    margin-right: 20px;
    font-size: 13px;
  }
  .single-blog-post-box .post-content .post-meta li::before {
    top: 2px;
  }
  .single-blog-post-box .post-content .read-more-btn {
    margin-top: 12px;
    font-size: 14px;
  }
  .single-products-box {
    text-align: center;
  }
  .single-products-box .products-image .add-to-cart-btn {
    bottom: 15px;
    opacity: 1;
    visibility: visible;
  }
  .single-products-box .products-image a img {
    width: 100%;
  }
  .single-products-box .products-content {
    margin-top: 25px;
  }
  .single-products-box .products-content h3 {
    font-size: 20px;
  }
  .single-products-box .products-content .price {
    margin-top: 5px;
    font-size: 17px;
  }
  .products-slides.owl-theme .owl-dots .owl-dot span {
    width: 30px;
  }
  .products-slides.owl-theme .owl-nav {
    display: none !important;
  }
  .single-products-item .products-image .bg-image img {
    width: 100% !important;
  }
  .single-products-item .products-image .add-to-cart-btn {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
  }
  .partner-area.pt-0-res {
    padding-top: 0;
  }
  .partner-area .section-title {
    margin-bottom: 30px;
  }
  .partner-area .section-title .sub-title {
    font-size: 20px;
  }
  .partner-shape1 {
    display: none;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    border-radius: 0;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image {
    width: auto;
    height: auto;
    background-image: unset !important;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image img {
    display: inline-block;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content {
    padding: 25px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content h3 {
    letter-spacing: 1px;
    margin-bottom: 8px;
    font-size: 22px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info {
    margin-top: 20px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li {
    margin-right: 15px;
    padding-left: 22px;
    font-size: 18px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li i {
    font-size: 14px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-date {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    border-radius: 0;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date {
    border-radius: 0;
    padding: 25px;
    font-size: 20px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date h3 {
    font-size: 20px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date span {
    margin-bottom: 7px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date p {
    font-size: 15px;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date i {
    font-size: 100px;
  }
  .single-tournaments-box .tournaments-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    max-width: 100%;
  }
  .single-tournaments-box .tournaments-image .image {
    width: auto;
    height: auto;
    background-image: unset !important;
  }
  .single-tournaments-box .tournaments-image .image img {
    display: inline-block;
  }
  .single-tournaments-box .tournaments-content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    max-width: 100%;
  }
  .single-tournaments-box .tournaments-content .content {
    padding: 25px;
  }
  .single-tournaments-box .tournaments-content .content h3 {
    font-size: 22px;
  }
  .single-tournaments-box .tournaments-content .content .meta {
    margin-top: -15px;
  }
  .single-tournaments-box .tournaments-content .content .meta li {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    font-size: 16px;
    padding-top: 15px;
  }
  .single-tournaments-box .tournaments-content .content .meta li span {
    margin-bottom: 3px;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .single-tournaments-box .tournaments-content .content .info {
    margin-top: 20px;
  }
  .single-tournaments-box .tournaments-content .content .info li {
    margin-right: 15px;
    padding-left: 22px;
    font-size: 18px;
  }
  .single-tournaments-box .tournaments-content .content .info li i {
    font-size: 14px;
  }
  .subscribe-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
  .subscribe-inner .sub-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .subscribe-inner h2 {
    font-size: 28px;
  }
  .subscribe-inner .newsletter-form {
    max-width: 100%;
    margin-top: 25px;
    margin-left: 0;
    margin-right: 0;
  }
  .subscribe-inner .newsletter-form .default-btn {
    position: relative;
    height: auto;
    padding-top: 13px;
    margin-top: 15px;
  }
  .subscribe-inner .newsletter-form .input-newsletter {
    padding: 5px 0 0 40px;
    font-size: 16px;
  }
  .subscribe-inner .newsletter-form label {
    left: 10px;
    top: 10px;
    font-size: 20px;
    -webkit-transform: unset;
            transform: unset;
  }
  .section-title .newsletter-form {
    max-width: 100%;
    margin-top: 25px;
    margin-left: 0;
    margin-right: 0;
  }
  .section-title .newsletter-form .default-btn {
    position: relative;
    height: auto;
    padding-top: 13px;
    margin-top: 15px;
  }
  .section-title .newsletter-form .input-newsletter {
    padding: 5px 0 0 40px;
    font-size: 16px;
  }
  .section-title .newsletter-form label {
    left: 10px;
    top: 10px;
    font-size: 20px;
    -webkit-transform: unset;
            transform: unset;
  }
  .featured-games-content .content {
    text-align: center;
    padding: 95px 20px 30px;
  }
  .featured-games-content .content .sub-title {
    font-size: 16px;
  }
  .featured-games-content .content h2 {
    margin-bottom: 12px;
    font-size: 22px;
  }
  .featured-games-content .content .read-more-btn {
    font-size: 17px;
  }
  .featured-games-content .owl-theme .owl-nav {
    left: 20px;
    top: 30px;
  }
  .featured-games-content .owl-theme .owl-nav [class*=owl-] {
    font-size: 20px;
  }
  .featured-games-content .owl-theme .owl-dots {
    right: 20px;
    top: 35px;
  }
  .single-live-stream-item .content {
    padding: 20px;
  }
  .single-live-stream-item .content h3 {
    margin-bottom: 5px;
    font-size: 22px;
  }
  .single-live-stream-item .content .meta li {
    font-size: 15px;
  }
  .single-live-stream-item .video-btn {
    right: 20px;
    top: 20px;
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 23px;
  }
  .single-games-box .content h3 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .single-games-box .content .btn-box .playstore-btn {
    margin-right: 0;
  }
  .single-games-box .content .btn-box .applestore-btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .single-games-box::before {
    bottom: 29px;
  }
  .games-slides.owl-theme .owl-nav {
    display: none;
  }
  .games-slides.owl-theme .owl-dots {
    margin-top: 10px;
  }
  .single-feedback-item {
    padding: 80px 20px 20px;
  }
  .single-feedback-item p {
    font-size: 14px;
  }
  .single-feedback-item span {
    font-size: 18px;
  }
  .single-feedback-item::before {
    left: 20px;
    top: 20px;
    font-size: 35px;
  }
  .single-top-team-item h3 {
    font-size: 25px;
  }
  .widget-area {
    padding-left: 0;
    margin-top: 40px;
  }
  .widget-area.widget-left-sidebar {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .widget-area .widget .widget-title {
    font-size: 20px;
  }
  .widget-area .widget_zelda_posts_thumb .item .info .title {
    font-size: 16px;
  }
  .widget-area .widget_categories ul li {
    font-size: 15px;
  }
  .widget-area .tagcloud a {
    font-size: 13px !important;
  }
  .widget-area .widget_match_list .single-match-list img {
    max-width: 55px;
  }
  .widget-area .widget_match_list .single-match-list img.team-1 {
    left: 10px;
  }
  .widget-area .widget_match_list .single-match-list img.team-2 {
    right: 10px;
  }
  .widget-area .widget_match_list .single-match-list .date {
    font-size: 14px;
  }
  .widget-area .widget_match_list .single-match-list ul li {
    font-size: 30px;
    margin-left: 12px;
    margin-right: 12px;
  }
  .widget-area .widget_match_list .single-match-list ul li::before {
    right: -15px;
    top: 4px;
    font-size: 20px;
  }
  .blog-details-desc .article-content .entry-meta ul {
    margin-bottom: -15px;
  }
  .blog-details-desc .article-content .entry-meta ul li {
    font-size: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 37px;
  }
  .blog-details-desc .article-content .entry-meta ul li i {
    font-size: 25px;
  }
  .blog-details-desc .article-content .entry-meta ul li span {
    font-size: 14px;
    margin-bottom: 0;
  }
  .blog-details-desc .article-content .entry-meta ul li a {
    font-size: 15px;
  }
  .blog-details-desc .article-content h3 {
    margin-top: 25px;
    font-size: 22px;
  }
  .blog-details-desc .article-footer {
    text-align: center;
  }
  .blog-details-desc .article-footer .article-tags {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .blog-details-desc .article-footer .article-share {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .blog-details-desc .article-footer .article-share .social {
    text-align: center;
    margin-top: 15px;
  }
  .blog-details-desc .article-video iframe {
    height: 230px;
  }
  .blog-details-desc .article-image-slides.owl-theme .owl-nav {
    opacity: 1;
    visibility: visible;
  }
  .blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-] {
    font-size: 15px;
    width: 35px;
    height: 35px;
    line-height: 34px;
    left: 10px;
  }
  .blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: 10px;
  }
  .blog-details-desc .article-author .author-profile {
    padding: 0 20px 20px;
  }
  .blog-details-desc .article-author .author-profile .author-profile-title h4 {
    font-size: 20px;
  }
  .blog-details-desc .article-author .author-profile .author-profile-title p {
    font-size: 13.5px;
  }
  blockquote, .blockquote {
    padding: 25px !important;
  }
  blockquote p, .blockquote p {
    font-size: 15px !important;
  }
  .prev-link-wrapper {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .next-link-wrapper {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-top: 25px;
  }
  .comments-area .comments-title {
    font-size: 22px;
  }
  .comments-area .comment-body {
    padding-left: 0;
  }
  .comments-area .comment-author .avatar {
    height: 50px;
    left: 0;
    position: relative;
    width: 50px;
    display: block;
    margin-bottom: 15px;
  }
  .comments-area .comment-content p {
    font-size: 14px;
  }
  .comments-area .comment-respond .comment-reply-title {
    font-size: 22px;
  }
  .comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
    font-size: 14px;
  }
  .comments-area .comment-respond .comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .comments-area .comment-respond .comment-form-email {
    width: 100%;
    padding-left: 0;
  }
  .comments-area .comment-respond .form-submit input {
    padding: 10px 25px 9px;
    font-size: 14px;
  }
  .zelda-grid-sorting {
    text-align: center;
  }
  .zelda-grid-sorting .select-box {
    text-align: center;
    margin-top: 15px;
  }
  .history-area {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .history-content .video-btn {
    width: 70px;
    line-height: 70px;
    height: 70px;
    font-size: 30px;
  }
  .history-content h2 {
    margin-top: 30px;
    font-size: 20px;
  }
  .cart-table table thead tr th {
    font-size: 16px;
    padding-right: 100px;
  }
  .cart-table table tbody tr td {
    font-size: 14px;
  }
  .cart-buttons .shopping-coupon-code button {
    position: relative;
    height: auto;
    padding: 13px 25px 9px;
    line-height: initial;
    font-size: 15px;
    display: block;
    width: 100%;
    margin-top: 15px;
  }
  .cart-buttons .text-right {
    text-align: center !important;
    margin-top: 25px;
  }
  .cart-totals {
    max-width: 100%;
    padding: 25px;
    margin-top: 40px;
  }
  .cart-totals h3 {
    font-size: 22px;
  }
  .cart-totals ul li {
    font-size: 16px;
  }
  .cart-totals ul li:last-child {
    font-size: 18px;
  }
  .billing-details .title {
    font-size: 22px;
  }
  .order-details {
    margin-top: 40px;
  }
  .order-details .title {
    font-size: 22px;
  }
  .order-details .order-table table thead tr th {
    font-size: 16px;
  }
  .order-details .order-table table tbody tr td {
    font-size: 14.5px;
  }
  .order-details .payment-box {
    padding: 20px;
  }
  .order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
    font-size: 15px;
  }
  .products-details-image {
    margin-bottom: 30px !important;
  }
  .products-details-desc {
    padding-left: 0;
    margin-top: 5px;
  }
  .products-details-desc h3 {
    font-size: 22px;
  }
  .products-details-desc .products-review .rating {
    font-size: 16px;
  }
  .products-details-desc .products-review .rating .rating-count {
    top: -2px;
  }
  .products-details-desc .products-meta span {
    font-size: 15px;
  }
  .products-details-desc .products-add-to-cart .input-counter input {
    height: 47px;
    font-size: 16px;
  }
  .products-details-tabs .nav {
    margin-bottom: 30px;
  }
  .products-details-tabs .nav .nav-item {
    margin-left: 10px;
    margin-right: 10px;
  }
  .products-details-tabs .nav .nav-item .nav-link {
    font-size: 17px;
  }
  .products-details-tabs .tab-content .tab-pane .products-reviews h3 {
    font-size: 22px;
  }
  .products-details-tabs .tab-content .tab-pane .products-reviews .rating {
    top: 2px;
  }
  .products-details-tabs .tab-content .tab-pane .products-reviews .rating span {
    font-size: 17px;
  }
  .products-details-tabs .tab-content .tab-pane .products-reviews .side {
    width: 20%;
  }
  .products-details-tabs .tab-content .tab-pane .products-reviews .side div {
    font-size: 15px;
  }
  .products-details-tabs .tab-content .tab-pane .products-reviews .middle {
    width: 60%;
  }
  .products-details-tabs .tab-content .tab-pane .products-review-comments h3 {
    padding-bottom: 10px;
    font-size: 20px;
  }
  .products-details-tabs .tab-content .tab-pane .products-review-comments .user-review {
    padding-left: 0;
  }
  .products-details-tabs .tab-content .tab-pane .products-review-comments .user-review img {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 15px;
  }
  .products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating span {
    display: block !important;
    top: 0;
    font-size: 17px;
    margin-left: 0;
    margin-top: 5px;
  }
  .products-details-tabs .tab-content .tab-pane .review-form-wrapper h3 {
    font-size: 20px;
  }
  .products-details-tabs .tab-content .tab-pane .review-form-wrapper form button {
    margin-top: 20px;
    padding: 12px 45px;
    font-size: 15px;
  }
  .single-stream-schedule-box {
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 18px;
  }
  .single-stream-schedule-box .date {
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: unset;
            transform: unset;
    font-size: 20px;
  }
  .single-stream-schedule-box .date br {
    display: none;
  }
  .single-stream-schedule-box .content {
    border-radius: 0;
    border-left: none;
    margin-top: 10px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .single-stream-schedule-box .content .time {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .single-stream-schedule-box .content h3 {
    font-size: 20px;
  }
  .our-experts-image {
    height: auto;
    background-image: unset !important;
  }
  .our-experts-image img {
    display: inline-block;
  }
  .our-experts-content {
    padding: 60px 15px 45px;
  }
  .our-experts-content .content {
    max-width: 100%;
    text-align: center;
  }
  .our-experts-content .content .sub-title {
    font-size: 16px;
  }
  .our-experts-content .content h2 {
    font-size: 28px;
  }
  .single-live-stream-box .content {
    padding: 18px 15px 13px;
    opacity: 1;
    visibility: visible;
    bottom: 0;
  }
  .single-live-stream-box .content h3 {
    font-size: 22px;
  }
  .single-live-stream-box .content h3 i {
    top: -3px;
  }
  .live-stream-tabs .nav-tabs {
    display: block;
  }
  .live-stream-tabs .nav-tabs .nav-item {
    margin-bottom: 0;
    display: block;
  }
  .live-stream-tabs .nav-tabs .nav-item .nav-link {
    padding: 14px 45px 7px 20px;
    letter-spacing: 1px;
    font-size: 18px;
    display: block;
    text-align: center;
    -webkit-clip-path: unset !important;
            clip-path: unset !important;
    border-bottom: 1px solid #272626;
  }
  .live-stream-tabs .nav-tabs .nav-item:first-child {
    border-top: 1px solid #272626;
  }
  .live-stream-tabs .tab-content {
    background-color: transparent;
    padding: 0;
  }
  .single-social-box {
    margin-bottom: 20px;
  }
  .single-social-box .content {
    font-size: 18px;
  }
  .single-social-box .content i {
    font-size: 22px;
    top: 5.5px;
    margin-right: 5px;
  }
  .pagination-area {
    margin-top: 15px;
  }
  .pagination-area .page-numbers {
    width: 35px;
    height: 35px;
    line-height: 39px;
    font-size: 18px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .faq-accordion-tab .tabs {
    margin-bottom: 40px;
    margin-top: -15px;
  }
  .faq-accordion-tab .tabs li {
    margin-top: 15px;
  }
  .faq-accordion-tab .tabs li a {
    padding: 12px 10px 10px;
    letter-spacing: .5px;
    font-size: 14px;
  }
  .faq-accordion .accordion .accordion-title {
    padding: 15px 15px 10px 40px;
    letter-spacing: 1px;
    font-size: 16px;
  }
  .faq-accordion .accordion .accordion-title i {
    left: 10px;
    top: 12px;
    font-size: 25px;
  }
  .faq-accordion .accordion .accordion-content {
    margin-top: 0;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 40px;
  }
  .page-title-area {
    padding-top: 150px;
    padding-bottom: 55px;
  }
  .page-title-content h1 {
    font-size: 40px;
  }
  .page-title-content h1::before, .page-title-content h1::after {
    display: none;
  }
  .page-title-content img {
    margin-bottom: 25px;
  }
  .contact-info {
    padding-right: 0;
    text-align: center;
  }
  .contact-info .sub-title {
    font-size: 16px;
  }
  .contact-info h2 {
    font-size: 32px;
    margin-bottom: 5px;
  }
  .contact-info ul li {
    margin-bottom: 30px;
    padding-left: 0;
  }
  .contact-info ul li h3 {
    margin-bottom: 8px;
    font-size: 20px;
  }
  .contact-info ul li p {
    max-width: 100%;
  }
  .contact-info ul li .icon {
    width: 60px;
    height: 60px;
    position: relative;
    font-size: 30px;
    margin: 0 auto 25px;
  }
  .contact-info ul li .social-box a {
    font-size: 20px;
    margin-right: 3px;
    margin-left: 3px;
  }
  .contact-info ul li .social-box a:first-child {
    margin-left: 0;
  }
  .contact-form {
    padding: 35px 20px;
    text-align: center;
    margin-left: 0;
    margin-top: 30px;
  }
  .contact-form h2 {
    font-size: 28px;
  }
  .contact-form form {
    text-align: left;
  }
  .contact-form form input, .contact-form form textarea {
    font-size: 15px;
  }
  .tournament-details-desc .sub-title {
    font-size: 15px;
  }
  .tournament-details-desc h3 {
    font-size: 25px;
  }
  .tournament-details-desc .tournament-meta li {
    padding-left: 50px;
    margin-right: 20px;
    font-size: 15px;
  }
  .tournament-details-desc .tournament-meta li .icon {
    font-size: 30px;
  }
  .tournament-details-desc .tournament-meta li span {
    font-size: 20px;
  }
  .match-details-desc .article-content h3 {
    margin-top: 25px;
    font-size: 22px;
  }
  .match-details-desc .article-footer {
    text-align: center;
  }
  .match-details-desc .article-footer .article-tags {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .match-details-desc .article-footer .article-share {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .match-details-desc .article-footer .article-share .social {
    text-align: center;
    margin-top: 15px;
  }
  .team-details-desc .sub-title {
    font-size: 15px;
  }
  .team-details-desc h3 {
    font-size: 25px;
  }
  .team-details-desc .team-meta li {
    padding-left: 45px;
    margin-right: 20px;
    font-size: 15px;
  }
  .team-details-desc .team-meta li .icon {
    font-size: 30px;
  }
  .team-details-desc .team-meta li span {
    font-size: 20px;
  }
  .team-details-desc .team-meta li .social a {
    margin-right: 8px;
    font-size: 18px;
  }
  .team-details-desc .player-list {
    margin-top: 35px;
  }
  .team-details-desc .streams-list {
    margin-top: 35px;
  }
  .team-details-desc .partner-list {
    margin-top: 35px;
  }
  .player-details-desc .sub-title {
    font-size: 15px;
  }
  .player-details-desc h3 {
    font-size: 25px;
  }
  .player-details-desc .player-meta li {
    padding-left: 45px;
    margin-right: 20px;
    font-size: 15px;
  }
  .player-details-desc .player-meta li .icon {
    font-size: 30px;
  }
  .player-details-desc .player-meta li span {
    font-size: 20px;
  }
  .player-details-desc .player-meta li .social a {
    margin-right: 8px;
    font-size: 18px;
  }
  .player-details-desc .player-list {
    margin-top: 35px;
  }
  .player-details-desc .streams-list {
    margin-top: 35px;
  }
  .player-details-desc .partner-list {
    margin-top: 35px;
  }
  .coming-soon-area {
    height: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .coming-soon-content {
    padding: 30px 20px;
  }
  .coming-soon-content .logo {
    margin-bottom: 25px;
  }
  .coming-soon-content h2 {
    font-size: 28px;
  }
  .coming-soon-content #timer {
    margin-top: 10px;
  }
  .coming-soon-content #timer div {
    width: 95px;
    height: 100px;
    font-size: 35px;
    margin-top: 20px;
  }
  .coming-soon-content #timer div span {
    font-size: 14px;
  }
  .coming-soon-content form {
    max-width: 100%;
    margin-top: 40px;
  }
  .coming-soon-content form .default-btn {
    display: block;
    width: 100%;
    margin-top: -5px;
  }
  .error-area {
    height: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .error-content h3 {
    margin-top: 35px;
    font-size: 28px;
  }
  .error-content p {
    max-width: 100%;
  }
  .login-form {
    padding: 30px 20px;
    margin-right: 0;
  }
  .login-form h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
  }
  .login-form form .lost-your-password-wrap {
    text-align: left;
    margin-top: 15px;
  }
  .login-form form button {
    padding: 13px 30px;
    font-size: 15px;
  }
  .register-form {
    margin-top: 40px;
    border-top: 1px solid #eeeeee;
    padding-left: 0;
    padding-top: 30px;
  }
  .register-form h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 28px;
  }
  .register-form form .lost-your-password-wrap {
    text-align: left;
    margin-top: 15px;
  }
  .register-form form button {
    padding: 13px 30px;
    font-size: 15px;
  }
  .footer-content .footer-menu {
    margin-top: 16px;
  }
  .footer-content .footer-menu li {
    margin-top: 9px;
    margin-left: 9px;
    margin-right: 9px;
  }
  .footer-content .footer-menu li a {
    font-size: 16px;
  }
  .copyright-area {
    text-align: center;
  }
  .copyright-area .lang-switcher {
    text-align: center;
    margin-top: 15px;
  }
  .copyright-area .lang-switcher select {
    width: 100%;
  }
  .copyright-area .lang-switcher::before {
    display: none;
  }
  .footer-style-two {
    padding-top: 60px;
  }
  .single-footer-widget.pl-5, .single-footer-widget.px-5 {
    padding-left: 0 !important;
  }
  .single-footer-widget.pl-3, .single-footer-widget.px-3 {
    padding-left: 0 !important;
  }
  .single-footer-widget h3 {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .single-footer-widget .footer-links-list li {
    font-size: 15px;
  }
  .single-footer-widget .footer-contact-info li {
    font-size: 15px;
  }
  .single-footer-widget .footer-contact-info li.bx-map {
    top: 4px;
  }
  .footer-bottom-area {
    margin-top: 30px;
    text-align: center;
  }
  .footer-bottom-area ul {
    text-align: center;
    margin-top: 12px;
  }
  .footer-bottom-area ul li::before {
    top: 2.5px;
  }
  .footer-map {
    display: none;
  }
  .go-top {
    width: 34px;
    height: 36px;
    font-size: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
  .single-banner-item .container-fluid {
    max-width: 540px;
  }
  .single-products-box .products-image .add-to-cart-btn {
    width: auto;
    left: 0;
    right: 0;
    -webkit-transform: unset;
            transform: unset;
    margin-left: 15px;
    margin-right: 15px;
  }
  .copyright-area {
    text-align: left;
  }
  .copyright-area .lang-switcher {
    text-align: right;
    margin-top: 0;
  }
  .copyright-area .lang-switcher span {
    margin-right: 10px;
  }
  .blog-area .container-fluid {
    max-width: 540px;
  }
  .magazine-area .container-fluid {
    max-width: 540px;
  }
  .footer-bottom-area {
    text-align: left;
  }
  .footer-bottom-area ul {
    text-align: right;
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb-100 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .pt-100 {
    padding-top: 90px;
  }
  .pb-100 {
    padding-bottom: 90px;
  }
  .ptb-70 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pt-70 {
    padding-top: 60px;
  }
  .pb-70 {
    padding-bottom: 60px;
  }
  .container {
    max-width: 720px;
  }
  .banner-video-slides.owl-theme .owl-nav [class*=owl-] {
    left: -10px;
  }
  .banner-video-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -10px;
  }
  .single-banner-item {
    padding-top: 180px;
    padding-bottom: 150px;
  }
  .single-banner-item .container-fluid {
    max-width: 600px;
    padding-left: 1px;
    padding-right: 15px;
  }
  .home-slides .main-banner-content {
    text-align: center;
    padding-right: 0;
  }
  .home-slides.owl-theme .owl-dots {
    bottom: 80px;
  }
  .main-banner-image {
    margin-top: 30px;
  }
  .hero-banner-area {
    padding-bottom: 100px;
  }
  .hero-banner-image {
    border-radius: 0;
    margin-right: 0;
    width: 100%;
    height: 100%;
  }
  .hero-banner-image img {
    border-radius: 0;
    width: 100%;
  }
  .hero-banner-content {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .hero-banner-content .content {
    padding: 50px;
    position: relative;
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  /* .hero-banner-content::before {
    border-radius: 0;
  } */
  .banner-area {
    padding-top: 230px;
    padding-bottom: 140px;
  }
  .banner-wrapper-area {
    padding-top: 250px;
    padding-bottom: 120px;
  }
  .single-matches-box {
    margin-bottom: 60px;
  }
  .single-matches-box .matches-team .content h3 {
    background-image: unset !important;
    background-color: #000000;
  }
  .awards-image .shape {
    top: 30px;
  }
  .awards-content {
    margin-top: 45px;
  }
  .awards-content .shape {
    bottom: -50px;
  }
  .single-magazine-box .content h3 {
    font-size: 25px;
  }
  .top-team-slides.owl-theme .owl-nav {
    display: none;
  }
  .team-area .section-title {
    max-width: 720px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
  }
  .products-slides.owl-theme .owl-nav {
    display: none;
  }
  .our-experts-image {
    height: auto;
    background-image: unset !important;
  }
  .our-experts-image img {
    display: inline-block;
  }
  .our-experts-content {
    padding: 100px 25px 85px;
  }
  .our-experts-content .content {
    max-width: 500px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    border-radius: 0;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image {
    background-image: unset !important;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image img {
    display: inline-block;
    width: 100%;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-content {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 65%;
            flex: 0 0 65%;
    max-width: 65%;
  }
  .single-popular-leagues-box .popular-leagues-box .popular-leagues-date {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
            flex: 0 0 35%;
    max-width: 35%;
    border-radius: 0;
  }
  .shape1, .shape2, .shape3, .shape4 {
    display: none;
  }
  .games-slides.owl-theme .owl-nav {
    display: none;
  }
  .services-area .section-title {
    max-width: 720px;
    text-align: center;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
  }
  .row .col-lg-3:nth-child(2) .single-services-box, .row .col-lg-3:nth-child(3) .single-services-box {
    margin-bottom: 30px;
  }
  .page-title-area {
    padding-top: 170px;
    padding-bottom: 70px;
  }
  .page-title-content h1 {
    font-size: 90px;
  }
  .blog-area .container-fluid {
    max-width: 720px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .single-blog-post-item .post-image {
    background-image: unset !important;
    height: auto;
  }
  .single-blog-post-item .post-image img {
    display: inline-block;
  }
  .single-blog-post-item .post-content {
    padding: 30px 25px 25px;
  }
  .contact-info {
    padding-right: 0;
  }
  .contact-form {
    margin-left: 0;
    margin-top: 40px;
  }
  .faq-accordion-tab .tabs {
    margin-bottom: 30px;
  }
  .faq-accordion-tab .tabs li {
    margin-bottom: 20px;
  }
  .single-blog-post-box .post-content {
    padding: 20px;
  }
  .single-blog-post-box .post-content h3 {
    font-size: 22px;
  }
  .widget-area {
    margin-top: 40px;
    padding-left: 0;
  }
  .widget-area.widget-left-sidebar {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .order-details {
    margin-top: 35px;
  }
  .products-details-image {
    margin-bottom: 30px !important;
  }
  .products-details-image img {
    width: 100%;
  }
  .products-details-desc {
    padding-left: 0;
    margin-top: 5px;
  }
  .register-form {
    margin-top: 40px;
    padding-left: 0;
  }
  .single-games-box .content .btn-box .playstore-btn {
    margin-right: 0;
  }
  .single-games-box .content .btn-box .applestore-btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .partner-area.pt-0-res {
    padding-top: 0;
  }
  .partner-shape1 {
    display: none;
  }
  .blog-slides-two.owl-theme .owl-nav {
    display: none;
  }
  .single-tournaments-box .tournaments-image {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .single-tournaments-box .tournaments-image .image {
    background-image: unset !important;
  }
  .single-tournaments-box .tournaments-image .image img {
    display: inline-block;
    width: 100%;
  }
  .single-tournaments-box .tournaments-content {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .history-area {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .footer-style-two {
    padding-top: 90px;
  }
  .footer-bottom-area {
    margin-top: 60px;
  }
  .footer-map {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
  .banner-video-slides.owl-theme .owl-nav [class*=owl-] {
    left: -70px;
  }
  .banner-video-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -70px;
  }
  .home-slides.owl-theme .owl-nav {
    display: none;
  }
  .home-slides .main-banner-content {
    padding-right: 0;
  }
  .home-slides .main-banner-content .sub-title {
    letter-spacing: 19px;
  }
  .single-banner-item .container-fluid {
    max-width: 960px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .hero-banner-image {
    width: 545px;
    height: 545px;
  }
  .hero-banner-content {
    width: 545px;
    height: 545px;
  }
  .hero-banner-content .content {
    padding: 85px;
  }
  .hero-banner-content .content h1 {
    font-size: 40px;
  }
  .banner-area {
    padding-top: 220px;
    padding-bottom: 140px;
  }
  .single-matches-box .matches-team img {
    max-width: 140px;
  }
  .single-matches-box .matches-result ul li {
    font-size: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .single-matches-box .matches-result ul li::before {
    left: -25px;
    top: 8px;
  }
  .shape1, .shape2, .shape3, .shape4 {
    display: none;
  }
  .top-team-slides.owl-theme .owl-nav {
    display: none;
  }
  .products-slides.owl-theme .owl-nav {
    display: none;
  }
  .single-feedback-item {
    padding: 105px 25px 40px;
  }
  .blog-area .container-fluid {
    max-width: 960px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .single-blog-post-item .post-image {
    height: auto;
    background-image: unset !important;
  }
  .single-blog-post-item .post-image img {
    display: inline-block;
  }
  .single-blog-post-item .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .blog-slides-two.owl-theme .owl-nav {
    display: none;
  }
  .contact-form {
    margin-left: 0;
  }
  .our-experts-content {
    padding: 110px 45px 100px;
  }
  .single-magazine-box .content {
    padding: 10px;
  }
  .single-magazine-box .content h3 {
    font-size: 20px;
  }
  .single-magazine-box .content .meta li {
    font-size: 15px;
  }
  .col-lg-6 .single-magazine-box .content {
    width: 80%;
  }
  .single-games-box .content .btn-box .playstore-btn {
    margin-right: 0;
  }
  .single-games-box .content .btn-box .applestore-btn {
    margin-left: 0;
    margin-top: 15px;
  }
  .featured-games-content .content {
    padding: 25px;
  }
  .featured-games-content .content h2 {
    font-size: 30px;
  }
  .featured-games-content .owl-theme .owl-nav {
    display: none;
  }
  .featured-games-content .owl-theme .owl-dots {
    right: 15px;
    top: auto;
    bottom: 25px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }
  .zelda-nav .container-fluid {
    max-width: 1170px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .single-banner-item .container-fluid {
    max-width: 1140px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .home-slides.owl-theme .owl-nav {
    display: none;
  }
  .home-slides .main-banner-content .sub-title {
    letter-spacing: 20px;
  }
  .single-matches-box .matches-result ul li {
    font-size: 55px;
  }
  .top-team-slides.owl-theme .owl-nav [class*=owl-] {
    left: -15px;
  }
  .top-team-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -15px;
  }
  .products-slides.owl-theme .owl-nav [class*=owl-] {
    left: -15px;
  }
  .products-slides.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -15px;
  }
  .blog-slides-two.owl-theme .owl-nav [class*=owl-] {
    left: -15px;
  }
  .blog-slides-two.owl-theme .owl-nav [class*=owl-].owl-next {
    left: auto;
    right: -15px;
  }
  .featured-games-content .content {
    padding: 110px 40px 45px;
  }
  .featured-games-content .content h2 {
    font-size: 30px;
  }
  .single-games-box .content .btn-box .playstore-btn {
    margin-right: 3px;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 58px;
    padding-right: 15px;
  }
  .single-games-box .content .btn-box .playstore-btn span {
    display: block;
    margin-bottom: -4px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
  }
  .single-games-box .content .btn-box .playstore-btn img {
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .single-games-box .content .btn-box .applestore-btn {
    margin-left: 3px;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 8px;
    padding-left: 62px;
    padding-right: 15px;
  }
  .single-games-box .content .btn-box .applestore-btn span {
    margin-bottom: -4px;
    font-weight: 500;
    font-size: 15px;
  }
  .single-games-box .content .btn-box .applestore-btn img {
    left: 15px;
  }
  .games-slides.owl-theme .owl-nav {
    display: none;
  }
  .single-magazine-box .content {
    padding: 20px;
  }
  .single-magazine-box .content h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1550px) {
  .shape1 {
    left: 9%;
    top: 0;
  }
  .shape2 {
    right: 9%;
    top: 0;
  }
  .shape3 {
    left: 40%;
    bottom: 0;
  }
  .shape4 {
    right: 4%;
    bottom: 4%;
  }
}

@media only screen and (min-width: 1920px) {
  .partner-shape1 img {
    width: 100%;
  }
  .single-magazine-box img {
    width: 100%;
  }
}
