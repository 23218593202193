.single-stream-schedule-box {
    border: 1px solid #9a9a9a !important;
}
.single-stream-schedule-box .content {
    border-left: none;
}
        section.stream-schedule-area.pt-100.pb-70 {
    max-height: 500px !important;
    overflow: auto !important;
}
        .single-stream-schedule-box.not-streaming .date {
    text-decoration: none !important;
    color: red !important;
}
.single-stream-schedule-box.not-streaming .content .time {
    color: white !important;
}
      .main-banner-content h6,
      .logo a h6,
      .navbar a h6 {
        font-size: 100px !important;
      }
      .main-banner-content h6 {
        color: #f8071f;
      }
      .main-banner-content .sub-title {
        font-weight: bold;
      }
      .buy-now-btn {
        display: none;
      }
      .single-live-stream-item img {
        height: 300px;
        object-fit: cover;
      }
      .logo a h6 {
        font-size: 50px !important;
      }
      .single-live-stream-box img {
        width: 100%;
      }
      .dot-menu {
        display: none;
      }
      .main-banner {
        padding-top: 50px;
      }
      .option-item {
        display: none;
      }
      .main-banner-content {
        padding-top: 5rem !important;
      }
      .page-title-content h1 {
        font-size: 50px;
      }
      .hero-banner-content h1 {
    text-align: center;
}
.hero-banner-content {
    width: 400px;
    height: 400px;
    margin-left: 0px;
    border: 2px solid white;
}
.hero-banner-area {
    background-image: url("../images/subscribe-bg.jpg");
}
.hero-banner-area::before {
    opacity: .50 !important;
}
.content span {
  color: white;
}

      @media screen and (max-width: 767px) {
        .single-live-stream-item img {
          height: 115px !important;
        }
        .single-live-stream-item .video-btn {
          right: 10px;
          top: 10px;
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 12px;
        }
        .main-banner-content {
          padding-top: 0 !important;
        }
        .logo a h6 {
          font-size: 35px !important;
        }
        .hero-banner-content {
    width: 200px !important;
    height: 200px !important;
    margin-left: 0px;
    border-radius: 50% !important;
}
.hero-banner-content::before {
    border-radius: 50% !important;
    opacity: 1;
}
      }


      .hero-banner-content .btn-box{
        margin-top: 10px !important;
      }