/*
@File: Zelda Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Navbar Area CSS
** - Search Overlay CSS
** - Main Banner Area CSS
** - Matches Area CSS
** - Match Details Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Live Stream Area CSS
** - Awards Area CSS
** - Tournament Details Area CSS
** - Featured Games Area CSS
** - Team Area CSS
** - Team Details Area CSS
** - Player Details Area CSS
** - Services Area CSS
** - Popular Leagues Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Games Area CSS
** - Upcoming Tournaments Area CSS
** - Partner Area CSS
** - Top Team Area CSS
** - Our Experts Area CSS
** - Stream Schedule Area CSS
** - Subscribe Area CSS
** - History Area CSS
** - Page Title Area CSS
** - Gallery Area CSS
** - 404 Error Area CSS
** - Coming Soon Area CSS
** - Social Area CSS
** - FAQ Area CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Contact Area CSS
** - Profile Authentication Area CSS
** - Footer Area CSS
** - Go Top CSS
** - Sidebar Modal CSS
*/
/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: VerminVibes;
  src: url(../fonts/Vermin-Vibes-V.ttf.eot);
  src: url(../fonts/Vermin-Vibes-V.ttf.svg);
  src: url(../fonts/Vermin-Vibes-V.ttf.woff);
}

:root {
  --fontFamily: 'Teko', sans-serif;
  --fontFamily2: 'VerminVibes', sans-serif;
  --fontFamily3: 'Poppins', sans-serif;
  --mainColor: #fc0820;
  --whiteColor: #ffffff;
  --blackColor: #0c0305;
  --fontSize: 18px;
  --transition: .5s;
}

body {
  background-color: var(--blackColor);
  color: var(--whiteColor);
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Teko', sans-serif !important;
  font-size: var(--fontSize);
}

a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  outline: 0 !important;
  text-decoration: none;
}

a:hover {
  color: var(--mainColor);
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1210px;
}

:focus {
  outline: 0 !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--whiteColor);
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

p {
  margin-bottom: 15px;
  line-height: 1.8;
  font-family: var(--fontFamily3);
  font-size: 15px;
}

p:last-child {
  margin-bottom: 0;
}

.bg-1b060a {
  background-color: #1b060a;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

/*animate-cursos*/
.zelda-cursor {
  width: 7px;
  height: 7px;
  background-color: var(--mainColor);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: none;
  transition: none;
  Pointer-events: none;
  z-index: 9999999999;
  display: none;
}

.zelda-cursor2 {
  display: none;
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid var(--mainColor);
  position: absolute;
  top: 9px;
  
  left: 9px;
  -webkit-transition: none;
  transition: none;
  Pointer-events: none;
  z-index: 9999999999;
}
/********    Corrections Start        ********/
/* .correction1 {
  background: #0c0305;
  padding-top: 35px;
  margin-top: -35px;
} */
body{
  background-color: #0c0305 !important;
}
a {
  text-decoration: none !important;
}
a.video-btn i {
  color: white;
}
/* new sec */
section.just-gamers-img {
  background-image: url(../images/just_gamer7.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 35px;
}
img.spin-text-img {
  max-width: 341px;
  position: absolute;
  bottom: 61px;
  left: 206px;
  transform: rotate(2deg);
  z-index: 999;
}
.g-inner-box3 h2 {
  font-size: 85px;
  color: #FFF;
  text-transform: uppercase;
  margin-top: 172px;
}
.just-game-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  position: relative;
}
img.g-image2 {
  position: absolute;
  bottom: 73px;
  z-index: 1;
  left: 103px;
  max-width: 280px;
  width: 100%;
}
img.g-image1 {
  position: relative;
  z-index: 999;
}
.g-inner-box3 {
  display: flex;
  position: relative;
  width: 100%;
}
img.sudoku-img {
  max-width: 332px;
}
img.g-image3 {
  max-width: 215px;
  position: absolute;
  bottom: -27px;
  left: 269px;
  animation: rotateme 8s linear infinite;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
section.top-c-slider h2 {
  color: #fff;
}
section.top-c-slider {
  padding: 20px 0;
  background: #1f0d26;
}
.item.category-inner {
  width: 100% !important;
  padding: 0 7px;
}
.category-box {
  border-radius: 5px;
  overflow: hidden;
}
.top-c-slider-inner .category .owl-nav button {
  position: absolute;
  top: -53px;
  right: 6px;
  width: 35px;
  height: 35px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 47px !important;
  background: white !important;
}
.top-c-slider-inner .category .owl-nav button:hover{
  color: #000000 !important;
}
.category-box img {
  height: 215px;
  object-fit: cover;
  object-position: center;
}
.top-c-slider-inner .category .owl-nav button.owl-prev {
  right: 50px;
}

div#wof br {
  display: none;
}
/* end */
.showPointsOnScreen {
  position: absolute;
  right: 75px;
  background: red;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  border: 2px solid white;
  padding: 5px;
  color: white;
}
.social-area .content{
  color:white;
}
.single-live-stream-box img {
  width: 624px !important;
  height: 350px !important;
  object-fit: cover;
}

.correction1 .streams-list {
  height: 100%;
  overflow: hidden !important;
}
.single-live-stream-item img {
  height: 300px !important;
  /* width: 500px !important; */
  object-fit: fill !important;
}
.score-change .single-live-stream-item .content {
  position: absolute;
  right: 0 !important;
  bottom: 0 !important;
  padding: 30px 30px 25px;
  z-index: 2;
}
.nav-link:focus {
  background-color: red !important;
  color: white !important;
 }
 
.single-live-stream-item:hover .video-btn {
  background-color: white !important;
  color: red !important;
} 
.single-live-stream-item:hover .flaticon-play-button {
  /* background-color: red !important; */
  color: red !important;
} 
h3.rating1 {
  width: 75px;
  height: 75px;
  background: red;
  border-radius: 50%;
  text-align: center;
  padding: 19px;
}

.gif-row{
  width: 100vw;
}
.upper-games {
  background-position: center top;
}
.ball {
  /* animation: bounce 2s infinite;
  animation-timing-function: ease; */
  color: #ffffff !important;
  margin-top: 10px;
}
.main-banner-content h6 {
  color: #ffffff;
}

/* cus titel */
.text-animation {
  min-height: 27vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-wrapper {
  position: relative;
}

.text-wrapper h2 {
  z-index: 4;
  color: #fff;
  top: 42px;
  letter-spacing: 6px;
  font-size: 158px;
  white-space: nowrap;
  position: absolute;
  transform: translate(-50%, -62%);
}

.text-wrapper h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #fff;
}

.text-wrapper h2:nth-child(2) {
  color: #fff;
  animation: wave 4s ease-in-out infinite;
}

/* ============= */

.loader {
  perspective: 1000px;
  -webkit-perspective: 1000px;
}
.loader > span {
  display: inline-block;
  color: #fff;
  transform-style: preserve-3d;
  transform-origin: 0 100%;
  animation: anim 3s;
}
.loader span {
  font-size: 48px;
  letter-spacing: 20px;
}
.loader > span:nth-child(even) {
  color: #fc0820;
}
.loader > span:nth-child(2) {
  animation-delay: 0.2s;
}
.loader > span:nth-child(3) {
  animation-delay: 0.4s;
}
.loader > span:nth-child(4) {
  animation-delay: 0.6s;
}
.loader > span:nth-child(5) {
  animation-delay: 0.8s;
}
.loader > span:nth-child(6) {
  animation-delay: 1s;
}
.loader > span:nth-child(7) {
  animation-delay: 1.2s;
}
.loader > span:nth-child(8) {
  animation-delay: 1.4s;
}
.loader > span:nth-child(9) {
  animation-delay: 1.6s;
}
.loader > span:nth-child(10) {
  animation-delay: 1.8s;
}
.loader > span:nth-child(11) {
  animation-delay: 2s;
}
.loader > span:nth-child(12) {
  animation-delay: 2.2s;
}
.loader > span:nth-child(13) {
  animation-delay: 2.4s;
}
.loader > span:nth-child(14) {
  animation-delay: 2.6s;
}
@keyframes anim {
  35% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

/* ============ */

@keyframes wave {
  /* start point */
  0%,
  100% {
    clip-path: polygon(
      0% 46%,
      17% 45%,
      34% 50%,
      56% 61%,
      69% 62%,
      86% 60%,
      100% 51%,
      100% 100%,
      0% 100%
    );
  }
  /* Mid point */
  50% {
    clip-path: polygon(
      0% 59%,
      16% 64%,
      33% 65%,
      52% 61%,
      70% 52%,
      85% 47%,
      100% 48%,
      100% 100%,
      0% 100%
    );
  }
}

/*  */

@media only screen and (max-width: 500px) {
  .p {
      font-size: 30px;
      letter-spacing: 0;
  }
  .loader span {
      font-size: 28px;
      letter-spacing: 6px;
  }
  .text-wrapper h2 {
      top: 32px;
  }
    .text-animation {
      min-height: 14vh;
  }
}

@media only screen and (max-width: 700px) {
  
  .loader span {
      font-size: 28px;
      letter-spacing: 6px;
  }
  .text-wrapper h2 {
      top: 32px;
  }
    .text-animation {
      min-height: 14vh;
  }
  div#wof h2 {
    font-size: 44px;
}
div#wof .text-animation {
  min-height: 11vh;
}
div#wof br {
  display: block;
}
.text-animation {
  min-height: 19vh !important;
}

div#box h2 {
  font-size: 70px;
}
}


.cursor {
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(0, -50%);
  border-right: 2px solid red;
  animation: cursor-animation 3s ease-in-out forwards alternate infinite;
}

@keyframes cursor-animation {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

/* end */
.bounce:nth-child(1) {
  animation-delay:-1000ms;
}
.bounce:nth-child(2) {
  animation-delay: -1200ms;
}
.bounce:nth-child(3) {
  animation-delay: -4800ms;
}
.owl-item .item {
  width: 200px;
}
.align-correct img {
  min-width: 175px;
  height: 300px;
  object-fit: cover;
}
@keyframes bounce {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(-30px);
  }
}

.game-show {
  display: flex !important;
  justify-content: center;
}

.btn-center {
  text-align: center;
}

.main-banner.jarallax {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}





@media only screen and (max-width: 768px) {
  .correction1 {
    padding-top: 0px !important;
  }
 
  .single-live-stream-item img {
    height: 115px !important;
    width: 175px !important;
    object-fit: cover;
}
.score-change .single-live-stream-item .content {
  position: absolute;
  left: 175px !important;
  bottom: 0 !important;
  padding: 30px 10px 25px 30px;
  z-index: 2;
}
h3.rating1 {
  width: 45px;
  height: 45px;
  background: red;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
}
.row.align-correct img {
  min-width: 130px;
  height: 220px;
  object-fit: cover;
  border-radius: 10px !important;
}
.owl-item .item {
  width: 125px;
}

  
}
@media only screen and (max-width: 575px) {
  .upper-games {
  background-position: 100% 25%;
}
.root{
  overflow: hidden;
}

}
/* cus css narendar */
.edit-box {
  width: 100%;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 108px;
  left: 55%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
.page-title-content.img-box {
  position: relative;
}
.icon-box-inner {
  max-width: 112px;
}
.cus-icon-box-inner {
  display: flex;
  gap: 15px;
  justify-content: center;
}
.icon-box-inner img {
  max-width: 100% !important;
  width: 100% !important;
  margin-bottom: 0 !important;
}
.icon-box-inner {
  max-width: 100px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
div#myDIV {
  opacity: 0;
  transition: all 0.5s;
  background: #fff;
  max-width: 430px;
  margin: auto;
  position: relative;
  top: -100px;
  padding: 60px 10px;
  overflow: hidden;
  border-radius: 5px;
}
.edit-name span {
  border: 2px solid #000;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 18px;
  display: block;
  width: 180px;
  font-size: 20px;
}
.edit-btn button {
  font-size: 22px;
  width: 145px;
  border-radius: 5px;
} 
.cus-icon-box {
    opacity: 1 !important;
    transition: all 0.5s;
}


.edit-name-box {
  margin-top: 45px;
  margin-bottom: 30px;
}
.edit-name-box input {
  display: block;
  width: 100%;
  margin: 14px 0;
  padding: 6px 14px;
  color: #000;
  font-size: 23px;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  border: 2px solid #000;
}
.edit-name-box label {
  display: block;
  font-size: 28px;
  color: #000;
}
.edit-name-box input::placeholder {
  color: #000;
}
.edit-name-box button.save-btn {
  padding: 5px 30px;
  font-size: 23px;
  border-radius: 4px;
  margin-top: 12px;
  border: 0;
  background: #28070e;
  color: #fff;
  width: 100%;
  letter-spacing: 3px;
}
.m-h {
  background: #28070e;
  color: #fff;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
.m-f button.btn.btn-secondary {
  background: #ff0000;
  border: none;
  padding: 3px 22px;
  font-size: 20px;
  letter-spacing: 2px;
  border-radius: 4px;
}
.m-h button.btn-close {
  filter: invert(1);
  opacity: 1;
}
.edit-profile .owl-dots {
  display: none;
}
.row.align-correct {
  justify-content: center;
}


.c-box-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  height: 450px;
  background: #28070e;
  overflow-y: scroll;
  border-radius: 10px;
}
.c-box-inner::-webkit-scrollbar-thumb {
  background: #28070e;
  border-radius: 10px;
  border: 3px solid #000;
}
.c-box-inner::-webkit-scrollbar {
  width: 0px;
  background: #000;
  border-radius: 10px;
}
.c-box-inner-img {
  width: 48%;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  height: 200px;
  position: relative;
}
button.change-img-btn {
  padding: 0;
}
button.c-play-btn {
  position: absolute;
  z-index: 99;
  border: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: red;
  color: #fff;
  font-size: 23px;
}
.c-box-inner-img .single-live-stream-item img {
  height: 200px !important;
  object-fit: cover; 
}
.c-box-inner-img .single-live-stream-item{  
  border-radius: 10px; 
}
.c-box-inner-img img {
  width: 100%;
  max-width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.modal-body {
  background: #d2d2d2;
}
.vt-navigation-new .c-main-box .modal-body {
  /* background: #fff; */
  background: #fff !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.c-main-box .modal-header {
  background: #28070e;
  color: #fff;
}
.c-main-box button.btn-close {
  filter: invert(1);
  opacity: 1;
}
.c-main-box {
  border-radius: 5px;
  overflow: hidden;
}
.c-main-box .modal-body {
  background: #28070e;
}

/* leaderbord */
.content.leader-bord {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px 22px;
}
.time.leader-bord-img img {
  max-width: 85px;
  height: 85px;
  object-fit: cover;
  object-position: center;
}
section.player-details-area{
  max-width: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  background: #260206;
}
.player-details-area-inner{
  max-width: 100%;
  margin: auto;
  height: 395px;
  overflow-y: scroll;
  background: #260206;
  border-radius: 10px;
  padding-bottom: 45px;
}
section.social-area {
  padding-top: 35px !important;
}
.player-details-area-inner::-webkit-scrollbar {
  width: 0px;
}
.leader-border-score h3 {
  margin: 0;
  font-size: 22px;
  letter-spacing: 2px;
}
.leader-box-inner-name span {
  font-size: 24px;
  letter-spacing: 3px;
}
.leader-bord-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 30px;
  font-size: 26px;
  letter-spacing: 2px;
  background: #fff;
  color: #260206;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px){
  .edit-name-box {
      margin-top: 20px;
      margin-bottom: 10px;
  }
  .edit-name-box label {
      font-size: 20px;
  }
  .edit-name-box input {
      display: block;
      width: 100%;
      margin: 7px 0;
      padding: 2px 6px;
      color: #000;
      font-size: 23px;
      border-radius: 4px;
      box-shadow: none;
      outline: none;
      border: 2px solid #000;
  }
  .edit-box {
      left: 67%;
  }
  .c-box-inner-img .single-live-stream-item img {
    height: 145px !important;
    object-fit: cover;
  }



.edit-img-box-inner {
    width: 75%;
    margin: auto;
}



.page-title-content .player-image {
  max-width: 120px;
  width: 120px;
  /* border-radius: 50%; */
}

.c-box-inner {
    /* align-items: center;
    display: flex;
    justify-content: center;
    height : max-content; */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.c-box-inner-img {
  height: 145px;
  width: 49%;
}

.vt-show-game .single-live-stream-item {
  height: 100px !important;
  width: 320px !important;
}


}
/* //MEdia Query End */

/* .streams-list {
  height: max-content;
  overflow-y: auto;
} */
.streams-list {
  height: -webkit-max-content;
  max-height: 600px;
  overflow-x: hidden;
}
.Loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .vt-show-game .single-live-stream-item img {
  height: 100% !important;
  width: 100%;
} */
.vt-show-game .single-live-stream-item {
  height: 225px;
  width: 440px;
}
.vt-show-game .single-live-stream-item {
  height: 200px;
  width: 440px;
  /* height: 100px;
  width: 340px; */
}
.streams-list.score-change::-webkit-scrollbar-thumb {
  background-color: #000;
}
.streams-list.score-change::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}
.navbar span {
  position: absolute;
  bottom: -10%;
  left: 0%;
  width: 40px;
  display: block;
  background: #fff;
  text-align: center;
  border-radius: 20px !important;
  color: red;
}

.zelda-responsive-menu span {
  width: 40px;
  display: block;
  background: #fff;
  color: red;
  text-align: center;
  border-radius: 20px !important;
}

.vt-navigation-new .modal-content {
  margin-top: 94px;
  border: none;
}
.vt-navigation-new .modal-body ul.navbar-nav li {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #000;
}
.vt-navigation-new .modal-header {
  border: none;
}

.modal-body ul.navbar-nav li a.nav-link {
  margin:0 10px;
  
}

.vt-profile-modal .owl-carousel .owl-item img {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
} 

.edit-img-box-inner:hover img {
  opacity: 0.5;
}
/********    Corrections End        ********/


/*default&optional-btn*/
.default-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 15px 40px 9px 40px;
  font-size: 20px;
  font-weight: 400;
}

.default-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.default-btn:hover {
  color: var(--mainColor);
}

.default-btn:hover::before {
  width: 100%;
  height: 100%;
}

.optional-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: transparent;
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 15px 45px 9px 45px;
  font-size: 20px;
  font-weight: 400;
}

.optional-btn::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.optional-btn::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border: 1px solid var(--whiteColor);
}

.optional-btn:hover {
  color: var(--mainColor);
}

.optional-btn:hover::before {
  width: 100%;
  height: 100%;
}

/*section-title*/
.section-title {
  max-width: 720px;
  text-align: center;
  margin-bottom: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -5px;
}

.section-title .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.section-title h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

/*form-control*/
.form-control {
  height: 50px;
  color: var(--whiteColor);
  background-color: #22152c !important;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 2px 0 0 15px;
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-family: var(--fontFamily3);
  font-size: 14px;
  font-weight: 500;
}

.form-control::-webkit-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control:-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control::-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control::placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::placeholder {
  color: transparent;
}

.form-control::-webkit-search-cancel-button {
  display: none;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
}

/*================================================
Navbar Area CSS
=================================================*/
.zelda-responsive-nav {
  display: none;
}

.zelda-nav .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

.zelda-nav .navbar {
  position: relative;
  /* padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0; */
  padding : 0;
}

.zelda-nav .navbar .navbar-brand {
  font-size: inherit;
  line-height: 1;
  max-width: 180px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.zelda-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.zelda-nav .navbar .navbar-nav {
  margin-left: auto;
}

.zelda-nav .navbar .navbar-nav .nav-item {
  position: relative;
  margin-left: 6px;
  margin-right: 6px;
}

.zelda-nav .navbar .navbar-nav .nav-item a {
  color: var(--whiteColor);
  text-transform: uppercase;
  display: block;
  background-color: transparent;
  letter-spacing: 1.5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 600;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 9px;
  padding-bottom: 3.5px;
}

.zelda-nav .navbar .navbar-nav .nav-item a i {
  font-size: 11px;
  position: relative;
  top: -2px;
  margin-left: 1px;
}

.zelda-nav .navbar .navbar-nav .nav-item a:hover, .zelda-nav .navbar .navbar-nav .nav-item a.active {
  color: var(--whiteColor);
  background-color: #00030e;
}

.zelda-nav .navbar .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item:hover a, .zelda-nav .navbar .navbar-nav .nav-item.active a {
  color: var(--whiteColor);
  background-color: #00030e;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  top: 38px;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 210px;
  display: block;
  border-radius: 0;
  position: absolute;
  visibility: hidden;
  background-color: #00030e;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-top: none;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  padding-top: 24px;
  padding-bottom: 22px;
  padding-left: 20px;
  padding-right: 20px;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  margin: 13px 0 0 0;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 0;
  position: relative;
  color: var(--whiteColor);
  overflow: hidden;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 600;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 18px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a::before {
  font-family: Flaticon;
  content: "\f107";
  position: absolute;
  top: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  left: 0;
  color: var(--whiteColor);
  font-size: 13px;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
  padding-left: 18px;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  opacity: 1;
  visibility: visible;
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover i {
  -webkit-transform: translateY(-50%) rotate(225deg);
          transform: translateY(-50%) rotate(225deg);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  left: -210px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li:first-child {
  margin-top: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.zelda-nav .navbar .others-option {
  margin-left: 17px;
}

.zelda-nav .navbar .others-option .option-item {
  margin-left: 37px;
}

.zelda-nav .navbar .others-option .option-item:first-child {
  margin-left: 0;
}

.zelda-nav .navbar .others-option .side-menu-btn i {
  cursor: pointer;
  font-size: 35px;
  position: relative;
  top: 3px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}


.zelda-nav .navbar .others-option .side-menu-btn i:hover {
  color: var(--mainColor);
}

.zelda-nav .navbar .others-option .search-box i {
  cursor: pointer;
  font-size: 22px;
  position: relative;
  top: 3px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.zelda-nav .navbar .others-option .search-box i:hover {
  color: var(--mainColor);
}

.zelda-nav .navbar .others-option .cart-btn a {
  display: inline-block;
  position: relative;
  font-size: 25px;
  top: 1px;
  color: var(--whiteColor);
  line-height: 1;
  padding-right: 10px;
}

.zelda-nav .navbar .others-option .cart-btn a span {
  position: absolute;
  right: 0;
  top: -3px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background-color: var(--mainColor);
  font-size: 14px;
  font-weight: 600;
}

.navbar-area {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  background-color: var(--blackColor) !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-area.navbar-style-two .zelda-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

.navbar-area.navbar-style-two .zelda-nav .navbar .others-option {
  margin-left: 0;
}

.others-option-for-responsive {
  display: none;
}

.others-option-for-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 9991;
  position: absolute;
  right: 52px;
  top: -40px;
}

.others-option-for-responsive .dot-menu .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
}

.others-option-for-responsive .dot-menu .inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--whiteColor);
}

.others-option-for-responsive .dot-menu:hover .inner .circle {
  background-color: var(--mainColor);
}

.others-option-for-responsive .container {
  position: relative;
}

.others-option-for-responsive .container .container {
  position: absolute;
  right: 0;
  top: 10px;
  max-width: 320px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}

.others-option-for-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.others-option-for-responsive .option-inner {
  padding: 10px 15px;
  -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
          box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
  background-color: var(--blackColor);
}

.others-option-for-responsive .option-inner .others-option {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.others-option-for-responsive .option-inner .others-option .option-item {
  margin-left: 30px;
}

.others-option-for-responsive .option-inner .others-option .option-item:first-child {
  margin-left: 0;
}

.others-option-for-responsive .option-inner .others-option .side-menu-btn i {
  cursor: pointer;
  font-size: 35px;
  position: relative;
  top: 3px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive .option-inner .others-option .side-menu-btn i:hover {
  color: var(--mainColor);
}

.others-option-for-responsive .option-inner .others-option .search-box i {
  cursor: pointer;
  font-size: 22px;
  position: relative;
  top: 9px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive .option-inner .others-option .search-box i:hover {
  color: var(--mainColor);
}

.others-option-for-responsive .option-inner .others-option .cart-btn {
  position: relative;
  top: 5px;
}

.others-option-for-responsive .option-inner .others-option .cart-btn a {
  display: inline-block;
  position: relative;
  font-size: 25px;
  top: 1px;
  color: var(--whiteColor);
  line-height: 1;
  padding-right: 10px;
}

.others-option-for-responsive .option-inner .others-option .cart-btn a span {
  position: absolute;
  right: 0;
  top: -3px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background-color: var(--mainColor);
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .navbar-area {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area.is-sticky {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-area.is-sticky .zelda-responsive-nav .zelda-responsive-menu.mean-container .mean-nav {
    margin-top: 63px;
  }
  .navbar-area.is-sticky .others-option-for-responsive .container .container {
    top: 10px;
  }
  .navbar-area.navbar-style-two {
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .zelda-responsive-nav {
    display: block;
  }
  .zelda-responsive-nav .zelda-responsive-menu {
    position: relative;
  }
  .zelda-responsive-nav .zelda-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 300px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .zelda-responsive-nav .zelda-responsive-menu .others-option {
    display: none !important;
  }
  .zelda-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }
  .zelda-responsive-nav .logo img {
    max-width: 140px;
  }
  .zelda-responsive-nav .dark-version-btn {
    top: 5px;
    right: 90px;
    margin-left: 0;
    position: absolute;
  }
  .zelda-responsive-nav .dark-version-btn .switch {
    width: 40px;
    height: 40px;
  }
  .zelda-nav {
    display: none;
  }
  .others-option-for-responsive {
    display: block;
  }
}

/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.search-overlay .search-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.search-overlay .search-overlay-layer:nth-child(1) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.search-overlay .search-overlay-layer:nth-child(2) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease-in-out 0.3s;
  transition: all 0.3s ease-in-out 0.3s;
}

.search-overlay .search-overlay-layer:nth-child(3) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.9s ease-in-out 0.6s;
  transition: all 0.9s ease-in-out 0.6s;
}

.search-overlay .search-overlay-close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 50px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  -webkit-transition: all 0.9s ease-in-out 1.5s;
  transition: all 0.9s ease-in-out 1.5s;
  opacity: 0;
  visibility: hidden;
}

.search-overlay .search-overlay-close .search-overlay-close-line {
  width: 100%;
  height: 3px;
  float: left;
  margin-bottom: 5px;
  background-color: var(--whiteColor);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(2) {
  margin-top: -7px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.search-overlay .search-overlay-close:hover .search-overlay-close-line {
  background: var(--mainColor);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.search-overlay .search-overlay-form {
  -webkit-transition: all 0.9s ease-in-out 1.4s;
  transition: all 0.9s ease-in-out 1.4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  max-width: 500px;
  width: 500px;
}

.search-overlay .search-overlay-form form {
  position: relative;
}

.search-overlay .search-overlay-form form .input-search {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  color: var(--blackColor);
  padding: 4px 0 0 25px;
}

.search-overlay .search-overlay-form form .input-search::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  color: var(--whiteColor);
  height: 50px;
  border-radius: 50%;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: none;
  font-size: 20px;
  line-height: 45px;
}

.search-overlay .search-overlay-form form button:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

.search-overlay.search-overlay-active.search-overlay {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-layer {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-close {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-form {
  opacity: 1;
  visibility: visible;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  padding-bottom: 0px !important;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  background-image: url(../images/main-banner-bg1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-banner::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../images/main-banner-shape.png);
  background-position: bottom;
  background-repeat: no-repeat;
}

.main-banner-content {
  text-align: center;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}

.main-banner-content .sub-title {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 13px;
  letter-spacing: 23px;
  font-size: 20px;
  font-weight: 400;
}

.main-banner-content .logo {
  margin-bottom: 30px;
}

.main-banner-content h6 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
}

.main-banner-content .btn-box {
  margin-top: 50px;
}

.main-banner-content .btn-box .default-btn {
  margin-right: 15px;
}

.main-banner-content .btn-box .optional-btn {
  margin-left: 15px;
}

.main-banner-content .btn-box .optional-btn::after {
  margin: 1px 0;
}

.main-banner-image {
  text-align: center;
}

.banner-video-slides {
  max-width: 790px;
  margin: 90px auto 50px auto;
}

.banner-video-slides .owl-item {
  padding: 12px;
}

.banner-video-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.banner-video-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 45px;
  margin: 0;
  padding: 0;
  background: transparent;
  position: absolute;
  left: -120px;
  bottom: 110px;
  border-radius: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.banner-video-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -120px;
  -webkit-transform: unset;
          transform: unset;
}

.banner-video-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.banner-video-slides.owl-theme .owl-dots {
  margin-top: 20px;
}

.banner-video-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.banner-video-slides.owl-theme .owl-dots .owl-dot:hover span, .banner-video-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.banner-video-box {
  position: relative;
  z-index: 1;
  text-align: center;
}

.banner-video-box::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  margin: -12px;
  background-image: url(../images/main-banner-video-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-video-box .content {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  text-align: center;
  z-index: 2;
}

.banner-video-box .content .video-btn {
  display: inline-block;
  width: 82px;
  line-height: 83px;
  height: 82px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  color: var(--mainColor);
  font-size: 35px;
}

.banner-video-box .content .video-btn:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.banner-video-box .content .title {
  display: block;
  text-transform: uppercase;
  margin-top: 25px;
  font-family: var(--fontFamily2);
  font-size: 30px;
}

.single-banner-item {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 145px;
  padding-bottom: 145px;
}

.single-banner-item .container-fluid {
  padding-left: 140px;
  padding-right: 140px;
}

.banner-bg1 {
  background-image: url(../images/main-banner-bg2.jpg);
}

.banner-bg2 {
  background-image: url(../images/main-banner-bg4.jpg);
}

.banner-bg3 {
  background-image: url(../images/main-banner-bg3.jpg);
}

.home-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.home-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 40px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: 30px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.home-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.home-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.home-slides.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin-top: 0;
}

.home-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.home-slides.owl-theme .owl-dots .owl-dot:hover span, .home-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.home-slides .main-banner-content {
  max-width: 100%;
  text-align: left;
  padding-right: 50px;
  margin-left: 0;
  margin-right: 0;
}

.home-slides .main-banner-image img {
  width: auto !important;
  display: inline-block !important;
}

.banner-area {
  padding-top: 260px;
  padding-bottom: 150px;
}

.banner-content {
  text-align: center;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}

.banner-content .sub-title {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 30px;
  letter-spacing: 10px;
  font-size: 20px;
  font-weight: 400;
}

.banner-content .logo {
  margin-bottom: 35px;
}

.banner-content h6 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: 400;
}

.banner-wrapper-area {
  padding-top: 280px;
  padding-bottom: 150px;
  background-image: url(../images/main-banner-bg3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-wrapper-content {
  max-width: 790px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.banner-wrapper-content .logo {
  margin-bottom: 40px;
}

.banner-wrapper-content h1 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 60px;
  font-weight: 700;
}

.banner-wrapper-content .sub-title {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-top: 20px;
  letter-spacing: 10px;
  font-size: 20px;
  font-weight: 400;
}

.hero-banner-area {
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
  padding-top: 160px;
  background-image: url(../images/main-banner-bg5.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-banner-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #1b060a;
  opacity: .90;
}

.hero-banner-area .row {
  margin: 0;
}

.hero-banner-area .row .col-lg-6 {
  padding: 0;
}

.hero-banner-image {
  border-radius: 50%;
  margin-right: -80px;
  width: 650px;
  height: 650px;
}

.hero-banner-image img {
  border-radius: 50%;
}

.hero-banner-content {
  width: 650px;
  height: 650px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  margin-left: -80px;
}

.hero-banner-content .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  padding: 120px;
}

.hero-banner-content::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 50%;
  background-color: var(--mainColor);
  opacity: .85;
}

.hero-banner-content h1 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 50px;
  font-weight: 700;
}

.hero-banner-content .btn-box {
  margin-top: 30px;
}

.hero-banner-content .btn-box .default-btn {
  background-color: var(--blackColor);
}

.hero-banner-content .btn-box .video-btn {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 20px;
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 600;
}

.hero-banner-content .btn-box .video-btn i {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  color: var(--mainColor);
  line-height: 50px;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-right: 5px;
  margin-top: 1px;
}

.hero-banner-content .btn-box .video-btn:hover i {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

/*================================================
Matches Area CSS
=================================================*/
.matches-area {
  position: relative;
  z-index: 1;
}

.matches-area.bg-image {
  background-image: url(../images/matches-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-matches-box {
  margin-bottom: 45px;
}

.single-matches-box .matches-team {
  position: relative;
  padding-left: 100px;
  padding-top: 45px;
}

.single-matches-box .matches-team img {
  position: absolute;
  left: 0;
  max-width: 180px;
  top: 0;
}

.single-matches-box .matches-team .content {
  text-align: right;
}

.single-matches-box .matches-team .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  background-image: url(../images/matches-bg1.png);
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 32px;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 55px;
}

.single-matches-box .matches-team .content .watch-list {
  list-style-type: none;
  padding-left: 0;
  padding-right: 54px;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-matches-box .matches-team .content .watch-list li {
  display: inline-block;
  margin-right: 8px;
}

.single-matches-box .matches-team .content .watch-list li span {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  font-size: 20px;
  font-weight: 400;
}

.single-matches-box .matches-team .content .watch-list li a {
  position: relative;
  top: 1px;
  color: #bcb6b8;
}

.single-matches-box .matches-team .content .watch-list li a:hover {
  color: var(--whiteColor);
}

.single-matches-box .matches-team .content .watch-list li:last-child {
  margin-right: 0;
}

.single-matches-box .matches-team.right-image {
  padding-left: 0;
  padding-right: 100px;
}

.single-matches-box .matches-team.right-image img {
  left: auto;
  right: 0;
}

.single-matches-box .matches-team.right-image .content {
  text-align: left;
}

.single-matches-box .matches-team.right-image .content h3 {
  padding-left: 55px;
  padding-right: 0;
  background-image: url(../images/matches-bg2.png);
  background-position: left center;
}

.single-matches-box .matches-team.right-image .content ul {
  padding-left: 54px;
  padding-right: 0;
}

.single-matches-box .matches-team.right-image .content ul li {
  margin-left: 8px;
  margin-right: 0;
}

.single-matches-box .matches-result {
  padding-top: 30px;
  text-align: center;
}

.single-matches-box .matches-result ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-matches-box .matches-result ul li {
  display: inline-block;
  position: relative;
  line-height: 1;
  font-size: 60px;
  font-weight: 700;
  margin-left: 28px;
  margin-right: 28px;
}

.single-matches-box .matches-result ul li::before {
  content: ':';
  position: absolute;
  left: -33px;
  top: 11px;
  font-size: 28px;
}

.single-matches-box .matches-result ul li:last-child {
  margin-right: 0;
}

.single-matches-box .matches-result ul li:first-child {
  margin-left: 0;
}

.single-matches-box .matches-result ul li:first-child::before {
  display: none;
}

.single-matches-box .matches-result .date-time {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  font-size: 20px;
  font-weight: 400;
}

.single-matches-box:last-child {
  margin-bottom: 0;
}

.matches-tabs .nav-tabs {
  background-color: var(--blackColor);
  border: none;
}

.matches-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
}

.matches-tabs .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 12px 45px 6px 30px;
  text-transform: uppercase;
  color: var(--whiteColor);
  letter-spacing: 2px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  font-size: 22px;
  font-weight: 600;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.matches-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor) !important;
}

.matches-tabs .nav-tabs .nav-item .nav-link:hover {
  color: var(--mainColor);
}

.matches-tabs .tab-content {
  background-color: rgba(12, 3, 5, 0.6);
  padding: 30px;
}

.shape1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.shape2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.shape3 {
  position: absolute;
  left: 32%;
  bottom: 0;
  z-index: -1;
}

.shape4 {
  position: absolute;
  right: 4%;
  bottom: 4%;
  z-index: -1;
}

/*================================================
Match Details Area CSS
=================================================*/
.match-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: -12px;
  list-style-type: none;
}

.match-details-desc .article-content .entry-meta ul li {
  display: inline-block;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--mainColor);
  position: relative;
}

.match-details-desc .article-content .entry-meta ul li::before {
  width: 1px;
  height: 12px;
  background-color: var(--mainColor);
  content: '';
  position: absolute;
  right: -12px;
  top: 5px;
}

.match-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}

.match-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}

.match-details-desc .article-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  margin-top: 28px;
  font-size: 32px;
  font-weight: 700;
}

.match-details-desc .article-content p {
  color: #c2bcc6;
}

.match-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.match-details-desc .article-content .wp-block-gallery.columns-3 li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333%;
          flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}

.match-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}

.match-details-desc .article-content .blockquote, .match-details-desc .article-content blockquote {
  margin-top: 30px;
  margin-bottom: 30px;
}

.match-details-desc .article-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-top: 1px solid #22152c;
  padding-top: 30px;
  margin-top: 30px;
}

.match-details-desc .article-footer .article-tags {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.match-details-desc .article-footer .article-tags span {
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 3px;
  font-size: 20px;
}

.match-details-desc .article-footer .article-tags a {
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
}

.match-details-desc .article-footer .article-share {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.match-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}

.match-details-desc .article-footer .article-share .social li {
  display: inline-block;
}

.match-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 3px;
  text-transform: uppercase;
  position: relative;
  top: -1px;
}

.match-details-desc .article-footer .article-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 37px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}

.match-details-desc .article-footer .article-share .social li a:hover, .match-details-desc .article-footer .article-share .social li a:focus {
  color: var(--mainColor);
  background-color: var(--whiteColor);
  border-color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.facebook:hover, .match-details-desc .article-footer .article-share .social li a.facebook:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.match-details-desc .article-footer .article-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.twitter:hover, .match-details-desc .article-footer .article-share .social li a.twitter:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.match-details-desc .article-footer .article-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.linkedin:hover, .match-details-desc .article-footer .article-share .social li a.linkedin:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.match-details-desc .article-footer .article-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.instagram:hover, .match-details-desc .article-footer .article-share .social li a.instagram:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.single-blog-post-item {
  margin-bottom: 30px;
}

.single-blog-post-item .post-image {
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.single-blog-post-item .post-image.bg-1 {
  background-image: url(../images/blog-img1.jpg);
}

.single-blog-post-item .post-image.bg-2 {
  background-image: url(../images/blog-img2.jpg);
}

.single-blog-post-item .post-image.bg-3 {
  background-image: url(../images/blog-img3.jpg);
}

.single-blog-post-item .post-image img {
  display: none;
}

.single-blog-post-item .post-image a.link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.single-blog-post-item .post-content {
  padding: 60px 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/post-bg.jpg);
}

.single-blog-post-item .post-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.single-blog-post-item .post-content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 30px;
  font-weight: 600;
}

.single-blog-post-item .post-content .default-btn {
  margin-top: 20px;
}

.blog-slides.owl-theme .owl-nav {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-top: 0;
}

.blog-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 35px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: 30px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.blog-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
}

.blog-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.blog-slides.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.blog-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.blog-slides.owl-theme .owl-dots .owl-dot:hover span, .blog-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.blog-slides.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.single-blog-post {
  margin-bottom: 30px;
  text-align: center;
}

.single-blog-post .post-content {
  margin-top: 20px;
}

.single-blog-post .post-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.single-blog-post .post-content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 34px;
  font-weight: 600;
}

.single-blog-post .post-content .default-btn {
  margin-top: 25px;
}

.blog-slides-two.owl-theme .owl-nav {
  margin-top: 0;
}

.blog-slides-two.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: -65px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.blog-slides-two.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.blog-slides-two.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.blog-slides-two.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.blog-slides-two.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.blog-slides-two.owl-theme .owl-dots .owl-dot:hover span, .blog-slides-two.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.single-blog-post-box {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  background-color: #1f1625;
}

.single-blog-post-box .post-image {
  border-radius: 5px 5px 0 0;
}

.single-blog-post-box .post-image a {
  display: block;
  border-radius: 5px 5px 0 0;
}

.single-blog-post-box .post-image a img {
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.single-blog-post-box .post-content {
  padding: 28px;
}

.single-blog-post-box .post-content .post-meta {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 15px;
}

.single-blog-post-box .post-content .post-meta li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.single-blog-post-box .post-content .post-meta li::before {
  width: 1px;
  height: 12px;
  position: absolute;
  right: -12px;
  top: 4px;
  content: '';
  background-color: var(--mainColor);
}

.single-blog-post-box .post-content .post-meta li:last-child {
  margin-right: 0;
}

.single-blog-post-box .post-content .post-meta li:last-child::before {
  display: none;
}

.single-blog-post-box .post-content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 26px;
  font-weight: 700;
}

.single-blog-post-box .post-content h3 a {
  display: inline-block;
}

.single-blog-post-box .post-content .read-more-btn {
  text-transform: uppercase;
  margin-top: 15px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
}

.single-blog-post-box .post-content .read-more-btn i {
  margin-left: 5px;
  position: relative;
  top: 1px;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-image img {
  width: 100%;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav {
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--blackColor);
  font-size: 18px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  width: 45px;
  height: 45px;
  line-height: 40px;
  position: absolute;
  left: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-left: 5px !important;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--whiteColor);
  border-width: 1.5px;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
  padding-left: 3px !important;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-image-slides.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.blog-details-desc .article-video iframe {
  width: 100%;
  height: 400px;
  border: none !important;
}

.blog-details-desc .article-content {
  margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
  margin-right: 20px;
  position: relative;
  display: inline-block;
  border-right: 1px solid #22152c;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
  padding-right: 20px;
  padding-left: 45px;
}

.blog-details-desc .article-content .entry-meta ul li i {
  font-size: 32px;
  color: #cfcfcf;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.blog-details-desc .article-content .entry-meta ul li span {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 15px;
}

.blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  font-size: var(--fontSize);
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.blog-details-desc .article-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  margin-top: 28px;
  font-size: 26px;
  font-weight: 700;
}

.blog-details-desc .article-content p {
  color: #c2bcc6;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333%;
          flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}

.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}

.blog-details-desc .article-content .features-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 26px;
  color: #c2bcc6;
  font-family: var(--fontFamily3);
  font-size: 15px;
}

.blog-details-desc .article-content .features-list li i {
  color: var(--mainColor);
  display: inline-block;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}

.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}

.blog-details-desc .article-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-top: 1px solid #22152c;
  padding-top: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 3px;
  font-size: 20px;
}

.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
}

.blog-details-desc .article-footer .article-share {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}

.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}

.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 3px;
  text-transform: uppercase;
  position: relative;
  top: -1px;
}

.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 37px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}

.blog-details-desc .article-footer .article-share .social li a:hover, .blog-details-desc .article-footer .article-share .social li a:focus {
  color: var(--mainColor);
  background-color: var(--whiteColor);
  border-color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.facebook:hover, .blog-details-desc .article-footer .article-share .social li a.facebook:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-footer .article-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.twitter:hover, .blog-details-desc .article-footer .article-share .social li a.twitter:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-footer .article-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.linkedin:hover, .blog-details-desc .article-footer .article-share .social li a.linkedin:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-footer .article-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.instagram:hover, .blog-details-desc .article-footer .article-share .social li a.instagram:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-author {
  margin-top: 30px;
  border-radius: 5px;
  background: #22152c;
}

.blog-details-desc .article-author .author-profile-header {
  height: 115px;
  border-radius: 5px 5px 0 0;
  background-color: var(--mainColor);
  background-image: url(../images/bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-details-desc .article-author .author-profile {
  padding: 0 25px 25px;
}

.blog-details-desc .article-author .author-profile .author-profile-title {
  position: relative;
  z-index: 1;
  margin-top: -45px;
}

.blog-details-desc .article-author .author-profile .author-profile-title img {
  display: inline-block;
  border: 3px solid var(--whiteColor);
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}

.blog-details-desc .article-author .author-profile .author-profile-title h4 {
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 600;
}

.blog-details-desc .article-author .author-profile .author-profile-title span {
  display: block;
  margin-bottom: 12px;
  letter-spacing: 1px;
  color: var(--whiteColor);
}

.blog-details-desc .article-author .author-profile .author-profile-title p {
  font-size: 15px;
}

blockquote, .blockquote {
  overflow: hidden;
  background-color: #22152c;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}

blockquote p, .blockquote p {
  color: var(--whiteColor) !important;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 700;
  font-size: 20px !important;
}

blockquote cite, .blockquote cite {
  display: none;
}

blockquote::after, .blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--mainColor);
  margin-top: 20px;
  margin-bottom: 20px;
}

.zelda-post-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #22152c;
  border-bottom: 1px solid #22152c;
}

.prev-link-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
}

.prev-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.prev-link-wrapper a:hover .image-prev::after {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .image-prev .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .prev-link-info-wrapper {
  color: var(--mainColor);
}

.prev-link-wrapper .image-prev {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-right: 20px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .image-prev img {
  border-radius: 5px;
}

.prev-link-wrapper .image-prev::after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .image-prev .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  line-height: 1;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
}

.prev-link-wrapper .prev-link-info-wrapper {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .prev-title {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}

.prev-link-wrapper .meta-wrapper {
  display: block;
  color: var(--whiteColor);
  text-transform: capitalize;
  margin-top: 6px;
  font-size: 16px;
}

.next-link-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
  padding-left: 15px;
}

.next-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.next-link-wrapper a:hover .image-next::after {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .image-next .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .next-link-info-wrapper {
  color: var(--mainColor);
}

.next-link-wrapper .image-next {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-left: 20px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .image-next img {
  border-radius: 5px;
}

.next-link-wrapper .image-next::after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .image-next .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  line-height: 1;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
}

.next-link-wrapper .next-link-info-wrapper {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .next-title {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}

.next-link-wrapper .meta-wrapper {
  display: block;
  color: var(--whiteColor);
  text-transform: capitalize;
  margin-top: 6px;
  font-size: 16px;
}

.comments-area {
  margin-top: 30px;
}

.comments-area .comments-title {
  line-height: initial;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
}

.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .children {
  margin-left: 20px;
}

.comments-area .comment-body {
  border-bottom: 1px dashed #22152c;
  padding-left: 70px;
  color: var(--whiteColor);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.comments-area .comment-body .reply {
  margin-top: 15px;
}

.comments-area .comment-body .reply a {
  border: 1px dashed #ded9d9;
  color: var(--whiteColor);
  display: inline-block;
  padding: 7px 20px 3px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.comments-area .comment-body .reply a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.comments-area .comment-author {
  font-size: var(--fontSize);
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
}

.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
  top: 5px;
}

.comments-area .comment-author .fn {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.comments-area .comment-author .says {
  display: none;
}

.comments-area .comment-metadata {
  margin-bottom: .8em;
  color: var(--whiteColor);
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
}

.comments-area .comment-metadata a {
  display: inline-block;
  color: var(--whiteColor);
}

.comments-area .comment-metadata a:hover {
  color: var(--mainColor);
}

.comments-area .comment-content p {
  color: #c2bcc6;
  font-size: 15px;
}

.comments-area .comment-respond {
  margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
  line-height: initial;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}

.comments-area .comment-respond .comment-form {
  overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
  margin-bottom: 20px;
  margin-top: 10px;
}

.comments-area .comment-respond .comment-notes .required {
  color: red;
}

.comments-area .comment-respond .comment-form-comment {
  float: left;
  width: 100%;
}

.comments-area .comment-respond label {
  display: none;
}

.comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #22152c;
  border: none;
  padding: 1px 0 0 15px;
  height: 50px;
  outline: 0;
  border-radius: 3px;
  color: var(--whiteColor);
  font-weight: 600;
  font-size: 15px;
}

.comments-area .comment-respond input[type="date"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="time"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="week"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="month"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="text"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="email"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="url"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="password"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="search"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="tel"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="number"]::-webkit-input-placeholder, .comments-area .comment-respond textarea::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]:-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:-ms-input-placeholder, .comments-area .comment-respond textarea:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]::-ms-input-placeholder, .comments-area .comment-respond input[type="time"]::-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]::-ms-input-placeholder, .comments-area .comment-respond input[type="week"]::-ms-input-placeholder, .comments-area .comment-respond input[type="month"]::-ms-input-placeholder, .comments-area .comment-respond input[type="text"]::-ms-input-placeholder, .comments-area .comment-respond input[type="email"]::-ms-input-placeholder, .comments-area .comment-respond input[type="url"]::-ms-input-placeholder, .comments-area .comment-respond input[type="password"]::-ms-input-placeholder, .comments-area .comment-respond input[type="search"]::-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]::-ms-input-placeholder, .comments-area .comment-respond input[type="number"]::-ms-input-placeholder, .comments-area .comment-respond textarea::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]::placeholder, .comments-area .comment-respond input[type="time"]::placeholder, .comments-area .comment-respond input[type="datetime-local"]::placeholder, .comments-area .comment-respond input[type="week"]::placeholder, .comments-area .comment-respond input[type="month"]::placeholder, .comments-area .comment-respond input[type="text"]::placeholder, .comments-area .comment-respond input[type="email"]::placeholder, .comments-area .comment-respond input[type="url"]::placeholder, .comments-area .comment-respond input[type="password"]::placeholder, .comments-area .comment-respond input[type="search"]::placeholder, .comments-area .comment-respond input[type="tel"]::placeholder, .comments-area .comment-respond input[type="number"]::placeholder, .comments-area .comment-respond textarea::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="time"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="week"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="month"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="text"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="email"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="url"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="password"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="search"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="number"]:focus::-webkit-input-placeholder, .comments-area .comment-respond textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:focus:-ms-input-placeholder, .comments-area .comment-respond textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:focus::-ms-input-placeholder, .comments-area .comment-respond textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::placeholder, .comments-area .comment-respond input[type="time"]:focus::placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::placeholder, .comments-area .comment-respond input[type="week"]:focus::placeholder, .comments-area .comment-respond input[type="month"]:focus::placeholder, .comments-area .comment-respond input[type="text"]:focus::placeholder, .comments-area .comment-respond input[type="email"]:focus::placeholder, .comments-area .comment-respond input[type="url"]:focus::placeholder, .comments-area .comment-respond input[type="password"]:focus::placeholder, .comments-area .comment-respond input[type="search"]:focus::placeholder, .comments-area .comment-respond input[type="tel"]:focus::placeholder, .comments-area .comment-respond input[type="number"]:focus::placeholder, .comments-area .comment-respond textarea:focus::placeholder {
  color: transparent;
}

.comments-area .comment-respond textarea {
  height: auto !important;
  padding-top: 15px;
}

.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 7px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: var(--whiteColor);
  font-weight: normal;
}

.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}

.comments-area .comment-respond .form-submit input {
  background: var(--mainColor);
  border: none;
  color: var(--whiteColor);
  padding: 12px 30px 10px;
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
}

.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: var(--blackColor);
  background: var(--whiteColor);
}

/*================================================
Live Stream Area CSS
=================================================*/
.live-stream-area {
  position: relative;
  z-index: 1;
  background-image: url(../images/awards-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.live-stream-area.bg-color {
  background-color: var(--blackColor);
  background-image: unset !important;
}

.live-stream-tabs .nav-tabs {
  background-color: var(--blackColor);
  border: none;
}

.live-stream-tabs .nav-tabs .nav-item {
  margin-bottom: -1px;
}

.live-stream-tabs .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 12px 45px 7px 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--whiteColor);
  -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  font-size: 22px;
  font-weight: 600;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.live-stream-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor) !important;
}

.live-stream-tabs .nav-tabs .nav-item .nav-link:hover {
  color: var(--mainColor);
}

.live-stream-tabs .tab-content {
  background-color: transparent;
  padding: 0;
}

.live-stream-tabs .tab-content .row {
  margin: 0;
}

.live-stream-tabs .tab-content .row .col-lg-7, .live-stream-tabs .tab-content .row .col-lg-5, .live-stream-tabs .tab-content .row .col-lg-4 {
  padding: 0;
}

/* .single-live-stream-box {
  overflow: hidden;
  position: relative;
} */

.single-live-stream-box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.single-live-stream-box .content {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: -20px;
  background-color: rgba(12, 3, 5, 0.75);
  padding: 28px 40px 20px;
  display: flex;
  justify-content:space-between
}

.single-live-stream-box .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: white;
}

.single-live-stream-box .content h3 i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #d2d0d1;
  color: var(--mainColor);
  font-size: 18px;
  display: inline-block;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  top: -5px;
  margin-left: 10px;
}

.single-live-stream-box:hover .content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.single-live-stream-box:hover .content h3 i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.single-live-stream-item {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.single-live-stream-item::before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--blackColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--blackColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--blackColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  top: 0;
}

.single-live-stream-item .content {
  position: absolute;
  left: 160px;
  right: 0;
  bottom: 0;
  /* for testing */
  top: 0 !important;
  padding: 30px 30px 25px;
  z-index: 2;
}

.single-live-stream-item .content .meta {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.single-live-stream-item .content .meta li {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-right: 5px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
}

.single-live-stream-item .content .meta li:last-child {
  margin-right: 0;
}

.single-live-stream-item .content .meta li:last-child::before {
  display: none;
}

.single-live-stream-item .content .meta li::before {
  content: '';
  position: absolute;
  right: -15.5px;
  top: 7px;
  width: 6px;
  height: 6px;
  background-color: var(--whiteColor);
  border-radius: 50%;
  display: none;
}

.single-live-stream-item .content h3 {
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 30px;
  font-weight: 700;
}

.single-live-stream-item .video-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  -webkit-box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.61);
          box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.61);
  background-color: var(--mainColor);
  z-index: 2;
}

.single-live-stream-item .link-btn {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.single-live-stream-item::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--mainColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--mainColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--mainColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  top: 0;
}

.single-live-stream-item:hover .video-btn {
  background-color: white !important;
  color: red !important;
}

.single-live-stream-item:hover::after {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.single-live-stream-item:hover::before {
  opacity: 0;
  visibility: hidden;
}

.single-live-stream-item:hover .content .meta li {
  color: var(--whiteColor);
}

/*================================================
Awards Area CSS
=================================================*/
.awards-area {
  position: relative;
  z-index: 1;
  background-image: url(../images/awards-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.awards-image {
  position: relative;
  z-index: 1;
  text-align: center;
}

.awards-image .shape {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 90px;
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 140s;
          animation-duration: 140s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.awards-content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.awards-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.awards-content h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.awards-content .row {
  padding-left: 60px;
  padding-right: 60px;
}

.awards-content .image {
  margin-top: 50px;
}

.awards-content .shape {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -1;
  bottom: -35px;
  margin-left: auto;
  margin-right: auto;
}

.awards-content .shape img:nth-child(1) {
  position: relative;
  top: 25px;
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*================================================
Tournament Details Area CSS
=================================================*/
.tournament-details-area .matches-tabs .nav-tabs {
  background-color: #000000;
}

.tournament-details-area .matches-tabs .tab-content {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 40px;
}

.tournament-details-desc {
  margin-bottom: 60px;
}

.tournament-details-desc .sub-title {
  display: block;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}

.tournament-details-desc h3 {
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 2px;
  font-size: 40px;
  font-weight: 700;
}

.tournament-details-desc .tournament-meta {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.tournament-details-desc .tournament-meta li {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2c0c0;
  padding-left: 62px;
  margin-right: 40px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
}

.tournament-details-desc .tournament-meta li .icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--whiteColor);
  line-height: 1;
  font-weight: 500;
  font-size: 45px;
}

.tournament-details-desc .tournament-meta li span {
  display: block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 24px;
  font-weight: 700;
}

.tournament-details-desc .tournament-meta li:last-child {
  margin-right: 0;
}

/*================================================
Magazine Demo CSS
=================================================*/
.magazine-demo {
  position: relative;
  z-index: 1;
}

.magazine-demo.bg-image {
  background-image: url(../images/main-banner-bg2.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.magazine-items {
  margin-left: -15px;
  margin-right: -15px;
}

.single-magazine-box {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
}

.single-magazine-box::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--mainColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--mainColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--mainColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  top: 0;
}

.single-magazine-box::before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--blackColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--blackColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--blackColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  top: 0;
}

.single-magazine-box .content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 30px 30px 25px;
}

.single-magazine-box .content .meta {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-bottom: 8px;
  padding-left: 0;
  list-style-type: none;
}

.single-magazine-box .content .meta li {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-right: 23px;
  position: relative;
  font-weight: 600;
  font-size: 17px;
}

.single-magazine-box .content .meta li:last-child {
  margin-right: 0;
}

.single-magazine-box .content .meta li:last-child::before {
  display: none;
}

.single-magazine-box .content .meta li::before {
  content: '';
  position: absolute;
  right: -15.5px;
  top: 7px;
  width: 6px;
  height: 6px;
  background-color: var(--whiteColor);
  border-radius: 50%;
}

.single-magazine-box .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 24px;
  font-weight: 700;
}

.single-magazine-box .content .read-more-btn {
  position: absolute;
  left: 30px;
  bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
  visibility: hidden;
}

.single-magazine-box .content .read-more-btn i {
  margin-right: 5px;
}

.single-magazine-box .link-btn {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.single-magazine-box:hover::after {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.single-magazine-box:hover::before {
  opacity: 0;
  visibility: hidden;
}

.single-magazine-box:hover .content .meta {
  -webkit-transform: translateY(-43px);
          transform: translateY(-43px);
}

.single-magazine-box:hover .content .meta li {
  color: var(--whiteColor);
}

.single-magazine-box:hover .content h3 {
  -webkit-transform: translateY(-43px);
          transform: translateY(-43px);
}

.single-magazine-box:hover .content .read-more-btn {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

.col-lg-6 .single-magazine-box .content {
  width: 60%;
}

/*================================================
Featured Games Area CSS
=================================================*/
.featured-games-inner {
  background-color: #1b060a;
}

.featured-games-content .content {
  padding: 110px 45px 45px;
}

.featured-games-content .content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.featured-games-content .content h2 {
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 32px;
  font-weight: 600;
}

.featured-games-content .content .read-more-btn {
  display: inline-block;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 5px;
}

.featured-games-content .content .read-more-btn i {
  margin-left: 5px;
}

.featured-games-content .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: 45px;
  top: 35px;
}

.featured-games-content .owl-theme .owl-nav [class*=owl-] {
  color: #8d8385;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.featured-games-content .owl-theme .owl-nav [class*=owl-].owl-next {
  margin-left: 20px;
}

.featured-games-content .owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
}

.featured-games-content .owl-theme .owl-dots {
  position: absolute;
  right: 45px;
  top: 45px;
}

.featured-games-content .owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 0;
  margin-left: 10px;
  background: transparent;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  border: 1px solid #8d8385;
}

.featured-games-content .owl-theme .owl-dots .owl-dot span::before {
  position: absolute;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #8d8385;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  margin: 4px;
}

.featured-games-content .owl-theme .owl-dots .owl-dot:hover span, .featured-games-content .owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--whiteColor);
}

.featured-games-content .owl-theme .owl-dots .owl-dot:hover span::before, .featured-games-content .owl-theme .owl-dots .owl-dot.active span::before {
  background-color: var(--whiteColor);
}

/*================================================
Team Area CSS
=================================================*/
.team-area .section-title {
  max-width: 540px;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.team-area .row {
  margin-left: 0;
  margin-right: 0;
}

.team-area .row .col-lg-3, .team-area .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}

.single-team-member {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.single-team-member .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.single-team-member .content {
  text-align: left;
  position: absolute;
  left: 0;
  bottom: -20px;
  right: 0;
  background-color: #1a0c09;
  padding: 15px 25px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-team-member .content h3 {
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.single-team-member .content span {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-top: 6px;
  font-size: 16px;
  font-weight: 600;
}

.single-team-member:hover .content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

/*================================================
Team Details Area CSS
=================================================*/
.team-details-desc .sub-title {
  display: block;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}

.team-details-desc h3 {
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 2px;
  font-size: 40px;
  font-weight: 700;
}

.team-details-desc .team-meta {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.team-details-desc .team-meta li {
  display: inline-block;
  margin-right: 40px;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2c0c0;
  padding-left: 62px;
  margin-right: 40px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
}

.team-details-desc .team-meta li .icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--whiteColor);
  line-height: 1;
  font-weight: 500;
  font-size: 45px;
}

.team-details-desc .team-meta li span {
  display: block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 24px;
  font-weight: 700;
}

.team-details-desc .team-meta li .social {
  margin-left: -5px;
}

.team-details-desc .team-meta li .social a {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
}

.team-details-desc .team-meta li .social a:last-child {
  margin-right: 0;
}

.team-details-desc .team-meta li:last-child {
  margin-right: 0;
}

.team-details-desc .player-list {
  margin-top: 60px;
}

.team-details-desc .player-list h3 {
  margin-bottom: 20px;
}

.team-details-desc .player-list .row {
  margin: 0;
}

.team-details-desc .player-list .row .col-lg-3 {
  padding: 0;
}

.team-details-desc .player-list .single-team-member .content h3 {
  margin-bottom: 0;
}

.team-details-desc .streams-list {
  margin-top: 60px;
}

.team-details-desc .streams-list h3 {
  margin-bottom: 20px;
}

.team-details-desc .streams-list .single-live-stream-item .content h3 {
  margin-bottom: 0;
}

.team-details-desc .partner-list {
  margin-top: 60px;
}

/*================================================
Player Details Area CSS
=================================================*/
.player-details-desc .sub-title {
  display: block;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}

.player-details-desc h3 {
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 2px;
  font-size: 40px;
  font-weight: 700;
}

.player-details-desc .player-meta {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.player-details-desc .player-meta li {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2c0c0;
  padding-left: 62px;
  color: var(--mainColor);
  margin-right: 40px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
}

.player-details-desc .player-meta li .icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--whiteColor);
  line-height: 1;
  font-weight: 500;
  font-size: 45px;
}

.player-details-desc .player-meta li span {
  display: block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 32px;
  font-weight: 700;
}

.player-details-desc .player-meta li .social {
  margin-top: 5px;
  margin-left: -8px;
}

.player-details-desc .player-meta li .social a {
  display: inline-block;
  margin-right: 10px;
  font-size: 25px;
}

.player-details-desc .player-meta li .social a:last-child {
  margin-right: 0;
}

.player-details-desc .player-meta li:last-child {
  margin-right: 0;
}

.player-details-desc .streams-list {
  margin-top: 60px;
}

.player-details-desc .streams-list h3 {
  margin-bottom: 20px;
}

.player-details-desc .streams-list .single-live-stream-item .content h3 {
  margin-bottom: 0;
}

.player-details-desc .partner-list {
  margin-top: 60px;
}

/*================================================
Services Area CSS
=================================================*/
.services-area .section-title {
  max-width: 100%;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 50px;
}

.single-services-box {
  margin-bottom: 30px;
}

.single-services-box .icon {
  font-size: 50px;
  line-height: 1;
  margin-bottom: 20px;
  color: #c2c0c0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-services-box h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 600;
}

.single-services-box:hover .icon {
  color: var(--whiteColor);
}

.row .col-lg-3:nth-child(2) .single-services-box, .row .col-lg-3:nth-child(3) .single-services-box {
  margin-bottom: 50px;
}

/*================================================
Popular Leagues Area CSS
=================================================*/
.single-popular-leagues-box {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #0b0a0b;
  border-radius: 5px;
  margin-bottom: 30px;
}

.single-popular-leagues-box .popular-leagues-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-radius: 5px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  border-radius: 5px 0 0 5px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image {
  height: 100%;
  width: 100%;
  border-radius: 5px 0 0 5px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image img {
  display: none;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg1 {
  background-image: url(../images/popular-leagues-img1.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg2 {
  background-image: url(../images/popular-leagues-img2.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg3 {
  background-image: url(../images/popular-leagues-img3.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg4 {
  background-image: url(../images/popular-leagues-img4.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content {
  padding: 30px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content h3 a {
  display: inline-block;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li {
  display: inline-block;
  margin-right: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-left: 28px;
  font-size: 22px;
  font-weight: 600;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li i {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--mainColor);
  font-weight: normal;
  font-size: 18px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li i.flaticon-coin, .single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li i.flaticon-game-computer {
  top: 1px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .join-now-btn {
  display: inline-block;
  margin-top: 25px;
  font-weight: 600;
  position: relative;
  padding-bottom: 3px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .join-now-btn::before {
  width: 100%;
  height: 1px;
  background: var(--mainColor);
  content: '';
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  left: 0;
  bottom: 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .join-now-btn:hover::before {
  width: 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  border-radius: 0 5px 5px 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date {
  width: 100%;
  height: 100%;
  background-color: var(--mainColor);
  text-align: center;
  color: var(--whiteColor);
  position: relative;
  border-radius: 0 5px 5px 0;
  z-index: 1;
  letter-spacing: 1px;
  font-size: 30px;
  font-weight: 700;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date span {
  display: block;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date h3 {
  color: var(--whiteColor);
  margin-bottom: 6px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date p {
  color: var(--whiteColor);
  line-height: initial;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 600;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date i {
  display: inline-block;
  font-size: 200px;
  color: var(--whiteColor);
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: .20;
}

/*================================================
Products Area CSS
=================================================*/
.products-area {
  position: relative;
  z-index: 2;
}

.single-products-box {
  margin-bottom: 30px;
  position: relative;
}

.single-products-box .sale {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: green;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-box .hot {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: var(--mainColor);
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-box .products-image {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.single-products-box .products-image img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-box .products-image .add-to-cart-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  position: absolute;
  overflow: hidden;
  padding: 12px 40px 7px 40px;
  bottom: 0;
  left: 0;
  display: block;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
  font-weight: 400;
}

.single-products-box .products-image .add-to-cart-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-box .products-image .add-to-cart-btn:hover {
  color: var(--mainColor);
}

.single-products-box .products-image .add-to-cart-btn:hover::before {
  width: 100%;
  height: 100%;
}

.single-products-box .products-content {
  margin-top: 30px;
}

.single-products-box .products-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 600;
}

.single-products-box .products-content .price {
  display: block;
  color: var(--mainColor);
  margin-top: 3px;
  font-size: 20px;
}

.single-products-box:hover .products-image img {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.single-products-box:hover .products-image .add-to-cart-btn {
  bottom: 15px;
  opacity: 1;
  visibility: visible;
}

.single-products-item {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.single-products-item .products-image {
  position: relative;
}

.single-products-item .products-image .bg-image img {
  width: auto !important;
  display: inline-block !important;
}

.single-products-item .products-image .main-image {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  margin: 0 auto;
  width: auto !important;
  display: inline-block !important;
}

.single-products-item .products-image .add-to-cart-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  overflow: hidden;
  padding: 12px 40px 7px 40px;
  bottom: 15px;
  left: 0;
  display: block;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
  font-weight: 400;
}

.single-products-item .products-image .add-to-cart-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-item .products-image .add-to-cart-btn:hover {
  color: var(--mainColor);
}

.single-products-item .products-image .add-to-cart-btn:hover::before {
  width: 100%;
  height: 100%;
}

.single-products-item .sale {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: green;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-item .hot {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: var(--mainColor);
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-item .products-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 600;
}

.single-products-item .products-content .price {
  display: block;
  color: var(--mainColor);
  margin-top: 3px;
  font-size: 20px;
  font-weight: 600;
}

.single-products-item:hover .products-image .add-to-cart-btn {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

.products-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.products-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: -65px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.products-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.products-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.products-slides.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.products-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.products-slides.owl-theme .owl-dots .owl-dot:hover span, .products-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.zelda-grid-sorting {
  margin-bottom: 40px;
}

.zelda-grid-sorting .result-count p {
  font-weight: 500;
}

.zelda-grid-sorting .result-count p .count {
  font-weight: 700;
  color: var(--whiteColor);
}

.zelda-grid-sorting .ordering {
  text-align: right;
}

.zelda-grid-sorting .ordering label {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 15px;
  text-transform: uppercase;
  font-size: var(--fontSize);
}

.zelda-grid-sorting .ordering select {
  background-color: transparent;
  cursor: pointer;
  color: var(--whiteColor);
  width: 145px;
  max-width: 145px;
  padding: 10px 15px 5px 10px;
  border: 1px solid var(--whiteColor);
  text-transform: uppercase;
  font-size: var(--fontSize);
}

.zelda-grid-sorting .ordering select option {
  color: var(--blackColor);
  outline: 0 !important;
  cursor: pointer;
  border: none;
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
  border-radius: 5px;
  margin-bottom: 30px;
}

.products-details-image a {
  display: block;
  border-radius: 5px;
}

.products-details-image a img {
  border-radius: 5px;
}

.products-details-desc {
  padding-left: 30px;
}

.products-details-desc h3 {
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 28px;
  font-weight: 700;
}

.products-details-desc .price {
  margin-bottom: 15px;
  color: var(--mainColor);
  letter-spacing: 1px;
  font-weight: 700;
}

.products-details-desc .price .old-price {
  text-decoration: line-through;
  color: #c2bcc6;
  font-weight: normal;
}

.products-details-desc .products-review {
  margin-bottom: 10px;
}

.products-details-desc .products-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 18px;
}

.products-details-desc .products-review .rating i {
  color: #ffba0a;
  display: inline-block;
  margin-right: -1px;
}

.products-details-desc .products-review .rating-count {
  display: inline-block;
  color: var(--whiteColor);
  border-bottom: 1px solid var(--blackColor);
  line-height: initial;
  position: relative;
  top: -3px;
  font-weight: 600;
}

.products-details-desc .products-review .rating-count:hover {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.products-details-desc p {
  color: #c2bcc6;
}

.products-details-desc .products-meta {
  margin-top: 20px;
}

.products-details-desc .products-meta span {
  display: block;
  color: var(--whiteColor);
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.products-details-desc .products-meta span span {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.products-details-desc .products-meta span span.sku {
  color: var(--mainColor);
}

.products-details-desc .products-meta span span.in-stock {
  color: var(--whiteColor);
}

.products-details-desc .products-meta span a {
  display: inline-block;
  color: var(--whiteColor);
  font-weight: 600;
  text-transform: capitalize;
}

.products-details-desc .products-meta span a:hover, .products-details-desc .products-meta span a:focus {
  color: var(--mainColor);
}

.products-details-desc .products-meta span:last-child {
  margin-bottom: 0;
}

.products-details-desc .products-add-to-cart {
  margin-top: 25px;
}

.products-details-desc .products-add-to-cart .input-counter {
  max-width: 200px;
  min-width: 200px;
  text-align: center;
  position: relative;
}

.products-details-desc .products-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: #22152c;
  cursor: pointer;
  color: var(--whiteColor);
  width: 40px;
  height: 100%;
  line-height: 56px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
}

.products-details-desc .products-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.products-details-desc .products-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.products-details-desc .products-add-to-cart .input-counter span:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.products-details-desc .products-add-to-cart .input-counter input {
  height: 45px;
  color: var(--whiteColor);
  outline: 0;
  display: block;
  border: none;
  background-color: #000000;
  text-align: center;
  width: 100%;
  padding-top: 4px;
  font-size: 17px;
  font-weight: 600;
}

.products-details-desc .products-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .input-counter input:-ms-input-placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .input-counter input::-ms-input-placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .input-counter input::placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .default-btn {
  max-width: 200px;
  min-width: 200px;
  text-align: center;
  margin-top: 15px;
}

.products-details-desc .products-share {
  margin-top: 30px;
}

.products-details-desc .products-share .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.products-details-desc .products-share .social li {
  display: inline-block;
}

.products-details-desc .products-share .social li span {
  display: inline-block;
  margin-right: 3px;
  font-weight: 600;
  position: relative;
  top: -2px;
  text-transform: uppercase;
}

.products-details-desc .products-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 37px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}

.products-details-desc .products-share .social li a:hover, .products-details-desc .products-share .social li a:focus {
  color: var(--mainColor);
  background-color: transparent;
}

.products-details-desc .products-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.facebook:hover, .products-details-desc .products-share .social li a.facebook:focus {
  color: #3b5998;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.twitter:hover, .products-details-desc .products-share .social li a.twitter:focus {
  color: #1da1f2;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.linkedin:hover, .products-details-desc .products-share .social li a.linkedin:focus {
  color: #007bb5;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.instagram:hover, .products-details-desc .products-share .social li a.instagram:focus {
  color: #c13584;
  background-color: transparent;
}

.products-details-tabs {
  margin-top: 50px;
}

.products-details-tabs .nav {
  text-align: center;
  padding-left: 0;
  margin-bottom: 40px;
  list-style-type: none;
  display: block;
  border-bottom-color: #22152c;
}

.products-details-tabs .nav .nav-item {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}

.products-details-tabs .nav .nav-item .nav-link {
  color: var(--whiteColor);
  border: none;
  border-bottom: 1px solid #22152c;
  padding: 0;
  background-color: transparent;
  position: relative;
  text-transform: uppercase;
  padding-bottom: 8px;
  font-size: 22px;
  font-weight: 700;
}

.products-details-tabs .nav .nav-item .nav-link::before {
  content: '';
  position: absolute;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: -2px;
}

.products-details-tabs .nav .nav-item .nav-link:hover, .products-details-tabs .nav .nav-item .nav-link.active {
  color: var(--mainColor);
}

.products-details-tabs .nav .nav-item .nav-link:hover::before, .products-details-tabs .nav .nav-item .nav-link.active::before {
  width: 100%;
}

.products-details-tabs .tab-content .tab-pane {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.products-details-tabs .tab-content .tab-pane p {
  line-height: 1.8;
  color: #c2bcc6;
}

.products-details-tabs .tab-content .tab-pane ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane ul li {
  margin-bottom: 15px;
  position: relative;
  color: var(--whiteColor);
  padding-left: 15px;
  color: #c2bcc6;
  font-size: 15px;
  font-family: var(--fontFamily3);
}

.products-details-tabs .tab-content .tab-pane ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--whiteColor);
}

.products-details-tabs .tab-content .tab-pane ul li:last-child {
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating {
  display: inline-block;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span {
  font-size: 17px;
  color: #cecfd2;
  margin-right: -2px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count span {
  display: block;
  font-size: 15px;
  color: var(--whiteColor);
  font-family: var(--fontFamily3);
}

.products-details-tabs .tab-content .tab-pane .products-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
  padding-left: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side div {
  text-transform: uppercase;
  font-size: var(--fontSize);
  font-weight: 600;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .right {
  text-align: right;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: var(--whiteColor);
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4CAF50;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196F3;
  border-radius: 5px;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments {
  margin-top: 40px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments h3 {
  border-bottom: 1px solid #22152c;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review {
  border-bottom: 1px solid #22152c;
  padding: 20px 0 20px 110px;
  position: relative;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .sub-comment {
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: -3px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating span {
  color: var(--whiteColor);
  position: relative;
  top: -2px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 5px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper {
  margin-top: 30px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper h3 {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper .comment-notes span {
  color: red;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form {
  margin-top: 20px;
  text-align: center;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group {
  margin-bottom: 25px;
  text-align: left;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating {
  text-align: left;
  overflow: hidden;
  max-width: 115px;
  margin-top: -5px;
  margin-bottom: 20px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label {
  float: right;
  position: relative;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:not(:first-of-type) {
  padding-right: 5px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:before {
  content: "\2605";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 27px;
  color: #CCCCCC;
  line-height: 1;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input {
  display: none;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input:checked ~ label:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover ~ label:before {
  color: #f6b500;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent {
  text-align: left;
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) {
  display: none;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-weight: 500;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--mainColor);
  position: absolute;
  top: 5.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:hover + label:before {
  border-color: var(--mainColor);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before {
  border-color: var(--mainColor);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form button {
  margin-top: 22px;
  border: none;
  display: block;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 5px;
  padding: 15px 60px 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: var(--fontSize);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form button:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

/*================================================
Games Area CSS
=================================================*/
.games-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.single-games-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-bottom: 30px;
}

.single-games-box .content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.single-games-box .content h3 {
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 32px;
  font-weight: 600;
}

.single-games-box .content .rating {
  color: #efc02f;
}

.single-games-box .content .btn-box {
  margin-top: 30px;
}

.single-games-box .content .btn-box .playstore-btn {
  margin-right: 10px;
  display: inline-block;
  text-align: left;
  background-color: var(--whiteColor);
  position: relative;
  color: var(--blackColor);
  border-radius: 60px;
  font-family: var(--fontFamily3);
  font-size: 20px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 65px;
  padding-right: 22px;
}

.single-games-box .content .btn-box .playstore-btn span {
  display: block;
  margin-bottom: -4px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.single-games-box .content .btn-box .playstore-btn img {
  position: absolute;
  left: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-games-box .content .btn-box .applestore-btn {
  margin-left: 10px;
  display: inline-block;
  text-align: left;
  background-color: var(--whiteColor);
  position: relative;
  color: var(--blackColor);
  border-radius: 60px;
  font-family: var(--fontFamily3);
  font-size: 20px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 70px;
  padding-right: 22px;
}

.single-games-box .content .btn-box .applestore-btn span {
  display: block;
  margin-bottom: -4px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.single-games-box .content .btn-box .applestore-btn img {
  position: absolute;
  left: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-games-box::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  top: 5%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), to(rgba(0, 0, 0, 0.8)));
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.8));
  z-index: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-games-box:hover::before {
  opacity: 0;
  visibility: hidden;
}

.owl-item .single-games-box .content .btn-box img {
  width: auto !important;
  display: inline-block !important;
}

.games-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.games-slides.owl-theme .owl-nav [class*=owl-] {
  color: #8d8385;
  font-size: 35px;
  margin: 0;
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0;
  background: transparent;
  border-radius: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.games-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
}

.games-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
}

.games-slides.owl-theme .owl-dots {
  margin-top: 15px;
}

.games-slides.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 0 5px;
  background: transparent;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  border: 1px solid #8d8385;
}

.games-slides.owl-theme .owl-dots .owl-dot span::before {
  position: absolute;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #8d8385;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  margin: 4px;
}

.games-slides.owl-theme .owl-dots .owl-dot:hover span, .games-slides.owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--whiteColor);
}

.games-slides.owl-theme .owl-dots .owl-dot:hover span::before, .games-slides.owl-theme .owl-dots .owl-dot.active span::before {
  background-color: var(--whiteColor);
}

/*================================================
Upcoming Tournaments Area CSS
=================================================*/
.single-tournaments-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: #0b0a0b;
}

.single-tournaments-box .tournaments-image {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.single-tournaments-box .tournaments-image .image {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-tournaments-box .tournaments-image .image img {
  display: none;
}

.single-tournaments-box .tournaments-image .image.bg1 {
  background-image: url(../images/upcoming-tournaments-img1.jpg);
}

.single-tournaments-box .tournaments-image .image.bg2 {
  background-image: url(../images/upcoming-tournaments-img2.jpg);
}

.single-tournaments-box .tournaments-image .image.bg3 {
  background-image: url(../images/upcoming-tournaments-img3.jpg);
}

.single-tournaments-box .tournaments-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.single-tournaments-box .tournaments-content .content {
  padding: 50px 40px;
}

.single-tournaments-box .tournaments-content .content .date {
  color: var(--mainColor);
  margin-top: -5px;
  font-size: 60px;
  font-weight: 700;
}

.single-tournaments-box .tournaments-content .content .date sup {
  text-transform: uppercase;
  color: var(--whiteColor);
  top: -23px;
  left: -5px;
  font-size: 20px;
  font-weight: 500;
}

.single-tournaments-box .tournaments-content .content .date sub {
  left: -40px;
  top: -1px;
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 500;
}

.single-tournaments-box .tournaments-content .content h3 {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: 600;
}

.single-tournaments-box .tournaments-content .content h3 a {
  display: inline-block;
}

.single-tournaments-box .tournaments-content .content .meta {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: -15px;
  margin-right: -15px;
}

.single-tournaments-box .tournaments-content .content .meta li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  font-size: 18px;
  letter-spacing: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.single-tournaments-box .tournaments-content .content .meta li span {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 700;
}

.single-tournaments-box .tournaments-content .content .info {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}

.single-tournaments-box .tournaments-content .content .info li {
  display: inline-block;
  margin-right: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-left: 28px;
  font-size: 22px;
  font-weight: 600;
}

.single-tournaments-box .tournaments-content .content .info li i {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--mainColor);
  font-weight: normal;
  font-size: 18px;
}

.single-tournaments-box .tournaments-content .content .info li i.flaticon-coin, .single-tournaments-box .tournaments-content .content .info li i.flaticon-game-computer {
  top: 1px;
}

.single-tournaments-box .tournaments-content .content .default-btn {
  margin-top: 25px;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  position: relative;
  z-index: 1;
}

.partner-area .section-title {
  margin-bottom: 50px;
}

.partner-area .section-title .sub-title {
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 30px;
}

.partner-area.bg-image {
  background-image: url(../images/awards-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-partner-item {
  text-align: center;
  margin-bottom: 30px;
}

.single-partner-item img {
  width: auto !important;
  display: inline-block !important;
}

.partner-shape1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  -webkit-transform: translateY(-35%);
          transform: translateY(-35%);
  z-index: -1;
}

/*================================================
Top Team Area CSS
=================================================*/
.single-top-team-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}

.single-top-team-box .bg-image img {
  display: inline-block !important;
  width: auto !important;
}

.single-top-team-box .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-top-team-box .content img {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 25px;
}

.single-top-team-box .content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
}

.single-top-team-box .content ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-top-team-box .content ul li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.single-top-team-box .content ul li a {
  color: #bcb6b8;
  font-size: 20px;
}

.single-top-team-box .content ul li a:hover {
  color: var(--whiteColor);
}

.single-top-team-item {
  margin-bottom: 30px;
  position: relative;
  background-color: #0b0a0b;
  text-align: center;
  padding: 40px;
  border-radius: 5px;
}

.single-top-team-item img {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 25px;
}

.single-top-team-item h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
}

.single-top-team-item ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-top-team-item ul li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.single-top-team-item ul li a {
  color: #bcb6b8;
  font-size: 20px;
}

.single-top-team-item ul li a:hover {
  color: var(--whiteColor);
}

.top-team-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.top-team-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: -65px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.top-team-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.top-team-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.top-team-slides.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.top-team-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.top-team-slides.owl-theme .owl-dots .owl-dot:hover span, .top-team-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

/*================================================
Our Experts Area CSS
=================================================*/
.our-experts-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.our-experts-area .container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}

.our-experts-area .container-fluid .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}

.our-experts-image {
  height: 100%;
  background-image: url(../images/experts-img.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.our-experts-image img {
  display: none;
}

.our-experts-content {
  padding: 160px 80px 150px;
  background-image: url(../images/experts-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.our-experts-content .content {
  max-width: 500px;
}

.our-experts-content .content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--whiteColor);
  font-size: 20px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.our-experts-content .content h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.our-experts-content .content .default-btn {
  margin-top: 25px;
  background-color: var(--blackColor);
}

/*================================================
Stream Schedule Area CSS
=================================================*/
.single-stream-schedule-box {
  border-radius: 60px;
  position: relative;
  border: 1px solid #1d1b1b;
  text-align: center;
  margin-bottom: 30px;
  padding-left: 100px;
  padding-right: 50px;
}

.single-stream-schedule-box .date {
  position: absolute;
  left: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 1.2;
  color: var(--mainColor);
  font-weight: 700;
  font-size: 25px;
}

.single-stream-schedule-box .content {
  border-left: 1px solid #1d1b1b;
  border-radius: 60px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.single-stream-schedule-box .content .time {
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}

.single-stream-schedule-box .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 700;
}

.single-stream-schedule-box.not-streaming .date {
  text-decoration: line-through;
  color: red;
}

.single-stream-schedule-box.not-streaming .content .time {
  color: red;
}

.single-stream-schedule-box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 60px;
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  position: relative;
  z-index: 2;
}

.subscribe-inner {
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
  background-image: url(../images/subscribe-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.subscribe-inner .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.subscribe-inner h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.subscribe-inner .newsletter-form {
  position: relative;
  max-width: 700px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.subscribe-inner .newsletter-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  color: #929191;
  font-size: 22px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.subscribe-inner .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #1a0c09;
  padding: 5px 0 0 58px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
  border: none;
  font-size: 18px;
  font-weight: 400;
}

.subscribe-inner .newsletter-form .input-newsletter::-webkit-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter:-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter::-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter::placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter:focus {
  padding-top: 3px;
}

.subscribe-inner .newsletter-form .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  padding-top: 13.5px;
}

.subscribe-inner .newsletter-form .validation-danger {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
}

.section-title .newsletter-form {
  position: relative;
  max-width: 700px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.section-title .newsletter-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  color: #929191;
  font-size: 22px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.section-title .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #0c0305;
  padding: 5px 0 0 58px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
  border: none;
  font-size: 18px;
  font-weight: 400;
}

.section-title .newsletter-form .input-newsletter::-webkit-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter:-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter::-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter::placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter:focus {
  padding-top: 3px;
}

.section-title .newsletter-form .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.section-title .newsletter-form .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.section-title .newsletter-form .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.section-title .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}

.section-title .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  padding-top: 13.5px;
}

/*================================================
History Area CSS
=================================================*/
.history-area {
  position: relative;
  z-index: 1;
  padding-top: 280px;
  padding-bottom: 280px;
  background-image: url(../images/history-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.history-area::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #1b060a;
  opacity: .50;
}

.history-content {
  text-align: center;
}

.history-content .video-btn {
  display: inline-block;
  width: 82px;
  line-height: 83px;
  height: 82px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  color: var(--mainColor);
  font-size: 35px;
}

.history-content .video-btn:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.history-content h2 {
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 30px;
  font-family: var(--fontFamily2);
  font-size: 32px;
}

/*================================================
Feedback Area CSS
=================================================*/
.single-feedback-item {
  margin-bottom: 30px;
  background-color: #1b060a;
  padding: 105px 40px 40px;
  position: relative;
}

.single-feedback-item p {
  color: #bbbbbb;
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-weight: 600;
  font-size: 16px;
}

.single-feedback-item span {
  display: block;
  margin-top: 22px;
  color: var(--mainColor);
  padding-left: 25px;
  position: relative;
  line-height: 1;
  font-size: 20px;
  font-weight: 600;
}

.single-feedback-item span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 15px;
  height: 1px;
  background-color: var(--mainColor);
}

.single-feedback-item::before {
  content: "\f114";
  position: absolute;
  left: 40px;
  color: #bbbbbb;
  top: 40px;
  line-height: 1;
  font-family: Flaticon;
  font-size: 45px;
}

.single-feedback-item:hover p {
  color: var(--whiteColor);
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 210px;
  padding-bottom: 0px;
}

.page-title-bg1 {
  background-image: url(../images/main-banner-bg1.jpg);
}

.page-title-content img {
  margin-bottom: 35px;
}

.page-title-content .player-image {
  max-width: 120px;
  width: 120px;
  border-radius: 50%;
}

.page-title-content h1 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  position: relative;
  display: inline-block;
  /* -webkit-animation: glitch 1s linear infinite;
          animation: glitch 1s linear infinite; */
  overflow: hidden;
  font-size: 130px;
  font-weight: 700;
}
.page-title-content h1:before{
  display: none;
}

/* .page-title-content h1:before, .page-title-content h1:after {
  content: attr(title);
  position: absolute;
  left: 0;
} */

/* .page-title-content h1:before {
  -webkit-animation: glitchTop 1s linear infinite;
          animation: glitchTop 1s linear infinite;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
          clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

.page-title-content h1:after {
  -webkit-animation: glitchBotom 2.5s linear infinite;
          animation: glitchBotom 2.5s linear infinite;
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
          clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
} */

.page-title-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-top: -8px;
  font-size: 20px;
  font-weight: 600;
}

@-webkit-keyframes glitch {
  2%, 64% {
    -webkit-transform: translate(2px, 0) skew(0deg);
            transform: translate(2px, 0) skew(0deg);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0) skew(0deg);
            transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    -webkit-transform: translate(0, 0) skew(5deg);
            transform: translate(0, 0) skew(5deg);
  }
}

@keyframes glitch {
  2%, 64% {
    -webkit-transform: translate(2px, 0) skew(0deg);
            transform: translate(2px, 0) skew(0deg);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0) skew(0deg);
            transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    -webkit-transform: translate(0, 0) skew(5deg);
            transform: translate(0, 0) skew(5deg);
  }
}

@-webkit-keyframes glitchTop {
  2%, 64% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  62% {
    -webkit-transform: translate(13px, -1px) skew(-13deg);
            transform: translate(13px, -1px) skew(-13deg);
  }
}

@keyframes glitchTop {
  2%, 64% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  62% {
    -webkit-transform: translate(13px, -1px) skew(-13deg);
            transform: translate(13px, -1px) skew(-13deg);
  }
}

@-webkit-keyframes glitchBotom {
  2%, 64% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  62% {
    -webkit-transform: translate(-22px, 5px) skew(21deg);
            transform: translate(-22px, 5px) skew(21deg);
  }
}

@keyframes glitchBotom {
  2%, 64% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  62% {
    -webkit-transform: translate(-22px, 5px) skew(21deg);
            transform: translate(-22px, 5px) skew(21deg);
  }
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.single-gallery-item a {
  display: block;
  border-radius: 5px;
}

.single-gallery-item a img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-gallery-item:hover a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}

.error-content {
  text-align: center;
}

.error-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  margin-top: 50px;
  font-size: 50px;
  font-weight: 700;
}

.error-content p {
  color: #c2c0c0;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.error-content .default-btn {
  margin-top: 30px;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
  background-image: url(../images/main-banner-bg1.jpg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.coming-soon-content {
  max-width: 700px;
  overflow: hidden;
  text-align: center;
  background-color: #22152c;
  padding: 45px 40px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.coming-soon-content .logo {
  display: inline-block;
  margin-bottom: 35px;
}

.coming-soon-content h2 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0;
}

.coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-content #timer div {
  background-color: #000000;
  width: 100px;
  height: 105px;
  border-radius: 5px;
  font-size: 40px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}

.coming-soon-content #timer div span {
  display: block;
  margin-top: -5px;
  font-size: 17px;
  font-weight: 600;
}

.coming-soon-content form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}

.coming-soon-content form .form-group .input-newsletter {
  display: block;
  width: 100%;
  border: none;
  color: var(--whiteColor);
  background-color: #000000;
  height: 55px;
  padding-left: 20px;
  padding-top: 2px;
  padding-right: 0;
  padding-bottom: 0;
  border-radius: 5px;
  font-size: 16.5px;
  font-weight: 500;
}

.coming-soon-content form .form-group .input-newsletter::-webkit-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter:-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter::-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter::placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::placeholder {
  color: transparent;
}

.coming-soon-content form .validation-danger {
  margin-top: 15px;
  color: red;
}

.coming-soon-content form .validation-success {
  margin-top: 15px;
}

.coming-soon-content form p {
  margin-bottom: 0;
  margin-top: 30px;
}

/*================================================
Social Area CSS
=================================================*/
.single-social-box {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
}

.single-social-box .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 400;
}

.single-social-box .content i {
  line-height: 1;
  font-size: 30px;
  position: relative;
  top: 6.5px;
  margin-right: 5px;
}

.single-social-box .shape img:nth-child(1) {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-social-box .shape img:nth-child(2) {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  z-index: -1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
  text-align: center;
  margin: 0 auto;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-social-box .link-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: block;
}

.single-social-box:hover .shape img:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}

.single-social-box:hover .shape img:nth-child(2) {
  opacity: 1;
  visibility: visible;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 60px;
  text-align: center;
}

.faq-accordion-tab .tabs li {
  margin-left: 10px;
  margin-right: 10px;
}

.faq-accordion-tab .tabs li a {
  color: var(--whiteColor);
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  display: block;
  text-transform: uppercase;
  background-color: #22152c;
  letter-spacing: 1px;
  font-size: 19px;
  font-weight: 600;
}

.faq-accordion-tab .tabs li a i {
  font-size: 30px;
  font-weight: 400;
}

.faq-accordion-tab .tabs li a span {
  display: block;
  margin-top: 8px;
}

.faq-accordion-tab .tabs li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.faq-accordion-tab .tabs li.current a {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
  border-radius: 5px;
  display: block;
  background: #22152c;
  margin-bottom: 15px;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
  padding: 20px 25px 17px 60px;
  text-decoration: none;
  position: relative;
  color: var(--whiteColor);
  text-transform: uppercase;
  display: block;
  letter-spacing: 1px;
  font-size: 21px;
  font-weight: 600;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 25px;
  top: 20px;
  font-size: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.faq-accordion .accordion .accordion-title.active i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: -5px;
  padding-bottom: 20px;
  padding-right: 25px;
  padding-left: 60px;
}

.faq-accordion .accordion .accordion-content p {
  line-height: 1.8;
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}

.tab .tabs-item {
  display: none;
}

.tab .tabs-item:first-child {
  display: block;
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 35px;
}

.pagination-area .page-numbers {
  width: 38px;
  height: 38px;
  background-color: var(--whiteColor);
  color: var(--blackColor);
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  line-height: 41px;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 20px;
  font-weight: 600;
}

.pagination-area .page-numbers:hover, .pagination-area .page-numbers.current {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.pagination-area .page-numbers i {
  position: relative;
  top: 2.2px;
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
  padding-left: 15px;
}

.widget-area.widget-left-sidebar {
  padding-right: 15px;
  padding-left: 0;
}

.widget-area .widget {
  margin-bottom: 40px;
}

.widget-area .widget:last-child {
  margin-bottom: 0;
}

.widget-area .widget .widget-title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 6px;
  text-transform: uppercase;
  border-bottom: 1px solid #1f1625;
  letter-spacing: 1px;
  font-size: 23px;
  font-weight: 600;
}

.widget-area .widget .widget-title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: var(--mainColor);
}

.widget-area .widget_search form {
  position: relative;
}

.widget-area .widget_search form .screen-reader-text {
  display: none;
}

.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}

.widget-area .widget_search form .search-field {
  height: 50px;
  color: var(--whiteColor);
  background-color: #22152c;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 2px 0 0 15px;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-family: var(--fontFamily3);
  font-size: 14px;
  font-weight: 500;
}

.widget-area .widget_search form .search-field::-webkit-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field:-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field::-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field::placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field:focus::-webkit-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus:-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field::-webkit-search-cancel-button {
  display: none;
}

.widget-area .widget_search form button {
  border: none;
  background-color: transparent;
  color: var(--whiteColor);
  height: 40px;
  width: 40px;
  position: absolute;
  right: 5px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 5px;
  font-size: 20px;
}

.widget-area .widget_search form button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  border-radius: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.widget-area .widget_zelda_posts_thumb {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_zelda_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}

.widget-area .widget_zelda_posts_thumb .item:last-child {
  margin-bottom: 0;
}

.widget-area .widget_zelda_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../images/main-blog-img1.jpg);
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../images/main-blog-img2.jpg);
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../images/main-blog-img3.jpg);
}

.widget-area .widget_zelda_posts_thumb .item .thumb::before, .widget-area .widget_zelda_posts_thumb .item .thumb::after {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: '';
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.widget-area .widget_zelda_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}

.widget-area .widget_zelda_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}

.widget-area .widget_zelda_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}

.widget-area .widget_zelda_posts_thumb .item .info span {
  display: block;
  color: var(--whiteColor);
  text-transform: uppercase;
  margin-top: -2px;
  margin-bottom: 8px;
  font-size: 15px;
}

.widget-area .widget_zelda_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 600;
}

.widget-area .widget_zelda_posts_thumb .item .info .title a {
  display: inline-block;
}

.widget-area .widget_zelda_posts_thumb .item:hover .thumb::before, .widget-area .widget_zelda_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  padding-left: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
}

.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
  background: var(--mainColor);
  height: 8px;
  width: 8px;
  content: '';
  border-radius: 50%;
  left: 0;
  top: 7px;
  position: absolute;
}

.widget-area .widget_categories ul li a {
  display: inline-block;
}

.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 22px;
}

.widget-area .tagcloud a {
  display: inline-block;
  background: #f5f5f5;
  color: var(--blackColor);
  padding: 8px 15px 5px;
  border: none;
  border-radius: 3px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px !important;
  margin-top: 8px;
  margin-right: 4px;
}

.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.widget-area .widget_match_list {
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
}

.widget-area .widget_match_list .single-match-list {
  text-align: center;
  position: relative;
  border-bottom: 1px solid #252525;
  padding-top: 22px;
  padding-bottom: 15px;
}

.widget-area .widget_match_list .single-match-list:last-child {
  border-bottom: none;
}

.widget-area .widget_match_list .single-match-list img {
  max-width: 60px;
  position: absolute;
}

.widget-area .widget_match_list .single-match-list img.team-1 {
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-area .widget_match_list .single-match-list img.team-2 {
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-area .widget_match_list .single-match-list .date {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}

.widget-area .widget_match_list .single-match-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_match_list .single-match-list ul li {
  display: inline-block;
  line-height: 1;
  position: relative;
  font-size: 40px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
}

.widget-area .widget_match_list .single-match-list ul li::before {
  content: ':';
  position: absolute;
  right: -20px;
  top: 5px;
  font-size: 25px;
}

.widget-area .widget_match_list .single-match-list ul li:first-child {
  margin-left: 0;
}

.widget-area .widget_match_list .single-match-list ul li:last-child {
  margin-right: 0;
}

.widget-area .widget_match_list .single-match-list ul li:last-child::before {
  display: none;
}

.widget-area .widget_instagram ul {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

.widget-area .widget_instagram ul li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.widget-area .widget_instagram ul li .box {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.widget-area .widget_instagram ul li .box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.widget-area .widget_instagram ul li .box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.widget-area .widget_instagram ul li .box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_instagram ul li .box::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

.widget-area .widget_instagram ul li .box:hover::before {
  opacity: 0.5;
  visibility: visible;
}

.widget-area .widget_instagram ul li .box:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.widget-area .widget_instagram ul li .box:hover i {
  opacity: 1;
  visibility: visible;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead tr {
  border-color: #2e2e2e;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 25px 0 25px;
  text-transform: uppercase;
  color: var(--whiteColor);
  border: none;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 700;
}

.cart-table table tbody tr {
  border-color: #2e2e2e;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: var(--whiteColor);
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  padding-left: 0;
  padding-right: 0;
  border-color: #22152c;
  border-left: none;
  border-right: none;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
  width: 80px;
}

.cart-table table tbody tr td.product-name a {
  display: inline-block;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: red;
  float: right;
  position: relative;
  top: -1px;
  font-size: 18px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: #22152c;
  cursor: pointer;
  color: var(--whiteColor);
  width: 40px;
  height: 100%;
  line-height: 56px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: var(--whiteColor);
  outline: 0;
  display: block;
  border: none;
  background-color: #000000;
  text-align: center;
  width: 100%;
  padding-top: 4px;
  font-size: 17px;
  font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-table .table > :not(:first-child) {
  border-top: none;
}

.cart-buttons {
  margin-top: 30px;
}

.cart-buttons .shopping-coupon-code {
  position: relative;
  max-width: 530px;
}

.cart-buttons .shopping-coupon-code button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: var(--whiteColor);
  color: var(--blackColor);
  border: none;
  padding: 0 25px;
  line-height: 55px;
  outline: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
}

.cart-buttons .shopping-coupon-code button:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.cart-totals {
  background: #22152c;
  padding: 40px;
  max-width: 500px;
  border-radius: 5px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

.cart-totals h3 {
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.cart-totals ul {
  padding-left: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #2a2828;
  padding: 15px 15px 10px;
  color: var(--whiteColor);
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  font-size: 22px;
  border-top: none;
}

.cart-totals ul li:last-child span {
  color: var(--whiteColor);
  font-weight: 600;
}

.cart-totals ul li span {
  float: right;
  color: var(--whiteColor);
  font-weight: normal;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  padding: 15px 20px;
  border-top: 3px solid var(--mainColor);
  position: relative;
  margin-bottom: 40px;
}

.user-actions i {
  margin-right: 2px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.user-actions span {
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
}

.user-actions span a {
  display: inline-block;
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #22152c;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.billing-details .title::before {
  content: '';
  position: absolute;
  background: var(--mainColor);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.billing-details .form-group label .required {
  color: red;
}

.billing-details .form-group select {
  background-color: #22152c;
  border: none;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  color: var(--whiteColor);
  padding-top: 2.5px;
  cursor: pointer;
}

.billing-details .form-group .form-control {
  color: var(--whiteColor);
}

.billing-details .form-check {
  margin-bottom: 20px;
}

.billing-details .form-check .form-check-label {
  color: var(--whiteColor);
  font-weight: 500;
  letter-spacing: 1px;
}

.billing-details .form-check label {
  position: relative;
  left: -3px;
  top: 0;
  font-weight: 500;
}

.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #22152c;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.order-details .title::before {
  content: '';
  position: absolute;
  background: var(--mainColor);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.order-details .order-table table {
  margin-bottom: 0;
}

.order-details .order-table table thead tr {
  border-color: #2e2e2e;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #22152c;
  padding: 14px 20px 10px;
  color: var(--whiteColor);
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
}

.order-details .order-table table tbody {
  border-color: #2e2e2e;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #bab9bb;
  white-space: nowrap;
  border-color: #22152c;
  font-size: 16.5px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 12px;
}

.order-details .order-table table tbody tr td.product-name a {
  display: inline-block;
  color: #bab9bb;
}

.order-details .order-table table tbody tr td.product-name a:hover {
  color: var(--mainColor);
}

.order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
  color: var(--whiteColor);
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--fontSize);
}

.order-details .order-table table tbody tr td.shipping-price, .order-details .order-table table tbody tr td.order-subtotal-price, .order-details .order-table table tbody tr td.product-subtotal {
  color: var(--whiteColor);
  font-weight: 600;
  font-size: var(--fontSize);
}

.order-details .order-table .table > :not(:first-child) {
  border-top: none;
}

.order-details .payment-box {
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  margin-top: 30px;
  padding: 30px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  color: var(--whiteColor);
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::before, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: var(--whiteColor);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--mainColor);
  position: absolute;
  top: 7px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.order-details .payment-box .default-btn {
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info {
  padding-right: 15px;
}

.contact-info .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}

.contact-info h2 {
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.contact-info ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 35px;
  margin-bottom: 0;
}

.contact-info ul li {
  position: relative;
  margin-bottom: 35px;
  padding-left: 100px;
}

.contact-info ul li .icon {
  width: 75px;
  height: 85px;
  background-color: #22152c;
  border-radius: 3px;
  position: absolute;
  text-align: center;
  left: 0;
  font-size: 45px;
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 0;
}

.contact-info ul li .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 55%;
  line-height: 1;
  -webkit-transform: translateY(-55%);
          transform: translateY(-55%);
}

.contact-info ul li h3 {
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.contact-info ul li p {
  font-weight: 500;
  max-width: 300px;
  margin-bottom: 0;
  margin-top: 2px;
}

.contact-info ul li .social-box {
  margin-top: 15px;
}

.contact-info ul li .social-box a {
  display: inline-block;
  font-size: 25px;
  margin-right: 7px;
}

.contact-info ul li .social-box a:last-child {
  margin-right: 0;
}

.contact-info ul li:hover .icon {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.contact-info ul li:last-child {
  margin-bottom: 0;
}

.contact-form {
  overflow: hidden;
  padding: 35px;
  margin-left: 15px;
  border-radius: 5px;
  background-color: var(--mainColor);
  background-image: url(../images/contact-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-form h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 50px;
  font-weight: 600;
}

.contact-form p {
  margin-top: 5px;
  margin-bottom: 0;
}

.contact-form form {
  margin-top: 30px;
}

.contact-form form .form-group {
  margin-bottom: 15px;
}

.contact-form form input, .contact-form form textarea {
  display: block;
  width: 100%;
  border: none;
  color: var(--whiteColor);
  background-color: var(--blackColor);
  height: 50px;
  padding-left: 15px;
  padding-top: 2px;
  padding-right: 0;
  padding-bottom: 0;
  border-radius: 5px;
  font-size: 16.5px;
  font-weight: 500;
}

.contact-form form input::-webkit-input-placeholder, .contact-form form textarea::-webkit-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input:-ms-input-placeholder, .contact-form form textarea:-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input::-ms-input-placeholder, .contact-form form textarea::-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input::placeholder, .contact-form form textarea::placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input:focus::-webkit-input-placeholder, .contact-form form textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.contact-form form input:focus:-ms-input-placeholder, .contact-form form textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.contact-form form input:focus::-ms-input-placeholder, .contact-form form textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.contact-form form input:focus::placeholder, .contact-form form textarea:focus::placeholder {
  color: transparent;
}

.contact-form form textarea {
  height: auto !important;
  padding-top: 15px;
}

.contact-form form .default-btn {
  margin-top: 10px;
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

.contact-form form .default-btn::before {
  background-color: var(--blackColor);
}

.contact-form form .default-btn:hover {
  color: var(--whiteColor);
}

.contact-form form .help-block ul {
  margin-bottom: 0;
  margin-top: 12px;
}

.contact-form form .help-block ul li {
  color: var(--whiteColor);
}

.contact-form form #msgSubmit {
  color: var(--whiteColor) !important;
  margin: 0 !important;
}

.contact-form form #msgSubmit.text-danger, .contact-form form #msgSubmit.text-success {
  margin-top: 15px !important;
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
  padding: 40px 50px;
  border-radius: 5px;
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  margin-right: 30px;
}

.login-form h2 {
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 40px;
  font-weight: 600;
}

.login-form form .form-group {
  margin-bottom: 25px;
}

.login-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.login-form form .form-group .form-control {
  background-color: #000000 !important;
}

.login-form form .remember-me-wrap {
  margin-bottom: 0;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) {
  display: none;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-weight: 600;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--mainColor);
  position: absolute;
  top: 5.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.login-form form .remember-me-wrap [type="checkbox"]:hover + label:before {
  border-color: var(--mainColor);
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before {
  border-color: var(--mainColor);
}

.login-form form .lost-your-password-wrap {
  text-align: right;
}

.login-form form .lost-your-password-wrap a {
  display: inline-block;
  position: relative;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
}

.login-form form .lost-your-password-wrap a::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: #eeeeee;
}

.login-form form .lost-your-password-wrap a::after {
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: 0;
  content: '';
  background-color: var(--mainColor);
}

.login-form form .lost-your-password-wrap a:hover::before {
  width: 0;
}

.login-form form .lost-your-password-wrap a:hover::after {
  width: 100%;
}

.login-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 16px 30px 12px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
}

.login-form form button:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

.register-form {
  padding-left: 30px;
}

.register-form h2 {
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 40px;
  font-weight: 600;
}

.register-form form .form-group {
  margin-bottom: 25px;
}

.register-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.register-form form .description {
  font-style: italic;
  font-size: 13.5px;
  margin-top: -10px;
  margin-bottom: 0;
}

.register-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 16px 30px 12px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
}

.register-form form button:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding-bottom: 50px;
}

.footer-area.ptb-70 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-content {
  text-align: center;
}

.footer-content .footer-menu {
  padding-left: 0;
  list-style-type: none;
  margin-top: 40px;
  margin-bottom: 0;
}

.footer-content .footer-menu li {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.footer-content .footer-menu li a {
  display: inline-block;
  text-transform: uppercase;
  padding: 0;
  color: var(--whiteColor);
  font-size: 18px;
  font-weight: 500;
}

.footer-content .footer-menu li a:hover {
  color: var(--mainColor);
}

.footer-content .footer-menu li:first-child {
  margin-left: 0;
}

.footer-content .footer-menu li:last-child {
  margin-right: 0;
}

.copyright-area {
  border-top: 1px solid #1a0c09;
  padding-top: 30px;
  padding-bottom: 30px;
}

.copyright-area p {
  text-transform: uppercase;
  line-height: initial;
  text-transform: uppercase;
  font-size: var(--fontSize);
  font-family: var(--fontFamily1);
  color: white;
}

.copyright-area p i {
  position: relative;
  top: 2.3px;
}

.copyright-area p a {
  color: var(--mainColor);
  position: relative;
  font-weight: 600;
}

.copyright-area p a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 4px;
  right: 0;
  height: 1px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 0;
}

.copyright-area p a:hover::before {
  width: 100%;
}

.copyright-area .lang-switcher {
  text-align: right;
  position: relative;
}

.copyright-area .lang-switcher span {
  display: inline-block;
  margin-right: 15px;
  text-transform: uppercase;
  font-size: var(--fontSize);
}

.copyright-area .lang-switcher select {
  background-color: transparent;
  cursor: pointer;
  color: var(--whiteColor);
  width: 145px;
  max-width: 145px;
  padding: 10px 15px 5px 10px;
  border: 1px solid var(--whiteColor);
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--fontSize);
}

.copyright-area .lang-switcher select option {
  color: var(--blackColor);
  outline: 0 !important;
  cursor: pointer;
  border: none;
}

.copyright-area .lang-switcher::before {
  font-family: Flaticon;
  content: "\f105";
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.footer-area-bg-image {
  background-image: url(../images/footer-bg.png);
  background-position: bottom left;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-style-two {
  background-color: #1b060a;
  padding-top: 100px;
  position: relative;
  z-index: 1;
}

.footer-map {
  position: absolute;
  right: 20%;
  top: 40%;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  z-index: -1;
  opacity: .8;
}

.footer-map img {
  -webkit-animation: moveleftbounce 5s linear infinite;
          animation: moveleftbounce 5s linear infinite;
}

.single-footer-widget {
  margin-bottom: 30px;
}

.single-footer-widget .logo {
  margin-bottom: 15px;
  font-size: 15px;
  display: inline-block;
}

.single-footer-widget p {
  color: #d0c6c6;
}

.single-footer-widget .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}

.single-footer-widget .social-link li {
  display: inline-block;
  margin-right: 5px;
}

.single-footer-widget .social-link li:last-child {
  margin-right: 0;
}

.single-footer-widget .social-link li a {
  width: 34px;
  height: 34px;
  text-align: center;
  color: var(--whiteColor);
  background-color: #0c0305;
  font-size: 20px;
  position: relative;
  border-radius: 2px;
}

.single-footer-widget .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-48%);
          transform: translateY(-48%);
}

.single-footer-widget .social-link li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.single-footer-widget h3 {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600;
}

.single-footer-widget .footer-links-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  font-family: var(--fontFamily3);
}

.single-footer-widget .footer-links-list li {
  margin-bottom: 12px;
  color: #d0c6c6;
  font-size: 16px;
}

.single-footer-widget .footer-links-list li a {
  color: #d0c6c6;
  display: inline-block;
}

.single-footer-widget .footer-links-list li a:hover {
  color: var(--mainColor);
}

.single-footer-widget .footer-links-list li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  font-family: var(--fontFamily3);
}

.single-footer-widget .footer-contact-info li {
  margin-bottom: 14px;
  color: #d0c6c6;
  position: relative;
  padding-left: 24px;
  font-size: 16px;
}

.single-footer-widget .footer-contact-info li a {
  color: #d0c6c6;
  display: inline-block;
}

.single-footer-widget .footer-contact-info li a:hover {
  color: var(--mainColor);
}

.single-footer-widget .footer-contact-info li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-contact-info li i {
  position: absolute;
  left: 0;
  color: var(--mainColor);
  top: 1px;
  font-size: 18px;
}

.footer-bottom-area {
  margin-top: 70px;
  border-top: 1px solid #220f13;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer-bottom-area p {
  text-transform: uppercase;
  line-height: initial;
  text-transform: uppercase;
  font-size: var(--fontSize);
  font-family: var(--fontFamily1);
}

.footer-bottom-area p i {
  position: relative;
  top: 2.3px;
}

.footer-bottom-area p a {
  color: var(--mainColor);
  position: relative;
  font-weight: 600;
}

.footer-bottom-area p a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 4px;
  right: 0;
  height: 1px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 0;
}

.footer-bottom-area p a:hover::before {
  width: 100%;
}

.footer-bottom-area ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area ul li {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  text-transform: uppercase;
}

.footer-bottom-area ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area ul li:last-child::before {
  display: none;
}

.footer-bottom-area ul li::before {
  content: '';
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: var(--whiteColor);
}

.footer-bottom-area ul li a {
  display: block;
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  z-index: 4;
  width: 43px;
  text-align: center;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  font-size: 27px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.go-top i {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.go-top.active {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.go-top:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

/*================================================
Sidebar Modal CSS
=================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  background-color: var(--blackColor);
  border: none;
  border-radius: 0;
}

.modal.right .modal-content button.close {
  float: unset;
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--whiteColor);
  z-index: 2;
  opacity: 1;
  border: none;
  text-shadow: unset;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  font-size: 40px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: transparent;
}

.modal.right .modal-content button.close:hover {
  color: red;
}

.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.sidebarModal.modal .modal-body {
  text-align: center;
  padding: 80px 30px;
}

.sidebarModal.modal .modal-body .instagram-list {
  margin-top: 70px;
}

.sidebarModal.modal .modal-body .instagram-list .row {
  margin-left: -5px;
  margin-right: -5px;
}

.sidebarModal.modal .modal-body .instagram-list .row .col-lg-4 {
  padding-left: 5px;
  padding-right: 5px;
}

.sidebarModal.modal .modal-body .instagram-list .box {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 10px;
}

.sidebarModal.modal .modal-body .instagram-list .box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.sidebarModal.modal .modal-body .instagram-list .box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.sidebarModal.modal .modal-body .instagram-list .box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sidebarModal.modal .modal-body .instagram-list .box::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

.sidebarModal.modal .modal-body .instagram-list .box:hover::before {
  opacity: 0.5;
  visibility: visible;
}

.sidebarModal.modal .modal-body .instagram-list .box:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.sidebarModal.modal .modal-body .instagram-list .box:hover i {
  opacity: 1;
  visibility: visible;
}

.sidebarModal.modal .modal-body .sidebar-contact-info {
  margin-top: 80px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info h2 {
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 30px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info h2 span {
  display: block;
  font-size: var(--fontSize);
  margin-top: 8px;
  margin-bottom: 8px;
}

.sidebarModal.modal .modal-body .social-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
}

.sidebarModal.modal .modal-body .social-list li {
  display: inline-block;
  margin-right: 8px;
}

.sidebarModal.modal .modal-body .social-list li span {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  font-size: var(--fontSize);
  font-weight: 400;
}

.sidebarModal.modal .modal-body .social-list li a {
  position: relative;
  top: 1px;
  color: var(--whiteColor);
}

.sidebarModal.modal .modal-body .social-list li a:hover {
  color: var(--mainColor);
}

.sidebarModal.modal .modal-body .social-list li:last-child {
  margin-right: 0;
}

.buy-now-btn {
  left: 30px;
  bottom: 30px;
  z-index: 333;
  position: fixed;
  border-radius: 30px;
  color: var(--whiteColor);
  background-color: #81b441;
  padding: 10px 20px 10px 40px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--fontFamily3);
}

.buy-now-btn img {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  width: 17px;
  left: 15px;
  top: 50%;
}

.buy-now-btn:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  color: var(--whiteColor);
}

.dark-version-btn {
  top: 1px;
  margin-left: 30px;
  position: relative;
}

.dark-version-btn .switch {
  width: 44px;
  height: 44px;
  position: relative;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}

.dark-version-btn .switch input {
  width: 0;
  height: 0;
  opacity: 0;
}

.dark-version-btn .slider {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: #3d4152;
}

.dark-version-btn .slider:before {
  left: 0;
  top: 50%;
  right: 0;
  content: "\ee4e";
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
  font-family: 'boxicons';
}

.dark-version-btn .slider.round {
  border-radius: 50%;
}

.dark-version-btn .slider.round:before {
  border-radius: 50%;
}

.dark-version-btn input:checked + .slider:before {
  content: "\eebe";
}
button.img-btn {
  padding: 0 !important;
  margin: 38px 0;
}
.iframe{
width: 500px;
}
.radius{
  border-radius: 15px;
}
.upper-games {
  overflow: hidden !important;
  padding-top: 35px !important;
}

button.img-btn img {
  animation: animName 4s linear infinite;
  width: 550px;
  /* height: 550px; */
  padding: 2rem;
  margin: -52px 0 -40px 0;
}
.upper-games .btn-box.d-flex {
  margin-top: -35px;
}
@keyframes animName {
  0%{
     transform: rotate(0deg);
    }
    25%{
      transform: rotate(-45deg);
     }
    50%{
      transform: rotate(0deg);
     }
     
     75%{
      transform: rotate(180deg);
     }
 100%{
     transform: rotate(360deg);
    }
 }

@media only screen and (max-width: 768px) {
button.img-btn img {
  /* width: 350px;
  height: 350px; */
}
.upper-games {
  overflow: hidden !important;
  padding-top: 35px !important;
}

}

@media only screen and (max-width: 375px) {

  button.img-btn img {
    /* width: 250px;
    height: 250px; */
  }
  button.img-btn {
    margin-top: 50px;
    margin-bottom: 30px;
}
  }
  @media screen and (max-width: 767px){
    .single-live-stream-item .video-btn {
      font-size: 12px;
      height: 25px;
      line-height: 25px;
      right: 30px;
      top: 10px;
      width: 25px;
  }
  .streams-list {
    max-height: 300px;
    overflow-y: auto;
}
  }
 @media only screen and (max-width: 992px){
    img.sudoku-img {
      max-width: 222px;
    }
}

  @media only screen and (max-width: 768px){
    .score-change .single-live-stream-item .content {
      bottom: 0!important;
      left: 175px!important;
      padding: 30px 10px 25px 16px;
      position: absolute;
      z-index: 2;
  }
  .single-live-stream-box img {
    width: 624px !important;
    height: 145px !important;
  }
  .single-live-stream-box .content h3 {
      font-size: 12px !important;
  } 
  .single-live-stream-box .content {
      padding: 5px !important;
  }
  .single-live-stream-box .content h3 i {
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      margin-left: 10px;
  }
  h3.rating1 {
    width: 40px;
    margin-top: -24px;
    height: 20px;
    background: red;
    border-radius: 0;
    text-align: center;
    display: flex !important;
    padding: 0;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    justify-content: center;
    align-items: center;
}
img.g-image2 {
    position: absolute;
    bottom: 51px;
    z-index: 1;
    left: 27px;
    max-width: 136px;
    width: 100%;
  }
img.g-image3 {
    max-width: 105px;
    position: absolute;
    bottom: 2px;
    left: 107px;
  }
  img.sudoku-img {
      max-width: 114px;
  }
  img.g-image1 {
      max-width: 108px;
  }

}
@media only screen and (max-width: 600px){
  .g-inner-box2 {
      position: absolute;
      top: -12px;
      right: 26px;
  }
  .g-inner-box3 h2 {
      font-size: 32px;
      color: #FFF;
      text-transform: uppercase;
      margin-top: 180px;
      margin-left: 35%;
  }
  img.spin-text-img {
      max-width: 163px;
      position: absolute;
      bottom: 49px;
      left: 78px;
      transform: rotate(1deg);
      z-index: 999;
  }
}

/*
@File: Zelda Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Navbar Area CSS
** - Search Overlay CSS
** - Main Banner Area CSS
** - Matches Area CSS
** - Match Details Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Live Stream Area CSS
** - Awards Area CSS
** - Tournament Details Area CSS
** - Featured Games Area CSS
** - Team Area CSS
** - Team Details Area CSS
** - Player Details Area CSS
** - Services Area CSS
** - Popular Leagues Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Games Area CSS
** - Upcoming Tournaments Area CSS
** - Partner Area CSS
** - Top Team Area CSS
** - Our Experts Area CSS
** - Stream Schedule Area CSS
** - Subscribe Area CSS
** - History Area CSS
** - Page Title Area CSS
** - Gallery Area CSS
** - 404 Error Area CSS
** - Coming Soon Area CSS
** - Social Area CSS
** - FAQ Area CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Contact Area CSS
** - Profile Authentication Area CSS
** - Footer Area CSS
** - Go Top CSS
** - Sidebar Modal CSS
*/
/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: VerminVibes;
  src: url(../fonts/Vermin-Vibes-V.ttf.eot);
  src: url(../fonts/Vermin-Vibes-V.ttf.svg);
  src: url(../fonts/Vermin-Vibes-V.ttf.woff);
}

:root {
  --fontFamily: 'Teko', sans-serif;
  --fontFamily2: 'VerminVibes', sans-serif;
  --fontFamily3: 'Poppins', sans-serif;
  --mainColor: #fc0820;
  --whiteColor: #ffffff;
  --blackColor: #0c0305;
  --fontSize: 18px;
  --transition: .5s;
}

body {
  background-color: var(--blackColor);
  color: var(--whiteColor);
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Teko', sans-serif !important;
  font-size: var(--fontSize);
}

a {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  outline: 0 !important;
  text-decoration: none;
}

a:hover {
  color: var(--mainColor);
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1210px;
}

:focus {
  outline: 0 !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--whiteColor);
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

p {
  margin-bottom: 15px;
  line-height: 1.8;
  font-family: var(--fontFamily3);
  font-size: 15px;
}

p:last-child {
  margin-bottom: 0;
}

.bg-1b060a {
  background-color: #1b060a;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

/*animate-cursos*/
.zelda-cursor {
  width: 7px;
  height: 7px;
  background-color: var(--mainColor);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: none;
  transition: none;
  Pointer-events: none;
  z-index: 9999999999;
  display: none;
}

.zelda-cursor2 {
  display: none;
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid var(--mainColor);
  position: absolute;
  top: 9px;
  
  left: 9px;
  -webkit-transition: none;
  transition: none;
  Pointer-events: none;
  z-index: 9999999999;
}
/********    Corrections Start        ********/
/* .correction1 {
  background: #0c0305;
  padding-top: 35px;
  margin-top: -35px;
} */
body{
  background-color: #0c0305 !important;
}
a {
  text-decoration: none !important;
}
a.video-btn i {
  color: white;
}
/* new sec */
section.just-gamers-img {
  background-image: url(../images/just_gamer7.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 35px;
}
img.spin-text-img {
  max-width: 341px;
  position: absolute;
  bottom: 61px;
  left: 206px;
  transform: rotate(2deg);
  z-index: 999;
}
.g-inner-box3 h2 {
  font-size: 85px;
  color: #FFF;
  text-transform: uppercase;
  margin-top: 172px;
}
.just-game-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  position: relative;
}
img.g-image2 {
  position: absolute;
  bottom: 73px;
  z-index: 1;
  left: 103px;
  max-width: 280px;
  width: 100%;
}
img.g-image1 {
  position: relative;
  z-index: 999;
}
.g-inner-box3 {
  display: flex;
  position: relative;
  width: 100%;
}
img.sudoku-img {
  max-width: 332px;
}
img.g-image3 {
  max-width: 215px;
  position: absolute;
  bottom: -27px;
  left: 269px;
  animation: rotateme 8s linear infinite;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
section.top-c-slider h2 {
  color: #fff;
}
section.top-c-slider {
  padding: 20px 0;
  background: #1f0d26;
}
.item.category-inner {
  width: 100% !important;
  padding: 0 7px;
}
.category-box {
  border-radius: 5px;
  overflow: hidden;
}
.top-c-slider-inner .category .owl-nav button {
  position: absolute;
  top: -53px;
  right: 6px;
  width: 35px;
  height: 35px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 47px !important;
  background: white !important;
}
.top-c-slider-inner .category .owl-nav button:hover{
  color: #000000 !important;
}
.category-box img {
  height: auto;
  object-fit: cover;
  object-position: center;
}
.top-c-slider-inner .category .owl-nav button.owl-prev {
  right: 50px;
}
/* end */
.showPointsOnScreen {
  position: absolute;
  right: 75px;
  background: red;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  border: 2px solid white;
  padding: 5px;
  color: white;
}
.social-area .content{
  color:white;
}
.single-live-stream-box img {
  width: 624px !important;
  height: 350px !important;
  object-fit: cover;
}

.correction1 .streams-list {
  height: 100%;
  overflow: hidden !important;
}
.single-live-stream-item img {
  height: 300px !important;
  /* width: 500px !important; */
  object-fit: fill !important;
}
.score-change .single-live-stream-item .content {
  position: absolute;
  right: 0 !important;
  bottom: 0 !important;
  padding: 30px 30px 25px;
  z-index: 2;
}
.nav-link:focus {
  background-color: red !important;
  color: white !important;
 }
 
.single-live-stream-item:hover .video-btn {
  background-color: white !important;
  color: red !important;
} 
.single-live-stream-item:hover .flaticon-play-button {
  /* background-color: red !important; */
  color: red !important;
} 
h3.rating1 {
  width: 75px;
  height: 75px;
  background: red;
  border-radius: 50%;
  text-align: center;
  padding: 19px;
}

.gif-row{
  width: 100vw;
}
.upper-games {
  background-position: center top;
}
.ball {
  /* animation: bounce 2s infinite;
  animation-timing-function: ease; */
  color: #ffffff !important;
  margin-top: 10px;
}
.main-banner-content h6 {
  color: #ffffff;
}

/* cus titel */
.text-animation {
  min-height: 27vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-wrapper {
  position: relative;
}

.text-wrapper h2 {
  z-index: 4;
  color: #fff;
  top: 42px;
  letter-spacing: 6px;
  font-size: 158px;
  white-space: nowrap;
  position: absolute;
  transform: translate(-50%, -62%);
}

.text-wrapper h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #fff;
}

.text-wrapper h2:nth-child(2) {
  color: #fff;
  animation: wave 4s ease-in-out infinite;
}

/* ============= */

.loader {
  perspective: 1000px;
  -webkit-perspective: 1000px;
}
.loader > span {
  display: inline-block;
  color: #fff;
  transform-style: preserve-3d;
  transform-origin: 0 100%;
  animation: anim 3s;
}
.loader span {
  font-size: 48px;
  letter-spacing: 20px;
}
.loader > span:nth-child(even) {
  color: #fc0820;
}
.loader > span:nth-child(2) {
  animation-delay: 0.2s;
}
.loader > span:nth-child(3) {
  animation-delay: 0.4s;
}
.loader > span:nth-child(4) {
  animation-delay: 0.6s;
}
.loader > span:nth-child(5) {
  animation-delay: 0.8s;
}
.loader > span:nth-child(6) {
  animation-delay: 1s;
}
.loader > span:nth-child(7) {
  animation-delay: 1.2s;
}
.loader > span:nth-child(8) {
  animation-delay: 1.4s;
}
.loader > span:nth-child(9) {
  animation-delay: 1.6s;
}
.loader > span:nth-child(10) {
  animation-delay: 1.8s;
}
.loader > span:nth-child(11) {
  animation-delay: 2s;
}
.loader > span:nth-child(12) {
  animation-delay: 2.2s;
}
.loader > span:nth-child(13) {
  animation-delay: 2.4s;
}
.loader > span:nth-child(14) {
  animation-delay: 2.6s;
}
@keyframes anim {
  35% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

/* ============ */

@keyframes wave {
  /* start point */
  0%,
  100% {
    clip-path: polygon(
      0% 46%,
      17% 45%,
      34% 50%,
      56% 61%,
      69% 62%,
      86% 60%,
      100% 51%,
      100% 100%,
      0% 100%
    );
  }
  /* Mid point */
  50% {
    clip-path: polygon(
      0% 59%,
      16% 64%,
      33% 65%,
      52% 61%,
      70% 52%,
      85% 47%,
      100% 48%,
      100% 100%,
      0% 100%
    );
  }
}

/*  */

@media only screen and (max-width: 500px) {
  .p {
      font-size: 30px;
      letter-spacing: 0;
  }
  .loader span {
      font-size: 28px;
      letter-spacing: 6px;
  }
  .text-wrapper h2 {
      top: 32px;
  }
    .text-animation {
      min-height: 14vh;
  }
}

@media only screen and (max-width: 700px) {
  
  .loader span {
      font-size: 28px;
      letter-spacing: 6px;
  }
  .text-wrapper h2 {
      top: 32px;
  }
    .text-animation {
      min-height: 14vh;
  }
}


.cursor {
  position: absolute;
  top: 50%;
  height: 100%;
  width: 100%;
  transform: translate(0, -50%);
  border-right: 2px solid red;
  animation: cursor-animation 3s ease-in-out forwards alternate infinite;
}

@keyframes cursor-animation {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

/* end */
.bounce:nth-child(1) {
  animation-delay:-1000ms;
}
.bounce:nth-child(2) {
  animation-delay: -1200ms;
}
.bounce:nth-child(3) {
  animation-delay: -4800ms;
}
.owl-item .item {
  width: 200px;
}
.align-correct img {
  min-width: 175px;
  height: 300px;
  object-fit: cover;
}
@keyframes bounce {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(-30px);
  }
}

.game-show {
  display: flex !important;
  justify-content: center;
}

.btn-center {
  text-align: center;
}

.main-banner.jarallax {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}





@media only screen and (max-width: 768px) {
  .correction1 {
    padding-top: 0px !important;
  }
 
  .single-live-stream-item img {
    height: 115px !important;
    width: 175px !important;
    object-fit: cover;
}
.score-change .single-live-stream-item .content {
  position: absolute;
  left: 175px !important;
  bottom: 0 !important;
  padding: 30px 10px 25px 30px;
  z-index: 2;
}
h3.rating1 {
  width: 45px;
  height: 45px;
  background: red;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
}
.row.align-correct img {
  min-width: 130px;
  height: 220px;
  object-fit: cover;
  border-radius: 10px !important;
}
.owl-item .item {
  width: 125px;
}

  
}
@media only screen and (max-width: 575px) {
  .upper-games {
  background-position: 100% 25%;
}
.root{
  overflow: hidden;
}

}
/* cus css narendar */
.edit-box {
  width: 100%;
  max-width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 108px;
  left: 55%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}
.page-title-content.img-box {
  position: relative;
}
.icon-box-inner {
  max-width: 112px;
}
.cus-icon-box-inner {
  display: flex;
  gap: 15px;
  justify-content: center;
}
.icon-box-inner img {
  max-width: 100% !important;
  width: 100% !important;
  margin-bottom: 0 !important;
}
.icon-box-inner {
  max-width: 100px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
div#myDIV {
  opacity: 0;
  transition: all 0.5s;
  background: #fff;
  max-width: 430px;
  margin: auto;
  position: relative;
  top: -100px;
  padding: 60px 10px;
  overflow: hidden;
  border-radius: 5px;
}
.edit-name span {
  border: 2px solid #000;
  border-radius: 5px;
  margin: auto;
  margin-bottom: 18px;
  display: block;
  width: 180px;
  font-size: 20px;
}
.edit-btn button {
  font-size: 22px;
  width: 145px;
  border-radius: 5px;
} 
.cus-icon-box {
    opacity: 1 !important;
    transition: all 0.5s;
}


.edit-name-box {
  margin-top: 45px;
  margin-bottom: 30px;
}
.edit-name-box input {
  display: block;
  width: 100%;
  margin: 14px 0;
  padding: 6px 14px;
  color: #000;
  font-size: 23px;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  border: 2px solid #000;
}
.edit-name-box label {
  display: block;
  font-size: 28px;
  color: #000;
}
.edit-name-box input::placeholder {
  color: #000;
}
.edit-name-box button.save-btn {
  padding: 5px 30px;
  font-size: 23px;
  border-radius: 4px;
  margin-top: 12px;
  border: 0;
  background: #28070e;
  color: #fff;
  width: 100%;
  letter-spacing: 3px;
}
.m-h {
  background: #28070e;
  color: #fff;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
.m-f button.btn.btn-secondary {
  background: #ff0000;
  border: none;
  padding: 3px 22px;
  font-size: 20px;
  letter-spacing: 2px;
  border-radius: 4px;
}
.m-h button.btn-close {
  filter: invert(1);
  opacity: 1;
}
.edit-profile .owl-dots {
  display: none;
}
.row.align-correct {
  justify-content: center;
}


.c-box-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  height: 450px;
  background: #28070e;
  overflow-y: scroll;
  border-radius: 10px;
}
.c-box-inner::-webkit-scrollbar-thumb {
  background: #28070e;
  border-radius: 10px;
  border: 3px solid #000;
}
.c-box-inner::-webkit-scrollbar {
  width: 0px;
  background: #000;
  border-radius: 10px;
}
.c-box-inner-img {
  width: 48%;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  height: 200px;
  position: relative;
}
button.change-img-btn {
  padding: 0;
}
button.c-play-btn {
  position: absolute;
  z-index: 99;
  border: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: red;
  color: #fff;
  font-size: 23px;
}
.c-box-inner-img .single-live-stream-item img {
  height: 200px !important;
  object-fit: cover; 
}
.c-box-inner-img .single-live-stream-item{  
  border-radius: 10px; 
}
.c-box-inner-img img {
  width: 100%;
  max-width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.modal-body {
  background: #d2d2d2;
}
.vt-navigation-new .c-main-box .modal-body {
  /* background: #fff; */
  background: #fff !important;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.c-main-box .modal-header {
  background: #28070e;
  color: #fff;
}
.c-main-box button.btn-close {
  filter: invert(1);
  opacity: 1;
}
.c-main-box {
  border-radius: 5px;
  overflow: hidden;
}
.c-main-box .modal-body {
  background: #28070e;
}

/* leaderbord */
.content.leader-bord {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px 22px;
}
.time.leader-bord-img img {
  max-width: 85px;
  height: 85px;
  object-fit: cover;
  object-position: center;
}
section.player-details-area{
  max-width: 100%;
  padding-top: 45px;
  padding-bottom: 45px;
  background: #260206;
}
.player-details-area-inner{
  max-width: 100%;
  margin: auto;
  height: 395px;
  overflow-y: scroll;
  background: #260206;
  border-radius: 10px;
  padding-bottom: 45px;
}
section.social-area {
  padding-top: 35px !important;
}
.player-details-area-inner::-webkit-scrollbar {
  width: 0px;
}
.leader-border-score h3 {
  margin: 0;
  font-size: 22px;
  letter-spacing: 2px;
}
.leader-box-inner-name span {
  font-size: 24px;
  letter-spacing: 3px;
}
.leader-bord-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 30px;
  font-size: 26px;
  letter-spacing: 2px;
  background: #fff;
  color: #260206;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px){
  .edit-name-box {
      margin-top: 20px;
      margin-bottom: 10px;
  }
  .edit-name-box label {
      font-size: 20px;
  }
  .edit-name-box input {
      display: block;
      width: 100%;
      margin: 7px 0;
      padding: 2px 6px;
      color: #000;
      font-size: 23px;
      border-radius: 4px;
      box-shadow: none;
      outline: none;
      border: 2px solid #000;
  }
  .edit-box {
      left: 67%;
  }
  .c-box-inner-img .single-live-stream-item img {
    height: 145px !important;
    object-fit: cover;
  }



.edit-img-box-inner {
    width: 75%;
    margin: auto;
}



.page-title-content .player-image {
  max-width: 120px;
  width: 120px;
  /* border-radius: 50%; */
}

.c-box-inner {
    /* align-items: center;
    display: flex;
    justify-content: center;
    height : max-content; */
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.c-box-inner-img {
  height: 145px;
  width: 49%;
}

.vt-show-game .single-live-stream-item {
  height: 100px !important;
  width: 320px !important;
}


}
/* //MEdia Query End */

/* .streams-list {
  height: max-content;
  overflow-y: auto;
} */
.streams-list {
  height: -webkit-max-content;
  max-height: 600px;
  overflow-x: hidden;
}
.Loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .vt-show-game .single-live-stream-item img {
  height: 100% !important;
  width: 100%;
} */
.vt-show-game .single-live-stream-item {
  height: 225px;
  width: 440px;
}
.vt-show-game .single-live-stream-item {
  height: 200px;
  width: 440px;
  /* height: 100px;
  width: 340px; */
}
.streams-list.score-change::-webkit-scrollbar-thumb {
  background-color: #000;
}
.streams-list.score-change::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}
.navbar span {
  position: absolute;
  /* bottom: -20%; */
  bottom: -30px;
  left: 0%;
  width: 40px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* display: block; */
  background: #fff;
  text-align: center;
  border-radius: 20px !important;
  color: red;
}

.zelda-responsive-menu span {
  width: 40px;
  display: block;
  background: #fff;
  color: red;
  text-align: center;
  border-radius: 20px !important;
}

.vt-navigation-new .modal-content {
  margin-top: 94px;
  border: none;
}
.vt-navigation-new .modal-body ul.navbar-nav li {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #000;
}
.vt-navigation-new .modal-header {
  border: none;
}

.modal-body ul.navbar-nav li a.nav-link {
  margin:0 10px;
  
}

.vt-profile-modal .owl-carousel .owl-item img {
  display: block;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
} 

.edit-img-box-inner:hover img {
  opacity: 0.5;
}
/********    Corrections End        ********/


/*default&optional-btn*/
.default-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 15px 40px 9px 40px;
  font-size: 20px;
  font-weight: 400;
}

.default-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.default-btn:hover {
  color: var(--mainColor);
}

.default-btn:hover::before {
  width: 100%;
  height: 100%;
}

.optional-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: transparent;
  color: var(--whiteColor);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 15px 45px 9px 45px;
  font-size: 20px;
  font-weight: 400;
}

.optional-btn::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.optional-btn::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border: 1px solid var(--whiteColor);
}

.optional-btn:hover {
  color: var(--mainColor);
}

.optional-btn:hover::before {
  width: 100%;
  height: 100%;
}

/*section-title*/
.section-title {
  max-width: 720px;
  text-align: center;
  margin-bottom: 45px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -5px;
}

.section-title .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.section-title h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

/*form-control*/
.form-control {
  height: 50px;
  color: var(--whiteColor);
  background-color: #22152c !important;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 2px 0 0 15px;
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-family: var(--fontFamily3);
  font-size: 14px;
  font-weight: 500;
}

.form-control::-webkit-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control:-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control::-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control::placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}

.form-control:focus:-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::-ms-input-placeholder {
  color: transparent;
}

.form-control:focus::placeholder {
  color: transparent;
}

.form-control::-webkit-search-cancel-button {
  display: none;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
}

/*================================================
Navbar Area CSS
=================================================*/
.zelda-responsive-nav {
  display: none;
}

.zelda-nav .container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

.zelda-nav .navbar {
  position: relative;
  /* padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0; */
  padding : 0;
}

.zelda-nav .navbar .navbar-brand {
  font-size: inherit;
  line-height: 1;
  max-width: 180px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.zelda-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.zelda-nav .navbar .navbar-nav {
  margin-left: auto;
}

.zelda-nav .navbar .navbar-nav .nav-item {
  position: relative;
  margin-left: 6px;
  margin-right: 6px;
}

.zelda-nav .navbar .navbar-nav .nav-item a {
  color: var(--whiteColor);
  text-transform: uppercase;
  display: block;
  background-color: transparent;
  letter-spacing: 1.5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 600;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 9px;
  padding-bottom: 3.5px;
}

.zelda-nav .navbar .navbar-nav .nav-item a i {
  font-size: 11px;
  position: relative;
  top: -2px;
  margin-left: 1px;
}

.zelda-nav .navbar .navbar-nav .nav-item a:hover, .zelda-nav .navbar .navbar-nav .nav-item a.active {
  color: var(--whiteColor);
  background-color: #00030e;
}

.zelda-nav .navbar .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item:first-child {
  margin-left: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item:hover a, .zelda-nav .navbar .navbar-nav .nav-item.active a {
  color: var(--whiteColor);
  background-color: #00030e;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  border: none;
  top: 38px;
  left: 0;
  z-index: 99;
  opacity: 0;
  width: 210px;
  display: block;
  border-radius: 0;
  position: absolute;
  visibility: hidden;
  background-color: #00030e;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-top: none;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  padding-top: 24px;
  padding-bottom: 22px;
  padding-left: 20px;
  padding-right: 20px;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  margin: 13px 0 0 0;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  padding: 0;
  position: relative;
  color: var(--whiteColor);
  overflow: hidden;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 600;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  margin: 0;
  position: absolute;
  top: 50%;
  font-size: 18px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a::before {
  font-family: Flaticon;
  content: "\f107";
  position: absolute;
  top: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  left: 0;
  color: var(--whiteColor);
  font-size: 13px;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
  padding-left: 18px;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover::before, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active::before {
  opacity: 1;
  visibility: visible;
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover i {
  -webkit-transform: translateY(-50%) rotate(225deg);
          transform: translateY(-50%) rotate(225deg);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  top: 0;
  left: -210px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 210px;
  opacity: 0;
  visibility: hidden;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--whiteColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li:first-child {
  margin-top: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}

.zelda-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.zelda-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.zelda-nav .navbar .others-option {
  margin-left: 17px;
}

.zelda-nav .navbar .others-option .option-item {
  margin-left: 37px;
}

.zelda-nav .navbar .others-option .option-item:first-child {
  margin-left: 0;
}

.zelda-nav .navbar .others-option .side-menu-btn i {
  cursor: pointer;
  font-size: 35px;
  position: relative;
  top: 3px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}


.zelda-nav .navbar .others-option .side-menu-btn i:hover {
  color: var(--mainColor);
}

.zelda-nav .navbar .others-option .search-box i {
  cursor: pointer;
  font-size: 22px;
  position: relative;
  top: 3px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.zelda-nav .navbar .others-option .search-box i:hover {
  color: var(--mainColor);
}

.zelda-nav .navbar .others-option .cart-btn a {
  display: inline-block;
  position: relative;
  font-size: 25px;
  top: 1px;
  color: var(--whiteColor);
  line-height: 1;
  padding-right: 10px;
}

.zelda-nav .navbar .others-option .cart-btn a span {
  position: absolute;
  right: 0;
  top: -3px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background-color: var(--mainColor);
  font-size: 14px;
  font-weight: 600;
}

.navbar-area {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-area.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  background-color: var(--blackColor) !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-area.navbar-style-two .zelda-nav .navbar .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}

.navbar-area.navbar-style-two .zelda-nav .navbar .others-option {
  margin-left: 0;
}

.others-option-for-responsive {
  display: none;
}

.others-option-for-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 9991;
  position: absolute;
  right: 52px;
  top: -40px;
}

.others-option-for-responsive .dot-menu .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
}

.others-option-for-responsive .dot-menu .inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--whiteColor);
}

.others-option-for-responsive .dot-menu:hover .inner .circle {
  background-color: var(--mainColor);
}

.others-option-for-responsive .container {
  position: relative;
}

.others-option-for-responsive .container .container {
  position: absolute;
  right: 0;
  top: 10px;
  max-width: 320px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}

.others-option-for-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.others-option-for-responsive .option-inner {
  padding: 10px 15px;
  -webkit-box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
          box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
  background-color: var(--blackColor);
}

.others-option-for-responsive .option-inner .others-option {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.others-option-for-responsive .option-inner .others-option .option-item {
  margin-left: 30px;
}

.others-option-for-responsive .option-inner .others-option .option-item:first-child {
  margin-left: 0;
}

.others-option-for-responsive .option-inner .others-option .side-menu-btn i {
  cursor: pointer;
  font-size: 35px;
  position: relative;
  top: 3px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive .option-inner .others-option .side-menu-btn i:hover {
  color: var(--mainColor);
}

.others-option-for-responsive .option-inner .others-option .search-box i {
  cursor: pointer;
  font-size: 22px;
  position: relative;
  top: 9px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.others-option-for-responsive .option-inner .others-option .search-box i:hover {
  color: var(--mainColor);
}

.others-option-for-responsive .option-inner .others-option .cart-btn {
  position: relative;
  top: 5px;
}

.others-option-for-responsive .option-inner .others-option .cart-btn a {
  display: inline-block;
  position: relative;
  font-size: 25px;
  top: 1px;
  color: var(--whiteColor);
  line-height: 1;
  padding-right: 10px;
}

.others-option-for-responsive .option-inner .others-option .cart-btn a span {
  position: absolute;
  right: 0;
  top: -3px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background-color: var(--mainColor);
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 1199px) {
  .navbar-area {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-area.is-sticky {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-area.is-sticky .zelda-responsive-nav .zelda-responsive-menu.mean-container .mean-nav {
    margin-top: 63px;
  }
  .navbar-area.is-sticky .others-option-for-responsive .container .container {
    top: 10px;
  }
  .navbar-area.navbar-style-two {
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .zelda-responsive-nav {
    display: block;
  }
  .zelda-responsive-nav .zelda-responsive-menu {
    position: relative;
  }
  .zelda-responsive-nav .zelda-responsive-menu.mean-container .navbar-nav {
    overflow-y: scroll;
    height: 300px;
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
  }
  .zelda-responsive-nav .zelda-responsive-menu .others-option {
    display: none !important;
  }
  .zelda-responsive-nav .logo {
    position: relative;
    width: 50%;
    z-index: 999;
  }
  .zelda-responsive-nav .logo img {
    max-width: 140px;
  }
  .zelda-responsive-nav .dark-version-btn {
    top: 5px;
    right: 90px;
    margin-left: 0;
    position: absolute;
  }
  .zelda-responsive-nav .dark-version-btn .switch {
    width: 40px;
    height: 40px;
  }
  .zelda-nav {
    display: none;
  }
  .others-option-for-responsive {
    display: block;
  }
}

/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.search-overlay .search-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.search-overlay .search-overlay-layer:nth-child(1) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.search-overlay .search-overlay-layer:nth-child(2) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.3s ease-in-out 0.3s;
  transition: all 0.3s ease-in-out 0.3s;
}

.search-overlay .search-overlay-layer:nth-child(3) {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.9s ease-in-out 0.6s;
  transition: all 0.9s ease-in-out 0.6s;
}

.search-overlay .search-overlay-close {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 50px;
  z-index: 2;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  -webkit-transition: all 0.9s ease-in-out 1.5s;
  transition: all 0.9s ease-in-out 1.5s;
  opacity: 0;
  visibility: hidden;
}

.search-overlay .search-overlay-close .search-overlay-close-line {
  width: 100%;
  height: 3px;
  float: left;
  margin-bottom: 5px;
  background-color: var(--whiteColor);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.search-overlay .search-overlay-close .search-overlay-close-line:nth-child(2) {
  margin-top: -7px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.search-overlay .search-overlay-close:hover .search-overlay-close-line {
  background: var(--mainColor);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.search-overlay .search-overlay-form {
  -webkit-transition: all 0.9s ease-in-out 1.4s;
  transition: all 0.9s ease-in-out 1.4s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  max-width: 500px;
  width: 500px;
}

.search-overlay .search-overlay-form form {
  position: relative;
}

.search-overlay .search-overlay-form form .input-search {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 30px;
  color: var(--blackColor);
  padding: 4px 0 0 25px;
}

.search-overlay .search-overlay-form form .input-search::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: .5px;
  color: var(--blackColor);
}

.search-overlay .search-overlay-form form .input-search:focus::-webkit-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus:-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::-ms-input-placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form .input-search:focus::placeholder {
  color: transparent;
}

.search-overlay .search-overlay-form form button {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  color: var(--whiteColor);
  height: 50px;
  border-radius: 50%;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: none;
  font-size: 20px;
  line-height: 45px;
}

.search-overlay .search-overlay-form form button:hover {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

.search-overlay.search-overlay-active.search-overlay {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-layer {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-close {
  opacity: 1;
  visibility: visible;
}

.search-overlay.search-overlay-active.search-overlay .search-overlay-form {
  opacity: 1;
  visibility: visible;
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  padding-bottom: 0px !important;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  background-image: url(../images/main-banner-bg1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-banner::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(../images/main-banner-shape.png);
  background-position: bottom;
  background-repeat: no-repeat;
}

.main-banner-content {
  text-align: center;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}

.main-banner-content .sub-title {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 13px;
  letter-spacing: 23px;
  font-size: 20px;
  font-weight: 400;
}

.main-banner-content .logo {
  margin-bottom: 30px;
}

.main-banner-content h6 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
}

.main-banner-content .btn-box {
  margin-top: 50px;
}

.main-banner-content .btn-box .default-btn {
  margin-right: 15px;
}

.main-banner-content .btn-box .optional-btn {
  margin-left: 15px;
}

.main-banner-content .btn-box .optional-btn::after {
  margin: 1px 0;
}

.main-banner-image {
  text-align: center;
}

.banner-video-slides {
  max-width: 790px;
  margin: 90px auto 50px auto;
}

.banner-video-slides .owl-item {
  padding: 12px;
}

.banner-video-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.banner-video-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 45px;
  margin: 0;
  padding: 0;
  background: transparent;
  position: absolute;
  left: -120px;
  bottom: 110px;
  border-radius: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.banner-video-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -120px;
  -webkit-transform: unset;
          transform: unset;
}

.banner-video-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.banner-video-slides.owl-theme .owl-dots {
  margin-top: 20px;
}

.banner-video-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.banner-video-slides.owl-theme .owl-dots .owl-dot:hover span, .banner-video-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.banner-video-box {
  position: relative;
  z-index: 1;
  text-align: center;
}

.banner-video-box::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  margin: -12px;
  background-image: url(../images/main-banner-video-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-video-box .content {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  text-align: center;
  z-index: 2;
}

.banner-video-box .content .video-btn {
  display: inline-block;
  width: 82px;
  line-height: 83px;
  height: 82px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  color: var(--mainColor);
  font-size: 35px;
}

.banner-video-box .content .video-btn:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.banner-video-box .content .title {
  display: block;
  text-transform: uppercase;
  margin-top: 25px;
  font-family: var(--fontFamily2);
  font-size: 30px;
}

.single-banner-item {
  position: relative;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 145px;
  padding-bottom: 145px;
}

.single-banner-item .container-fluid {
  padding-left: 140px;
  padding-right: 140px;
}

.banner-bg1 {
  background-image: url(../images/main-banner-bg2.jpg);
}

.banner-bg2 {
  background-image: url(../images/main-banner-bg4.jpg);
}

.banner-bg3 {
  background-image: url(../images/main-banner-bg3.jpg);
}

.home-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.home-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 40px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: 30px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.home-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.home-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.home-slides.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin-top: 0;
}

.home-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.home-slides.owl-theme .owl-dots .owl-dot:hover span, .home-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.home-slides .main-banner-content {
  max-width: 100%;
  text-align: left;
  padding-right: 50px;
  margin-left: 0;
  margin-right: 0;
}

.home-slides .main-banner-image img {
  width: auto !important;
  display: inline-block !important;
}

.banner-area {
  padding-top: 260px;
  padding-bottom: 150px;
}

.banner-content {
  text-align: center;
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}

.banner-content .sub-title {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 30px;
  letter-spacing: 10px;
  font-size: 20px;
  font-weight: 400;
}

.banner-content .logo {
  margin-bottom: 35px;
}

.banner-content h6 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: 400;
}

.banner-wrapper-area {
  padding-top: 280px;
  padding-bottom: 150px;
  background-image: url(../images/main-banner-bg3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-wrapper-content {
  max-width: 790px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.banner-wrapper-content .logo {
  margin-bottom: 40px;
}

.banner-wrapper-content h1 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 60px;
  font-weight: 700;
}

.banner-wrapper-content .sub-title {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-top: 20px;
  letter-spacing: 10px;
  font-size: 20px;
  font-weight: 400;
}

.hero-banner-area {
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
  padding-top: 160px;
  background-image: url(../images/main-banner-bg5.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-banner-area::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #1b060a;
  opacity: .90;
}

.hero-banner-area .row {
  margin: 0;
}

.hero-banner-area .row .col-lg-6 {
  padding: 0;
}

.hero-banner-image {
  border-radius: 50%;
  margin-right: -80px;
  width: 650px;
  height: 650px;
}

.hero-banner-image img {
  border-radius: 50%;
}

.hero-banner-content {
  width: 650px;
  height: 650px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  margin-left: -80px;
}

.hero-banner-content .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  padding: 120px;
}

.hero-banner-content::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 50%;
  background-color: var(--mainColor);
  opacity: .85;
}

.hero-banner-content h1 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 50px;
  font-weight: 700;
}

.hero-banner-content .btn-box {
  margin-top: 30px;
}

.hero-banner-content .btn-box .default-btn {
  background-color: var(--blackColor);
}

.hero-banner-content .btn-box .video-btn {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: 20px;
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 600;
}

.hero-banner-content .btn-box .video-btn i {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  color: var(--mainColor);
  line-height: 50px;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-right: 5px;
  margin-top: 1px;
}

.hero-banner-content .btn-box .video-btn:hover i {
  background-color: var(--blackColor);
  color: var(--whiteColor);
}

/*================================================
Matches Area CSS
=================================================*/
.matches-area {
  position: relative;
  z-index: 1;
}

.matches-area.bg-image {
  background-image: url(../images/matches-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-matches-box {
  margin-bottom: 45px;
}

.single-matches-box .matches-team {
  position: relative;
  padding-left: 100px;
  padding-top: 45px;
}

.single-matches-box .matches-team img {
  position: absolute;
  left: 0;
  max-width: 180px;
  top: 0;
}

.single-matches-box .matches-team .content {
  text-align: right;
}

.single-matches-box .matches-team .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  background-image: url(../images/matches-bg1.png);
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 32px;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 55px;
}

.single-matches-box .matches-team .content .watch-list {
  list-style-type: none;
  padding-left: 0;
  padding-right: 54px;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-matches-box .matches-team .content .watch-list li {
  display: inline-block;
  margin-right: 8px;
}

.single-matches-box .matches-team .content .watch-list li span {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  font-size: 20px;
  font-weight: 400;
}

.single-matches-box .matches-team .content .watch-list li a {
  position: relative;
  top: 1px;
  color: #bcb6b8;
}

.single-matches-box .matches-team .content .watch-list li a:hover {
  color: var(--whiteColor);
}

.single-matches-box .matches-team .content .watch-list li:last-child {
  margin-right: 0;
}

.single-matches-box .matches-team.right-image {
  padding-left: 0;
  padding-right: 100px;
}

.single-matches-box .matches-team.right-image img {
  left: auto;
  right: 0;
}

.single-matches-box .matches-team.right-image .content {
  text-align: left;
}

.single-matches-box .matches-team.right-image .content h3 {
  padding-left: 55px;
  padding-right: 0;
  background-image: url(../images/matches-bg2.png);
  background-position: left center;
}

.single-matches-box .matches-team.right-image .content ul {
  padding-left: 54px;
  padding-right: 0;
}

.single-matches-box .matches-team.right-image .content ul li {
  margin-left: 8px;
  margin-right: 0;
}

.single-matches-box .matches-result {
  padding-top: 30px;
  text-align: center;
}

.single-matches-box .matches-result ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.single-matches-box .matches-result ul li {
  display: inline-block;
  position: relative;
  line-height: 1;
  font-size: 60px;
  font-weight: 700;
  margin-left: 28px;
  margin-right: 28px;
}

.single-matches-box .matches-result ul li::before {
  content: ':';
  position: absolute;
  left: -33px;
  top: 11px;
  font-size: 28px;
}

.single-matches-box .matches-result ul li:last-child {
  margin-right: 0;
}

.single-matches-box .matches-result ul li:first-child {
  margin-left: 0;
}

.single-matches-box .matches-result ul li:first-child::before {
  display: none;
}

.single-matches-box .matches-result .date-time {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  font-size: 20px;
  font-weight: 400;
}

.single-matches-box:last-child {
  margin-bottom: 0;
}

.matches-tabs .nav-tabs {
  background-color: var(--blackColor);
  border: none;
}

.matches-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
}

.matches-tabs .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 12px 45px 6px 30px;
  text-transform: uppercase;
  color: var(--whiteColor);
  letter-spacing: 2px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  font-size: 22px;
  font-weight: 600;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.matches-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor) !important;
}

.matches-tabs .nav-tabs .nav-item .nav-link:hover {
  color: var(--mainColor);
}

.matches-tabs .tab-content {
  background-color: rgba(12, 3, 5, 0.6);
  padding: 30px;
}

.shape1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.shape2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.shape3 {
  position: absolute;
  left: 32%;
  bottom: 0;
  z-index: -1;
}

.shape4 {
  position: absolute;
  right: 4%;
  bottom: 4%;
  z-index: -1;
}

/*================================================
Match Details Area CSS
=================================================*/
.match-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: -12px;
  list-style-type: none;
}

.match-details-desc .article-content .entry-meta ul li {
  display: inline-block;
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--mainColor);
  position: relative;
}

.match-details-desc .article-content .entry-meta ul li::before {
  width: 1px;
  height: 12px;
  background-color: var(--mainColor);
  content: '';
  position: absolute;
  right: -12px;
  top: 5px;
}

.match-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}

.match-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}

.match-details-desc .article-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  margin-top: 28px;
  font-size: 32px;
  font-weight: 700;
}

.match-details-desc .article-content p {
  color: #c2bcc6;
}

.match-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.match-details-desc .article-content .wp-block-gallery.columns-3 li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333%;
          flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}

.match-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}

.match-details-desc .article-content .blockquote, .match-details-desc .article-content blockquote {
  margin-top: 30px;
  margin-bottom: 30px;
}

.match-details-desc .article-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-top: 1px solid #22152c;
  padding-top: 30px;
  margin-top: 30px;
}

.match-details-desc .article-footer .article-tags {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.match-details-desc .article-footer .article-tags span {
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 3px;
  font-size: 20px;
}

.match-details-desc .article-footer .article-tags a {
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
}

.match-details-desc .article-footer .article-share {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.match-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}

.match-details-desc .article-footer .article-share .social li {
  display: inline-block;
}

.match-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 3px;
  text-transform: uppercase;
  position: relative;
  top: -1px;
}

.match-details-desc .article-footer .article-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 37px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}

.match-details-desc .article-footer .article-share .social li a:hover, .match-details-desc .article-footer .article-share .social li a:focus {
  color: var(--mainColor);
  background-color: var(--whiteColor);
  border-color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.facebook:hover, .match-details-desc .article-footer .article-share .social li a.facebook:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.match-details-desc .article-footer .article-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.twitter:hover, .match-details-desc .article-footer .article-share .social li a.twitter:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.match-details-desc .article-footer .article-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.linkedin:hover, .match-details-desc .article-footer .article-share .social li a.linkedin:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.match-details-desc .article-footer .article-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: var(--whiteColor);
}

.match-details-desc .article-footer .article-share .social li a.instagram:hover, .match-details-desc .article-footer .article-share .social li a.instagram:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.single-blog-post-item {
  margin-bottom: 30px;
}

.single-blog-post-item .post-image {
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.single-blog-post-item .post-image.bg-1 {
  background-image: url(../images/blog-img1.jpg);
}

.single-blog-post-item .post-image.bg-2 {
  background-image: url(../images/blog-img2.jpg);
}

.single-blog-post-item .post-image.bg-3 {
  background-image: url(../images/blog-img3.jpg);
}

.single-blog-post-item .post-image img {
  display: none;
}

.single-blog-post-item .post-image a.link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.single-blog-post-item .post-content {
  padding: 60px 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/post-bg.jpg);
}

.single-blog-post-item .post-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.single-blog-post-item .post-content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 30px;
  font-weight: 600;
}

.single-blog-post-item .post-content .default-btn {
  margin-top: 20px;
}

.blog-slides.owl-theme .owl-nav {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-top: 0;
}

.blog-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 35px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: 30px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.blog-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
}

.blog-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.blog-slides.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.blog-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.blog-slides.owl-theme .owl-dots .owl-dot:hover span, .blog-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.blog-slides.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.single-blog-post {
  margin-bottom: 30px;
  text-align: center;
}

.single-blog-post .post-content {
  margin-top: 20px;
}

.single-blog-post .post-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.single-blog-post .post-content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 34px;
  font-weight: 600;
}

.single-blog-post .post-content .default-btn {
  margin-top: 25px;
}

.blog-slides-two.owl-theme .owl-nav {
  margin-top: 0;
}

.blog-slides-two.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: -65px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.blog-slides-two.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.blog-slides-two.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.blog-slides-two.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.blog-slides-two.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.blog-slides-two.owl-theme .owl-dots .owl-dot:hover span, .blog-slides-two.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.single-blog-post-box {
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  background-color: #1f1625;
}

.single-blog-post-box .post-image {
  border-radius: 5px 5px 0 0;
}

.single-blog-post-box .post-image a {
  display: block;
  border-radius: 5px 5px 0 0;
}

.single-blog-post-box .post-image a img {
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.single-blog-post-box .post-content {
  padding: 28px;
}

.single-blog-post-box .post-content .post-meta {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 15px;
}

.single-blog-post-box .post-content .post-meta li {
  display: inline-block;
  margin-right: 20px;
  position: relative;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.single-blog-post-box .post-content .post-meta li::before {
  width: 1px;
  height: 12px;
  position: absolute;
  right: -12px;
  top: 4px;
  content: '';
  background-color: var(--mainColor);
}

.single-blog-post-box .post-content .post-meta li:last-child {
  margin-right: 0;
}

.single-blog-post-box .post-content .post-meta li:last-child::before {
  display: none;
}

.single-blog-post-box .post-content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 1px;
  font-size: 26px;
  font-weight: 700;
}

.single-blog-post-box .post-content h3 a {
  display: inline-block;
}

.single-blog-post-box .post-content .read-more-btn {
  text-transform: uppercase;
  margin-top: 15px;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
}

.single-blog-post-box .post-content .read-more-btn i {
  margin-left: 5px;
  position: relative;
  top: 1px;
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc .article-image img {
  width: 100%;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav {
  margin-top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--blackColor);
  font-size: 18px;
  margin: 0;
  padding: 0;
  background: var(--whiteColor);
  width: 45px;
  height: 45px;
  line-height: 40px;
  position: absolute;
  left: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding-left: 5px !important;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--whiteColor);
  border-width: 1.5px;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 20px;
  padding-left: 3px !important;
}

.blog-details-desc .article-image-slides.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-image-slides.owl-theme:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.blog-details-desc .article-video iframe {
  width: 100%;
  height: 400px;
  border: none !important;
}

.blog-details-desc .article-content {
  margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
  margin-right: 20px;
  position: relative;
  display: inline-block;
  border-right: 1px solid #22152c;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
  padding-right: 20px;
  padding-left: 45px;
}

.blog-details-desc .article-content .entry-meta ul li i {
  font-size: 32px;
  color: #cfcfcf;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.blog-details-desc .article-content .entry-meta ul li span {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 15px;
}

.blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  font-size: var(--fontSize);
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.blog-details-desc .article-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
  margin-top: 28px;
  font-size: 26px;
  font-weight: 700;
}

.blog-details-desc .article-content p {
  color: #c2bcc6;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333%;
          flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}

.blog-details-desc .article-content .features-list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 30px;
}

.blog-details-desc .article-content .features-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 26px;
  color: #c2bcc6;
  font-family: var(--fontFamily3);
  font-size: 15px;
}

.blog-details-desc .article-content .features-list li i {
  color: var(--mainColor);
  display: inline-block;
  font-size: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}

.blog-details-desc .article-content .features-list li:last-child {
  margin-bottom: 0;
}

.blog-details-desc .article-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-top: 1px solid #22152c;
  padding-top: 30px;
  margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  margin-right: 3px;
  position: relative;
  top: 3px;
  font-size: 20px;
}

.blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 600;
}

.blog-details-desc .article-footer .article-share {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}

.blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
}

.blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 3px;
  text-transform: uppercase;
  position: relative;
  top: -1px;
}

.blog-details-desc .article-footer .article-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 37px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}

.blog-details-desc .article-footer .article-share .social li a:hover, .blog-details-desc .article-footer .article-share .social li a:focus {
  color: var(--mainColor);
  background-color: var(--whiteColor);
  border-color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.facebook:hover, .blog-details-desc .article-footer .article-share .social li a.facebook:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-footer .article-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.twitter:hover, .blog-details-desc .article-footer .article-share .social li a.twitter:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-footer .article-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.linkedin:hover, .blog-details-desc .article-footer .article-share .social li a.linkedin:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-footer .article-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: var(--whiteColor);
}

.blog-details-desc .article-footer .article-share .social li a.instagram:hover, .blog-details-desc .article-footer .article-share .social li a.instagram:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.blog-details-desc .article-author {
  margin-top: 30px;
  border-radius: 5px;
  background: #22152c;
}

.blog-details-desc .article-author .author-profile-header {
  height: 115px;
  border-radius: 5px 5px 0 0;
  background-color: var(--mainColor);
  background-image: url(../images/bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.blog-details-desc .article-author .author-profile {
  padding: 0 25px 25px;
}

.blog-details-desc .article-author .author-profile .author-profile-title {
  position: relative;
  z-index: 1;
  margin-top: -45px;
}

.blog-details-desc .article-author .author-profile .author-profile-title img {
  display: inline-block;
  border: 3px solid var(--whiteColor);
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
}

.blog-details-desc .article-author .author-profile .author-profile-title h4 {
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 600;
}

.blog-details-desc .article-author .author-profile .author-profile-title span {
  display: block;
  margin-bottom: 12px;
  letter-spacing: 1px;
  color: var(--whiteColor);
}

.blog-details-desc .article-author .author-profile .author-profile-title p {
  font-size: 15px;
}

blockquote, .blockquote {
  overflow: hidden;
  background-color: #22152c;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
}

blockquote p, .blockquote p {
  color: var(--whiteColor) !important;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 700;
  font-size: 20px !important;
}

blockquote cite, .blockquote cite {
  display: none;
}

blockquote::after, .blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--mainColor);
  margin-top: 20px;
  margin-bottom: 20px;
}

.zelda-post-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #22152c;
  border-bottom: 1px solid #22152c;
}

.prev-link-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
}

.prev-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.prev-link-wrapper a:hover .image-prev::after {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .image-prev .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.prev-link-wrapper a:hover .prev-link-info-wrapper {
  color: var(--mainColor);
}

.prev-link-wrapper .image-prev {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-right: 20px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .image-prev img {
  border-radius: 5px;
}

.prev-link-wrapper .image-prev::after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .image-prev .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  line-height: 1;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
}

.prev-link-wrapper .prev-link-info-wrapper {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.prev-link-wrapper .prev-title {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}

.prev-link-wrapper .meta-wrapper {
  display: block;
  color: var(--whiteColor);
  text-transform: capitalize;
  margin-top: 6px;
  font-size: 16px;
}

.next-link-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
  padding-left: 15px;
}

.next-link-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.next-link-wrapper a:hover .image-next::after {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .image-next .post-nav-title {
  opacity: 1;
  visibility: visible;
}

.next-link-wrapper a:hover .next-link-info-wrapper {
  color: var(--mainColor);
}

.next-link-wrapper .image-next {
  display: inline-block;
  min-width: 100px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  vertical-align: top;
  margin-left: 20px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .image-next img {
  border-radius: 5px;
}

.next-link-wrapper .image-next::after {
  display: block;
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  opacity: 0;
  background-color: var(--mainColor);
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .image-next .post-nav-title {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  color: var(--whiteColor);
  opacity: 0;
  line-height: 1;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--fontSize);
}

.next-link-wrapper .next-link-info-wrapper {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.next-link-wrapper .next-title {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}

.next-link-wrapper .meta-wrapper {
  display: block;
  color: var(--whiteColor);
  text-transform: capitalize;
  margin-top: 6px;
  font-size: 16px;
}

.comments-area {
  margin-top: 30px;
}

.comments-area .comments-title {
  line-height: initial;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 700;
}

.comments-area ol, .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.comments-area .children {
  margin-left: 20px;
}

.comments-area .comment-body {
  border-bottom: 1px dashed #22152c;
  padding-left: 70px;
  color: var(--whiteColor);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.comments-area .comment-body .reply {
  margin-top: 15px;
}

.comments-area .comment-body .reply a {
  border: 1px dashed #ded9d9;
  color: var(--whiteColor);
  display: inline-block;
  padding: 7px 20px 3px;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
}

.comments-area .comment-body .reply a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}

.comments-area .comment-author {
  font-size: var(--fontSize);
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
}

.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
  top: 5px;
}

.comments-area .comment-author .fn {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.comments-area .comment-author .says {
  display: none;
}

.comments-area .comment-metadata {
  margin-bottom: .8em;
  color: var(--whiteColor);
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
}

.comments-area .comment-metadata a {
  display: inline-block;
  color: var(--whiteColor);
}

.comments-area .comment-metadata a:hover {
  color: var(--mainColor);
}

.comments-area .comment-content p {
  color: #c2bcc6;
  font-size: 15px;
}

.comments-area .comment-respond {
  margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
  line-height: initial;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}

.comments-area .comment-respond .comment-form {
  overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
  margin-bottom: 20px;
  margin-top: 10px;
}

.comments-area .comment-respond .comment-notes .required {
  color: red;
}

.comments-area .comment-respond .comment-form-comment {
  float: left;
  width: 100%;
}

.comments-area .comment-respond label {
  display: none;
}

.comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #22152c;
  border: none;
  padding: 1px 0 0 15px;
  height: 50px;
  outline: 0;
  border-radius: 3px;
  color: var(--whiteColor);
  font-weight: 600;
  font-size: 15px;
}

.comments-area .comment-respond input[type="date"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="time"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="week"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="month"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="text"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="email"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="url"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="password"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="search"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="tel"]::-webkit-input-placeholder, .comments-area .comment-respond input[type="number"]::-webkit-input-placeholder, .comments-area .comment-respond textarea::-webkit-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]:-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:-ms-input-placeholder, .comments-area .comment-respond textarea:-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]::-ms-input-placeholder, .comments-area .comment-respond input[type="time"]::-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]::-ms-input-placeholder, .comments-area .comment-respond input[type="week"]::-ms-input-placeholder, .comments-area .comment-respond input[type="month"]::-ms-input-placeholder, .comments-area .comment-respond input[type="text"]::-ms-input-placeholder, .comments-area .comment-respond input[type="email"]::-ms-input-placeholder, .comments-area .comment-respond input[type="url"]::-ms-input-placeholder, .comments-area .comment-respond input[type="password"]::-ms-input-placeholder, .comments-area .comment-respond input[type="search"]::-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]::-ms-input-placeholder, .comments-area .comment-respond input[type="number"]::-ms-input-placeholder, .comments-area .comment-respond textarea::-ms-input-placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]::placeholder, .comments-area .comment-respond input[type="time"]::placeholder, .comments-area .comment-respond input[type="datetime-local"]::placeholder, .comments-area .comment-respond input[type="week"]::placeholder, .comments-area .comment-respond input[type="month"]::placeholder, .comments-area .comment-respond input[type="text"]::placeholder, .comments-area .comment-respond input[type="email"]::placeholder, .comments-area .comment-respond input[type="url"]::placeholder, .comments-area .comment-respond input[type="password"]::placeholder, .comments-area .comment-respond input[type="search"]::placeholder, .comments-area .comment-respond input[type="tel"]::placeholder, .comments-area .comment-respond input[type="number"]::placeholder, .comments-area .comment-respond textarea::placeholder {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
}

.comments-area .comment-respond input[type="date"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="time"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="week"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="month"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="text"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="email"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="url"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="password"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="search"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus::-webkit-input-placeholder, .comments-area .comment-respond input[type="number"]:focus::-webkit-input-placeholder, .comments-area .comment-respond textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus:-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:focus:-ms-input-placeholder, .comments-area .comment-respond textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="time"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="week"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="month"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="text"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="email"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="url"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="password"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="search"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="tel"]:focus::-ms-input-placeholder, .comments-area .comment-respond input[type="number"]:focus::-ms-input-placeholder, .comments-area .comment-respond textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.comments-area .comment-respond input[type="date"]:focus::placeholder, .comments-area .comment-respond input[type="time"]:focus::placeholder, .comments-area .comment-respond input[type="datetime-local"]:focus::placeholder, .comments-area .comment-respond input[type="week"]:focus::placeholder, .comments-area .comment-respond input[type="month"]:focus::placeholder, .comments-area .comment-respond input[type="text"]:focus::placeholder, .comments-area .comment-respond input[type="email"]:focus::placeholder, .comments-area .comment-respond input[type="url"]:focus::placeholder, .comments-area .comment-respond input[type="password"]:focus::placeholder, .comments-area .comment-respond input[type="search"]:focus::placeholder, .comments-area .comment-respond input[type="tel"]:focus::placeholder, .comments-area .comment-respond input[type="number"]:focus::placeholder, .comments-area .comment-respond textarea:focus::placeholder {
  color: transparent;
}

.comments-area .comment-respond textarea {
  height: auto !important;
  padding-top: 15px;
}

.comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 7px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: var(--whiteColor);
  font-weight: normal;
}

.comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}

.comments-area .comment-respond .form-submit input {
  background: var(--mainColor);
  border: none;
  color: var(--whiteColor);
  padding: 12px 30px 10px;
  display: inline-block;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
}

.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
  color: var(--blackColor);
  background: var(--whiteColor);
}

/*================================================
Live Stream Area CSS
=================================================*/
.live-stream-area {
  position: relative;
  z-index: 1;
  background-image: url(../images/awards-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.live-stream-area.bg-color {
  background-color: var(--blackColor);
  background-image: unset !important;
}

.live-stream-tabs .nav-tabs {
  background-color: var(--blackColor);
  border: none;
}

.live-stream-tabs .nav-tabs .nav-item {
  margin-bottom: -1px;
}

.live-stream-tabs .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 12px 45px 7px 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--whiteColor);
  -webkit-clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
  font-size: 22px;
  font-weight: 600;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.live-stream-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: var(--mainColor) !important;
  color: var(--whiteColor) !important;
}

.live-stream-tabs .nav-tabs .nav-item .nav-link:hover {
  color: var(--mainColor);
}

.live-stream-tabs .tab-content {
  background-color: transparent;
  padding: 0;
}

.live-stream-tabs .tab-content .row {
  margin: 0;
}

.live-stream-tabs .tab-content .row .col-lg-7, .live-stream-tabs .tab-content .row .col-lg-5, .live-stream-tabs .tab-content .row .col-lg-4 {
  padding: 0;
}

/* .single-live-stream-box {
  overflow: hidden;
  position: relative;
} */

.single-live-stream-box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.single-live-stream-box .content {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: -20px;
  background-color: rgba(12, 3, 5, 0.75);
  padding: 28px 40px 20px;
  display: flex;
  justify-content:space-between
}

.single-live-stream-box .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: white;
}

.single-live-stream-box .content h3 i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #d2d0d1;
  color: var(--mainColor);
  font-size: 18px;
  display: inline-block;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  top: -5px;
  margin-left: 10px;
}

.single-live-stream-box:hover .content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.single-live-stream-box:hover .content h3 i {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.single-live-stream-item {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.single-live-stream-item::before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--blackColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--blackColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--blackColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  top: 0;
}

.single-live-stream-item .content {
  position: absolute;
  left: 160px;
  right: 0;
  bottom: 0;
  /* for testing */
  top: 0 !important;
  padding: 30px 30px 25px;
  z-index: 2;
}

.single-live-stream-item .content .meta {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.single-live-stream-item .content .meta li {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-right: 5px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
}

.single-live-stream-item .content .meta li:last-child {
  margin-right: 0;
}

.single-live-stream-item .content .meta li:last-child::before {
  display: none;
}

.single-live-stream-item .content .meta li::before {
  content: '';
  position: absolute;
  right: -15.5px;
  top: 7px;
  width: 6px;
  height: 6px;
  background-color: var(--whiteColor);
  border-radius: 50%;
  display: none;
}

.single-live-stream-item .content h3 {
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 30px;
  font-weight: 700;
}

.single-live-stream-item .video-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  -webkit-box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.61);
          box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.61);
  background-color: var(--mainColor);
  z-index: 2;
}

.single-live-stream-item .link-btn {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.single-live-stream-item::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--mainColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--mainColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--mainColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  top: 0;
}

.single-live-stream-item:hover .video-btn {
  background-color: white !important;
  color: red !important;
}

.single-live-stream-item:hover::after {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.single-live-stream-item:hover::before {
  opacity: 0;
  visibility: hidden;
}

.single-live-stream-item:hover .content .meta li {
  color: var(--whiteColor);
}

/*================================================
Awards Area CSS
=================================================*/
.awards-area {
  position: relative;
  z-index: 1;
  background-image: url(../images/awards-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.awards-image {
  position: relative;
  z-index: 1;
  text-align: center;
}

.awards-image .shape {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 90px;
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 140s;
          animation-duration: 140s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.awards-content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.awards-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.awards-content h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.awards-content .row {
  padding-left: 60px;
  padding-right: 60px;
}

.awards-content .image {
  margin-top: 50px;
}

.awards-content .shape {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -1;
  bottom: -35px;
  margin-left: auto;
  margin-right: auto;
}

.awards-content .shape img:nth-child(1) {
  position: relative;
  top: 25px;
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*================================================
Tournament Details Area CSS
=================================================*/
.tournament-details-area .matches-tabs .nav-tabs {
  background-color: #000000;
}

.tournament-details-area .matches-tabs .tab-content {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 40px;
}

.tournament-details-desc {
  margin-bottom: 60px;
}

.tournament-details-desc .sub-title {
  display: block;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}

.tournament-details-desc h3 {
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 2px;
  font-size: 40px;
  font-weight: 700;
}

.tournament-details-desc .tournament-meta {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.tournament-details-desc .tournament-meta li {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2c0c0;
  padding-left: 62px;
  margin-right: 40px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
}

.tournament-details-desc .tournament-meta li .icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--whiteColor);
  line-height: 1;
  font-weight: 500;
  font-size: 45px;
}

.tournament-details-desc .tournament-meta li span {
  display: block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 24px;
  font-weight: 700;
}

.tournament-details-desc .tournament-meta li:last-child {
  margin-right: 0;
}

/*================================================
Magazine Demo CSS
=================================================*/
.magazine-demo {
  position: relative;
  z-index: 1;
}

.magazine-demo.bg-image {
  background-image: url(../images/main-banner-bg2.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.magazine-items {
  margin-left: -15px;
  margin-right: -15px;
}

.single-magazine-box {
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
}

.single-magazine-box::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--mainColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--mainColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--mainColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  top: 0;
}

.single-magazine-box::before {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  content: '';
  background: var(--blackColor);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(253, 49, 71, 0)), to(var(--blackColor)));
  background: linear-gradient(rgba(253, 49, 71, 0), var(--blackColor));
  -webkit-transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  transition: 0.5s cubic-bezier(0.24, 0.06, 0, 0.91);
  top: 0;
}

.single-magazine-box .content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 30px 30px 25px;
}

.single-magazine-box .content .meta {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-bottom: 8px;
  padding-left: 0;
  list-style-type: none;
}

.single-magazine-box .content .meta li {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-right: 23px;
  position: relative;
  font-weight: 600;
  font-size: 17px;
}

.single-magazine-box .content .meta li:last-child {
  margin-right: 0;
}

.single-magazine-box .content .meta li:last-child::before {
  display: none;
}

.single-magazine-box .content .meta li::before {
  content: '';
  position: absolute;
  right: -15.5px;
  top: 7px;
  width: 6px;
  height: 6px;
  background-color: var(--whiteColor);
  border-radius: 50%;
}

.single-magazine-box .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 24px;
  font-weight: 700;
}

.single-magazine-box .content .read-more-btn {
  position: absolute;
  left: 30px;
  bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
  visibility: hidden;
}

.single-magazine-box .content .read-more-btn i {
  margin-right: 5px;
}

.single-magazine-box .link-btn {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.single-magazine-box:hover::after {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.single-magazine-box:hover::before {
  opacity: 0;
  visibility: hidden;
}

.single-magazine-box:hover .content .meta {
  -webkit-transform: translateY(-43px);
          transform: translateY(-43px);
}

.single-magazine-box:hover .content .meta li {
  color: var(--whiteColor);
}

.single-magazine-box:hover .content h3 {
  -webkit-transform: translateY(-43px);
          transform: translateY(-43px);
}

.single-magazine-box:hover .content .read-more-btn {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

.col-lg-6 .single-magazine-box .content {
  width: 60%;
}

/*================================================
Featured Games Area CSS
=================================================*/
.featured-games-inner {
  background-color: #1b060a;
}

.featured-games-content .content {
  padding: 110px 45px 45px;
}

.featured-games-content .content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
}

.featured-games-content .content h2 {
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 32px;
  font-weight: 600;
}

.featured-games-content .content .read-more-btn {
  display: inline-block;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 5px;
}

.featured-games-content .content .read-more-btn i {
  margin-left: 5px;
}

.featured-games-content .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  left: 45px;
  top: 35px;
}

.featured-games-content .owl-theme .owl-nav [class*=owl-] {
  color: #8d8385;
  font-size: 25px;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.featured-games-content .owl-theme .owl-nav [class*=owl-].owl-next {
  margin-left: 20px;
}

.featured-games-content .owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
}

.featured-games-content .owl-theme .owl-dots {
  position: absolute;
  right: 45px;
  top: 45px;
}

.featured-games-content .owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 0;
  margin-left: 10px;
  background: transparent;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  border: 1px solid #8d8385;
}

.featured-games-content .owl-theme .owl-dots .owl-dot span::before {
  position: absolute;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #8d8385;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  margin: 4px;
}

.featured-games-content .owl-theme .owl-dots .owl-dot:hover span, .featured-games-content .owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--whiteColor);
}

.featured-games-content .owl-theme .owl-dots .owl-dot:hover span::before, .featured-games-content .owl-theme .owl-dots .owl-dot.active span::before {
  background-color: var(--whiteColor);
}

/*================================================
Team Area CSS
=================================================*/
.team-area .section-title {
  max-width: 540px;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.team-area .row {
  margin-left: 0;
  margin-right: 0;
}

.team-area .row .col-lg-3, .team-area .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}

.single-team-member {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.single-team-member .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.single-team-member .content {
  text-align: left;
  position: absolute;
  left: 0;
  bottom: -20px;
  right: 0;
  background-color: #1a0c09;
  padding: 15px 25px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-team-member .content h3 {
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.single-team-member .content span {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-top: 6px;
  font-size: 16px;
  font-weight: 600;
}

.single-team-member:hover .content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

/*================================================
Team Details Area CSS
=================================================*/
.team-details-desc .sub-title {
  display: block;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}

.team-details-desc h3 {
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 2px;
  font-size: 40px;
  font-weight: 700;
}

.team-details-desc .team-meta {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.team-details-desc .team-meta li {
  display: inline-block;
  margin-right: 40px;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2c0c0;
  padding-left: 62px;
  margin-right: 40px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
}

.team-details-desc .team-meta li .icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--whiteColor);
  line-height: 1;
  font-weight: 500;
  font-size: 45px;
}

.team-details-desc .team-meta li span {
  display: block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 24px;
  font-weight: 700;
}

.team-details-desc .team-meta li .social {
  margin-left: -5px;
}

.team-details-desc .team-meta li .social a {
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
}

.team-details-desc .team-meta li .social a:last-child {
  margin-right: 0;
}

.team-details-desc .team-meta li:last-child {
  margin-right: 0;
}

.team-details-desc .player-list {
  margin-top: 60px;
}

.team-details-desc .player-list h3 {
  margin-bottom: 20px;
}

.team-details-desc .player-list .row {
  margin: 0;
}

.team-details-desc .player-list .row .col-lg-3 {
  padding: 0;
}

.team-details-desc .player-list .single-team-member .content h3 {
  margin-bottom: 0;
}

.team-details-desc .streams-list {
  margin-top: 60px;
}

.team-details-desc .streams-list h3 {
  margin-bottom: 20px;
}

.team-details-desc .streams-list .single-live-stream-item .content h3 {
  margin-bottom: 0;
}

.team-details-desc .partner-list {
  margin-top: 60px;
}

/*================================================
Player Details Area CSS
=================================================*/
.player-details-desc .sub-title {
  display: block;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 600;
}

.player-details-desc h3 {
  text-transform: uppercase;
  margin-bottom: 8px;
  letter-spacing: 2px;
  font-size: 40px;
  font-weight: 700;
}

.player-details-desc .player-meta {
  padding-left: 0;
  list-style-type: none;
  margin-top: 15px;
  margin-bottom: 0;
}

.player-details-desc .player-meta li {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2c0c0;
  padding-left: 62px;
  color: var(--mainColor);
  margin-right: 40px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
}

.player-details-desc .player-meta li .icon {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: var(--whiteColor);
  line-height: 1;
  font-weight: 500;
  font-size: 45px;
}

.player-details-desc .player-meta li span {
  display: block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 32px;
  font-weight: 700;
}

.player-details-desc .player-meta li .social {
  margin-top: 5px;
  margin-left: -8px;
}

.player-details-desc .player-meta li .social a {
  display: inline-block;
  margin-right: 10px;
  font-size: 25px;
}

.player-details-desc .player-meta li .social a:last-child {
  margin-right: 0;
}

.player-details-desc .player-meta li:last-child {
  margin-right: 0;
}

.player-details-desc .streams-list {
  margin-top: 60px;
}

.player-details-desc .streams-list h3 {
  margin-bottom: 20px;
}

.player-details-desc .streams-list .single-live-stream-item .content h3 {
  margin-bottom: 0;
}

.player-details-desc .partner-list {
  margin-top: 60px;
}

/*================================================
Services Area CSS
=================================================*/
.services-area .section-title {
  max-width: 100%;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 50px;
}

.single-services-box {
  margin-bottom: 30px;
}

.single-services-box .icon {
  font-size: 50px;
  line-height: 1;
  margin-bottom: 20px;
  color: #c2c0c0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-services-box h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 600;
}

.single-services-box:hover .icon {
  color: var(--whiteColor);
}

.row .col-lg-3:nth-child(2) .single-services-box, .row .col-lg-3:nth-child(3) .single-services-box {
  margin-bottom: 50px;
}

/*================================================
Popular Leagues Area CSS
=================================================*/
.single-popular-leagues-box {
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: #0b0a0b;
  border-radius: 5px;
  margin-bottom: 30px;
}

.single-popular-leagues-box .popular-leagues-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-radius: 5px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  border-radius: 5px 0 0 5px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image {
  height: 100%;
  width: 100%;
  border-radius: 5px 0 0 5px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image img {
  display: none;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg1 {
  background-image: url(../images/popular-leagues-img1.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg2 {
  background-image: url(../images/popular-leagues-img2.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg3 {
  background-image: url(../images/popular-leagues-img3.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-image .image.bg4 {
  background-image: url(../images/popular-leagues-img4.jpg);
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content {
  padding: 30px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 600;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content h3 a {
  display: inline-block;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li {
  display: inline-block;
  margin-right: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-left: 28px;
  font-size: 22px;
  font-weight: 600;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li i {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--mainColor);
  font-weight: normal;
  font-size: 18px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li i.flaticon-coin, .single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .info li i.flaticon-game-computer {
  top: 1px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .join-now-btn {
  display: inline-block;
  margin-top: 25px;
  font-weight: 600;
  position: relative;
  padding-bottom: 3px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .join-now-btn::before {
  width: 100%;
  height: 1px;
  background: var(--mainColor);
  content: '';
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  left: 0;
  bottom: 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-content .content .join-now-btn:hover::before {
  width: 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  border-radius: 0 5px 5px 0;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date {
  width: 100%;
  height: 100%;
  background-color: var(--mainColor);
  text-align: center;
  color: var(--whiteColor);
  position: relative;
  border-radius: 0 5px 5px 0;
  z-index: 1;
  letter-spacing: 1px;
  font-size: 30px;
  font-weight: 700;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date span {
  display: block;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date h3 {
  color: var(--whiteColor);
  margin-bottom: 6px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date p {
  color: var(--whiteColor);
  line-height: initial;
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 600;
}

.single-popular-leagues-box .popular-leagues-box .popular-leagues-date .date i {
  display: inline-block;
  font-size: 200px;
  color: var(--whiteColor);
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: .20;
}

/*================================================
Products Area CSS
=================================================*/
.products-area {
  position: relative;
  z-index: 2;
}

.single-products-box {
  margin-bottom: 30px;
  position: relative;
}

.single-products-box .sale {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: green;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-box .hot {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: var(--mainColor);
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-box .products-image {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.single-products-box .products-image img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-box .products-image .add-to-cart-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  position: absolute;
  overflow: hidden;
  padding: 12px 40px 7px 40px;
  bottom: 0;
  left: 0;
  display: block;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
  font-weight: 400;
}

.single-products-box .products-image .add-to-cart-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-box .products-image .add-to-cart-btn:hover {
  color: var(--mainColor);
}

.single-products-box .products-image .add-to-cart-btn:hover::before {
  width: 100%;
  height: 100%;
}

.single-products-box .products-content {
  margin-top: 30px;
}

.single-products-box .products-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 600;
}

.single-products-box .products-content .price {
  display: block;
  color: var(--mainColor);
  margin-top: 3px;
  font-size: 20px;
}

.single-products-box:hover .products-image img {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
}

.single-products-box:hover .products-image .add-to-cart-btn {
  bottom: 15px;
  opacity: 1;
  visibility: visible;
}

.single-products-item {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.single-products-item .products-image {
  position: relative;
}

.single-products-item .products-image .bg-image img {
  width: auto !important;
  display: inline-block !important;
}

.single-products-item .products-image .main-image {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  margin: 0 auto;
  width: auto !important;
  display: inline-block !important;
}

.single-products-item .products-image .add-to-cart-btn {
  text-transform: uppercase;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  overflow: hidden;
  padding: 12px 40px 7px 40px;
  bottom: 15px;
  left: 0;
  display: block;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
  font-weight: 400;
}

.single-products-item .products-image .add-to-cart-btn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: -1;
  background-color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-products-item .products-image .add-to-cart-btn:hover {
  color: var(--mainColor);
}

.single-products-item .products-image .add-to-cart-btn:hover::before {
  width: 100%;
  height: 100%;
}

.single-products-item .sale {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: green;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-item .hot {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
  text-transform: uppercase;
  font-weight: 600;
  background-color: var(--mainColor);
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 58px;
  border-radius: 50%;
}

.single-products-item .products-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 600;
}

.single-products-item .products-content .price {
  display: block;
  color: var(--mainColor);
  margin-top: 3px;
  font-size: 20px;
  font-weight: 600;
}

.single-products-item:hover .products-image .add-to-cart-btn {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

.products-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.products-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: -65px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.products-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.products-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.products-slides.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.products-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.products-slides.owl-theme .owl-dots .owl-dot:hover span, .products-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

.zelda-grid-sorting {
  margin-bottom: 40px;
}

.zelda-grid-sorting .result-count p {
  font-weight: 500;
}

.zelda-grid-sorting .result-count p .count {
  font-weight: 700;
  color: var(--whiteColor);
}

.zelda-grid-sorting .ordering {
  text-align: right;
}

.zelda-grid-sorting .ordering label {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 15px;
  text-transform: uppercase;
  font-size: var(--fontSize);
}

.zelda-grid-sorting .ordering select {
  background-color: transparent;
  cursor: pointer;
  color: var(--whiteColor);
  width: 145px;
  max-width: 145px;
  padding: 10px 15px 5px 10px;
  border: 1px solid var(--whiteColor);
  text-transform: uppercase;
  font-size: var(--fontSize);
}

.zelda-grid-sorting .ordering select option {
  color: var(--blackColor);
  outline: 0 !important;
  cursor: pointer;
  border: none;
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
  border-radius: 5px;
  margin-bottom: 30px;
}

.products-details-image a {
  display: block;
  border-radius: 5px;
}

.products-details-image a img {
  border-radius: 5px;
}

.products-details-desc {
  padding-left: 30px;
}

.products-details-desc h3 {
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 28px;
  font-weight: 700;
}

.products-details-desc .price {
  margin-bottom: 15px;
  color: var(--mainColor);
  letter-spacing: 1px;
  font-weight: 700;
}

.products-details-desc .price .old-price {
  text-decoration: line-through;
  color: #c2bcc6;
  font-weight: normal;
}

.products-details-desc .products-review {
  margin-bottom: 10px;
}

.products-details-desc .products-review .rating {
  display: inline-block;
  padding-right: 5px;
  font-size: 18px;
}

.products-details-desc .products-review .rating i {
  color: #ffba0a;
  display: inline-block;
  margin-right: -1px;
}

.products-details-desc .products-review .rating-count {
  display: inline-block;
  color: var(--whiteColor);
  border-bottom: 1px solid var(--blackColor);
  line-height: initial;
  position: relative;
  top: -3px;
  font-weight: 600;
}

.products-details-desc .products-review .rating-count:hover {
  color: var(--mainColor);
  border-color: var(--mainColor);
}

.products-details-desc p {
  color: #c2bcc6;
}

.products-details-desc .products-meta {
  margin-top: 20px;
}

.products-details-desc .products-meta span {
  display: block;
  color: var(--whiteColor);
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.products-details-desc .products-meta span span {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.products-details-desc .products-meta span span.sku {
  color: var(--mainColor);
}

.products-details-desc .products-meta span span.in-stock {
  color: var(--whiteColor);
}

.products-details-desc .products-meta span a {
  display: inline-block;
  color: var(--whiteColor);
  font-weight: 600;
  text-transform: capitalize;
}

.products-details-desc .products-meta span a:hover, .products-details-desc .products-meta span a:focus {
  color: var(--mainColor);
}

.products-details-desc .products-meta span:last-child {
  margin-bottom: 0;
}

.products-details-desc .products-add-to-cart {
  margin-top: 25px;
}

.products-details-desc .products-add-to-cart .input-counter {
  max-width: 200px;
  min-width: 200px;
  text-align: center;
  position: relative;
}

.products-details-desc .products-add-to-cart .input-counter span {
  position: absolute;
  top: 0;
  background-color: #22152c;
  cursor: pointer;
  color: var(--whiteColor);
  width: 40px;
  height: 100%;
  line-height: 56px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
}

.products-details-desc .products-add-to-cart .input-counter span.minus-btn {
  left: 0;
}

.products-details-desc .products-add-to-cart .input-counter span.plus-btn {
  right: 0;
}

.products-details-desc .products-add-to-cart .input-counter span:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.products-details-desc .products-add-to-cart .input-counter input {
  height: 45px;
  color: var(--whiteColor);
  outline: 0;
  display: block;
  border: none;
  background-color: #000000;
  text-align: center;
  width: 100%;
  padding-top: 4px;
  font-size: 17px;
  font-weight: 600;
}

.products-details-desc .products-add-to-cart .input-counter input::-webkit-input-placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .input-counter input:-ms-input-placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .input-counter input::-ms-input-placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .input-counter input::placeholder {
  color: var(--whiteColor);
}

.products-details-desc .products-add-to-cart .default-btn {
  max-width: 200px;
  min-width: 200px;
  text-align: center;
  margin-top: 15px;
}

.products-details-desc .products-share {
  margin-top: 30px;
}

.products-details-desc .products-share .social {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.products-details-desc .products-share .social li {
  display: inline-block;
}

.products-details-desc .products-share .social li span {
  display: inline-block;
  margin-right: 3px;
  font-weight: 600;
  position: relative;
  top: -2px;
  text-transform: uppercase;
}

.products-details-desc .products-share .social li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 37px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border: 1px solid var(--mainColor);
  text-align: center;
  font-size: 17px;
  margin-left: 2px;
}

.products-details-desc .products-share .social li a:hover, .products-details-desc .products-share .social li a:focus {
  color: var(--mainColor);
  background-color: transparent;
}

.products-details-desc .products-share .social li a.facebook {
  background-color: #3b5998;
  border-color: #3b5998;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.facebook:hover, .products-details-desc .products-share .social li a.facebook:focus {
  color: #3b5998;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.twitter {
  background-color: #1da1f2;
  border-color: #1da1f2;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.twitter:hover, .products-details-desc .products-share .social li a.twitter:focus {
  color: #1da1f2;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.linkedin {
  background-color: #007bb5;
  border-color: #007bb5;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.linkedin:hover, .products-details-desc .products-share .social li a.linkedin:focus {
  color: #007bb5;
  background-color: transparent;
}

.products-details-desc .products-share .social li a.instagram {
  background-color: #c13584;
  border-color: #c13584;
  color: var(--whiteColor);
}

.products-details-desc .products-share .social li a.instagram:hover, .products-details-desc .products-share .social li a.instagram:focus {
  color: #c13584;
  background-color: transparent;
}

.products-details-tabs {
  margin-top: 50px;
}

.products-details-tabs .nav {
  text-align: center;
  padding-left: 0;
  margin-bottom: 40px;
  list-style-type: none;
  display: block;
  border-bottom-color: #22152c;
}

.products-details-tabs .nav .nav-item {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}

.products-details-tabs .nav .nav-item .nav-link {
  color: var(--whiteColor);
  border: none;
  border-bottom: 1px solid #22152c;
  padding: 0;
  background-color: transparent;
  position: relative;
  text-transform: uppercase;
  padding-bottom: 8px;
  font-size: 22px;
  font-weight: 700;
}

.products-details-tabs .nav .nav-item .nav-link::before {
  content: '';
  position: absolute;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: -2px;
}

.products-details-tabs .nav .nav-item .nav-link:hover, .products-details-tabs .nav .nav-item .nav-link.active {
  color: var(--mainColor);
}

.products-details-tabs .nav .nav-item .nav-link:hover::before, .products-details-tabs .nav .nav-item .nav-link.active::before {
  width: 100%;
}

.products-details-tabs .tab-content .tab-pane {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.products-details-tabs .tab-content .tab-pane p {
  line-height: 1.8;
  color: #c2bcc6;
}

.products-details-tabs .tab-content .tab-pane ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 20px;
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane ul li {
  margin-bottom: 15px;
  position: relative;
  color: var(--whiteColor);
  padding-left: 15px;
  color: #c2bcc6;
  font-size: 15px;
  font-family: var(--fontFamily3);
}

.products-details-tabs .tab-content .tab-pane ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--whiteColor);
}

.products-details-tabs .tab-content .tab-pane ul li:last-child {
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews h3 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating {
  display: inline-block;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span {
  font-size: 17px;
  color: #cecfd2;
  margin-right: -2px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating span.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count {
  margin-top: 10px;
  margin-bottom: 20px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .rating-count span {
  display: block;
  font-size: 15px;
  color: var(--whiteColor);
  font-family: var(--fontFamily3);
}

.products-details-tabs .tab-content .tab-pane .products-reviews .row {
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side {
  float: left;
  width: 9%;
  margin-top: 10px;
  padding-left: 0;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .side div {
  text-transform: uppercase;
  font-size: var(--fontSize);
  font-weight: 600;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .middle {
  margin-top: 14px;
  float: left;
  width: 82%;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .right {
  text-align: right;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: var(--whiteColor);
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-5 {
  width: 100%;
  height: 18px;
  background-color: #4CAF50;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-4 {
  width: 75%;
  height: 18px;
  background-color: #2196F3;
  border-radius: 5px;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-3 {
  width: 50%;
  height: 18px;
  background-color: #00bcd4;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-2 {
  width: 25%;
  height: 18px;
  background-color: #ff9800;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-reviews .bar-1 {
  width: 0;
  height: 18px;
  background-color: #f44336;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments {
  margin-top: 40px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments h3 {
  border-bottom: 1px solid #22152c;
  padding-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review {
  border-bottom: 1px solid #22152c;
  padding: 20px 0 20px 110px;
  position: relative;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review img {
  position: absolute;
  left: 0;
  top: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .sub-comment {
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating {
  display: block;
  margin-bottom: 8px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars {
  display: inline-block;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i {
  color: #cecfd2;
  font-size: 18px;
  display: inline-block;
  margin-right: -3px;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating .review-stars i.checked {
  color: orange;
}

.products-details-tabs .tab-content .tab-pane .products-review-comments .user-review .review-rating span {
  color: var(--whiteColor);
  position: relative;
  top: -2px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 5px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper {
  margin-top: 30px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper h3 {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper .comment-notes span {
  color: red;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form {
  margin-top: 20px;
  text-align: center;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .form-group {
  margin-bottom: 25px;
  text-align: left;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating {
  text-align: left;
  overflow: hidden;
  max-width: 115px;
  margin-top: -5px;
  margin-bottom: 20px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label {
  float: right;
  position: relative;
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:not(:first-of-type) {
  padding-right: 5px;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating label:before {
  content: "\2605";
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 27px;
  color: #CCCCCC;
  line-height: 1;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input {
  display: none;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating input:checked ~ label:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .rating:not(:checked) > label:hover ~ label:before {
  color: #f6b500;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent {
  text-align: left;
  margin-bottom: 0;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) {
  display: none;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-weight: 500;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after, .products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--mainColor);
  position: absolute;
  top: 5.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:hover + label:before {
  border-color: var(--mainColor);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form .comment-form-cookies-consent [type="checkbox"]:checked + label:before {
  border-color: var(--mainColor);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form button {
  margin-top: 22px;
  border: none;
  display: block;
  width: 100%;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 5px;
  padding: 15px 60px 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: var(--fontSize);
}

.products-details-tabs .tab-content .tab-pane .review-form-wrapper form button:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

/*================================================
Games Area CSS
=================================================*/
.games-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.single-games-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-bottom: 30px;
}

.single-games-box .content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.single-games-box .content h3 {
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 32px;
  font-weight: 600;
}

.single-games-box .content .rating {
  color: #efc02f;
}

.single-games-box .content .btn-box {
  margin-top: 30px;
}

.single-games-box .content .btn-box .playstore-btn {
  margin-right: 10px;
  display: inline-block;
  text-align: left;
  background-color: var(--whiteColor);
  position: relative;
  color: var(--blackColor);
  border-radius: 60px;
  font-family: var(--fontFamily3);
  font-size: 20px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 65px;
  padding-right: 22px;
}

.single-games-box .content .btn-box .playstore-btn span {
  display: block;
  margin-bottom: -4px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.single-games-box .content .btn-box .playstore-btn img {
  position: absolute;
  left: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-games-box .content .btn-box .applestore-btn {
  margin-left: 10px;
  display: inline-block;
  text-align: left;
  background-color: var(--whiteColor);
  position: relative;
  color: var(--blackColor);
  border-radius: 60px;
  font-family: var(--fontFamily3);
  font-size: 20px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 70px;
  padding-right: 22px;
}

.single-games-box .content .btn-box .applestore-btn span {
  display: block;
  margin-bottom: -4px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.single-games-box .content .btn-box .applestore-btn img {
  position: absolute;
  left: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-games-box::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  top: 5%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), to(rgba(0, 0, 0, 0.8)));
  background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.8));
  z-index: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-games-box:hover::before {
  opacity: 0;
  visibility: hidden;
}

.owl-item .single-games-box .content .btn-box img {
  width: auto !important;
  display: inline-block !important;
}

.games-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.games-slides.owl-theme .owl-nav [class*=owl-] {
  color: #8d8385;
  font-size: 35px;
  margin: 0;
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0;
  background: transparent;
  border-radius: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.games-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 30px;
}

.games-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--whiteColor);
}

.games-slides.owl-theme .owl-dots {
  margin-top: 15px;
}

.games-slides.owl-theme .owl-dots .owl-dot span {
  width: 18px;
  height: 18px;
  margin: 0 5px;
  background: transparent;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  border: 1px solid #8d8385;
}

.games-slides.owl-theme .owl-dots .owl-dot span::before {
  position: absolute;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #8d8385;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
  margin: 4px;
}

.games-slides.owl-theme .owl-dots .owl-dot:hover span, .games-slides.owl-theme .owl-dots .owl-dot.active span {
  border-color: var(--whiteColor);
}

.games-slides.owl-theme .owl-dots .owl-dot:hover span::before, .games-slides.owl-theme .owl-dots .owl-dot.active span::before {
  background-color: var(--whiteColor);
}

/*================================================
Upcoming Tournaments Area CSS
=================================================*/
.single-tournaments-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: #0b0a0b;
}

.single-tournaments-box .tournaments-image {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.single-tournaments-box .tournaments-image .image {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-tournaments-box .tournaments-image .image img {
  display: none;
}

.single-tournaments-box .tournaments-image .image.bg1 {
  background-image: url(../images/upcoming-tournaments-img1.jpg);
}

.single-tournaments-box .tournaments-image .image.bg2 {
  background-image: url(../images/upcoming-tournaments-img2.jpg);
}

.single-tournaments-box .tournaments-image .image.bg3 {
  background-image: url(../images/upcoming-tournaments-img3.jpg);
}

.single-tournaments-box .tournaments-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.single-tournaments-box .tournaments-content .content {
  padding: 50px 40px;
}

.single-tournaments-box .tournaments-content .content .date {
  color: var(--mainColor);
  margin-top: -5px;
  font-size: 60px;
  font-weight: 700;
}

.single-tournaments-box .tournaments-content .content .date sup {
  text-transform: uppercase;
  color: var(--whiteColor);
  top: -23px;
  left: -5px;
  font-size: 20px;
  font-weight: 500;
}

.single-tournaments-box .tournaments-content .content .date sub {
  left: -40px;
  top: -1px;
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 500;
}

.single-tournaments-box .tournaments-content .content h3 {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: 600;
}

.single-tournaments-box .tournaments-content .content h3 a {
  display: inline-block;
}

.single-tournaments-box .tournaments-content .content .meta {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: -15px;
  margin-right: -15px;
}

.single-tournaments-box .tournaments-content .content .meta li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  font-size: 18px;
  letter-spacing: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.single-tournaments-box .tournaments-content .content .meta li span {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 700;
}

.single-tournaments-box .tournaments-content .content .info {
  padding-left: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}

.single-tournaments-box .tournaments-content .content .info li {
  display: inline-block;
  margin-right: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-left: 28px;
  font-size: 22px;
  font-weight: 600;
}

.single-tournaments-box .tournaments-content .content .info li i {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--mainColor);
  font-weight: normal;
  font-size: 18px;
}

.single-tournaments-box .tournaments-content .content .info li i.flaticon-coin, .single-tournaments-box .tournaments-content .content .info li i.flaticon-game-computer {
  top: 1px;
}

.single-tournaments-box .tournaments-content .content .default-btn {
  margin-top: 25px;
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  position: relative;
  z-index: 1;
}

.partner-area .section-title {
  margin-bottom: 50px;
}

.partner-area .section-title .sub-title {
  margin-bottom: 0;
  color: var(--whiteColor);
  font-size: 30px;
}

.partner-area.bg-image {
  background-image: url(../images/awards-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-partner-item {
  text-align: center;
  margin-bottom: 30px;
}

.single-partner-item img {
  width: auto !important;
  display: inline-block !important;
}

.partner-shape1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 35%;
  -webkit-transform: translateY(-35%);
          transform: translateY(-35%);
  z-index: -1;
}

/*================================================
Top Team Area CSS
=================================================*/
.single-top-team-box {
  margin-bottom: 30px;
  position: relative;
  text-align: center;
}

.single-top-team-box .bg-image img {
  display: inline-block !important;
  width: auto !important;
}

.single-top-team-box .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.single-top-team-box .content img {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 25px;
}

.single-top-team-box .content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
}

.single-top-team-box .content ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-top-team-box .content ul li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.single-top-team-box .content ul li a {
  color: #bcb6b8;
  font-size: 20px;
}

.single-top-team-box .content ul li a:hover {
  color: var(--whiteColor);
}

.single-top-team-item {
  margin-bottom: 30px;
  position: relative;
  background-color: #0b0a0b;
  text-align: center;
  padding: 40px;
  border-radius: 5px;
}

.single-top-team-item img {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 25px;
}

.single-top-team-item h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
}

.single-top-team-item ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
}

.single-top-team-item ul li {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.single-top-team-item ul li a {
  color: #bcb6b8;
  font-size: 20px;
}

.single-top-team-item ul li a:hover {
  color: var(--whiteColor);
}

.top-team-slides.owl-theme .owl-nav {
  margin-top: 0;
}

.top-team-slides.owl-theme .owl-nav [class*=owl-] {
  color: var(--whiteColor);
  font-size: 30px;
  margin: 0;
  padding: 0;
  background: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 0;
  position: absolute;
  left: -65px;
  line-height: 1;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.top-team-slides.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -65px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.top-team-slides.owl-theme .owl-nav [class*=owl-]:hover {
  color: var(--mainColor);
}

.top-team-slides.owl-theme .owl-dots {
  margin-top: 0;
  line-height: 1;
}

.top-team-slides.owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 4px;
  margin: 0 7px;
  background: #421c20;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 3px;
}

.top-team-slides.owl-theme .owl-dots .owl-dot:hover span, .top-team-slides.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
}

/*================================================
Our Experts Area CSS
=================================================*/
.our-experts-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.our-experts-area .container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}

.our-experts-area .container-fluid .row .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}

.our-experts-image {
  height: 100%;
  background-image: url(../images/experts-img.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.our-experts-image img {
  display: none;
}

.our-experts-content {
  padding: 160px 80px 150px;
  background-image: url(../images/experts-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.our-experts-content .content {
  max-width: 500px;
}

.our-experts-content .content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--whiteColor);
  font-size: 20px;
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.our-experts-content .content h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.our-experts-content .content .default-btn {
  margin-top: 25px;
  background-color: var(--blackColor);
}

/*================================================
Stream Schedule Area CSS
=================================================*/
.single-stream-schedule-box {
  border-radius: 60px;
  position: relative;
  border: 1px solid #1d1b1b;
  text-align: center;
  margin-bottom: 30px;
  padding-left: 100px;
  padding-right: 50px;
}

.single-stream-schedule-box .date {
  position: absolute;
  left: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 1.2;
  color: var(--mainColor);
  font-weight: 700;
  font-size: 25px;
}

.single-stream-schedule-box .content {
  border-left: 1px solid #1d1b1b;
  border-radius: 60px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.single-stream-schedule-box .content .time {
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}

.single-stream-schedule-box .content h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 700;
}

.single-stream-schedule-box.not-streaming .date {
  text-decoration: line-through;
  color: red;
}

.single-stream-schedule-box.not-streaming .content .time {
  color: red;
}

.single-stream-schedule-box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 60px;
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  position: relative;
  z-index: 2;
}

.subscribe-inner {
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
  background-image: url(../images/subscribe-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.subscribe-inner .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
}

.subscribe-inner h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.subscribe-inner .newsletter-form {
  position: relative;
  max-width: 700px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.subscribe-inner .newsletter-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  color: #929191;
  font-size: 22px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.subscribe-inner .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #1a0c09;
  padding: 5px 0 0 58px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
  border: none;
  font-size: 18px;
  font-weight: 400;
}

.subscribe-inner .newsletter-form .input-newsletter::-webkit-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter:-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter::-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter::placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.subscribe-inner .newsletter-form .input-newsletter:focus {
  padding-top: 3px;
}

.subscribe-inner .newsletter-form .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}

.subscribe-inner .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  padding-top: 13.5px;
}

.subscribe-inner .newsletter-form .validation-danger {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -35px;
}

.section-title .newsletter-form {
  position: relative;
  max-width: 700px;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.section-title .newsletter-form label {
  display: block;
  margin-bottom: 0;
  position: absolute;
  left: 20px;
  top: 50%;
  color: #929191;
  font-size: 22px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.section-title .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 50px;
  background-color: #0c0305;
  padding: 5px 0 0 58px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--whiteColor);
  border: none;
  font-size: 18px;
  font-weight: 400;
}

.section-title .newsletter-form .input-newsletter::-webkit-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter:-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter::-ms-input-placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter::placeholder {
  text-transform: uppercase;
  color: #999999;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-title .newsletter-form .input-newsletter:focus {
  padding-top: 3px;
}

.section-title .newsletter-form .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.section-title .newsletter-form .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.section-title .newsletter-form .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.section-title .newsletter-form .input-newsletter:focus::placeholder {
  color: transparent;
}

.section-title .newsletter-form .default-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  padding-top: 13.5px;
}

/*================================================
History Area CSS
=================================================*/
.history-area {
  position: relative;
  z-index: 1;
  padding-top: 280px;
  padding-bottom: 280px;
  background-image: url(../images/history-bg.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.history-area::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #1b060a;
  opacity: .50;
}

.history-content {
  text-align: center;
}

.history-content .video-btn {
  display: inline-block;
  width: 82px;
  line-height: 83px;
  height: 82px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  color: var(--mainColor);
  font-size: 35px;
}

.history-content .video-btn:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.history-content h2 {
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 30px;
  font-family: var(--fontFamily2);
  font-size: 32px;
}

/*================================================
Feedback Area CSS
=================================================*/
.single-feedback-item {
  margin-bottom: 30px;
  background-color: #1b060a;
  padding: 105px 40px 40px;
  position: relative;
}

.single-feedback-item p {
  color: #bbbbbb;
  margin-bottom: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-weight: 600;
  font-size: 16px;
}

.single-feedback-item span {
  display: block;
  margin-top: 22px;
  color: var(--mainColor);
  padding-left: 25px;
  position: relative;
  line-height: 1;
  font-size: 20px;
  font-weight: 600;
}

.single-feedback-item span::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 15px;
  height: 1px;
  background-color: var(--mainColor);
}

.single-feedback-item::before {
  content: "\f114";
  position: absolute;
  left: 40px;
  color: #bbbbbb;
  top: 40px;
  line-height: 1;
  font-family: Flaticon;
  font-size: 45px;
}

.single-feedback-item:hover p {
  color: var(--whiteColor);
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  text-align: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 210px;
  padding-bottom: 0px;
}

.page-title-bg1 {
  background-image: url(../images/main-banner-bg1.jpg);
}

.page-title-content img {
  margin-bottom: 35px;
}

.page-title-content .player-image {
  max-width: 120px;
  width: 120px;
  border-radius: 50%;
}

.page-title-content h1 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  position: relative;
  display: inline-block;
  /* -webkit-animation: glitch 1s linear infinite;
          animation: glitch 1s linear infinite; */
  overflow: hidden;
  font-size: 130px;
  font-weight: 700;
}
.page-title-content h1:before{
  display: none;
}

/* .page-title-content h1:before, .page-title-content h1:after {
  content: attr(title);
  position: absolute;
  left: 0;
} */

/* .page-title-content h1:before {
  -webkit-animation: glitchTop 1s linear infinite;
          animation: glitchTop 1s linear infinite;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
          clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

.page-title-content h1:after {
  -webkit-animation: glitchBotom 2.5s linear infinite;
          animation: glitchBotom 2.5s linear infinite;
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
          clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
} */

.page-title-content .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-top: -8px;
  font-size: 20px;
  font-weight: 600;
}

@-webkit-keyframes glitch {
  2%, 64% {
    -webkit-transform: translate(2px, 0) skew(0deg);
            transform: translate(2px, 0) skew(0deg);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0) skew(0deg);
            transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    -webkit-transform: translate(0, 0) skew(5deg);
            transform: translate(0, 0) skew(5deg);
  }
}

@keyframes glitch {
  2%, 64% {
    -webkit-transform: translate(2px, 0) skew(0deg);
            transform: translate(2px, 0) skew(0deg);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0) skew(0deg);
            transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    -webkit-transform: translate(0, 0) skew(5deg);
            transform: translate(0, 0) skew(5deg);
  }
}

@-webkit-keyframes glitchTop {
  2%, 64% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  62% {
    -webkit-transform: translate(13px, -1px) skew(-13deg);
            transform: translate(13px, -1px) skew(-13deg);
  }
}

@keyframes glitchTop {
  2%, 64% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  62% {
    -webkit-transform: translate(13px, -1px) skew(-13deg);
            transform: translate(13px, -1px) skew(-13deg);
  }
}

@-webkit-keyframes glitchBotom {
  2%, 64% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  62% {
    -webkit-transform: translate(-22px, 5px) skew(21deg);
            transform: translate(-22px, 5px) skew(21deg);
  }
}

@keyframes glitchBotom {
  2%, 64% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  4%, 60% {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  62% {
    -webkit-transform: translate(-22px, 5px) skew(21deg);
            transform: translate(-22px, 5px) skew(21deg);
  }
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.single-gallery-item a {
  display: block;
  border-radius: 5px;
}

.single-gallery-item a img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-gallery-item:hover a img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}

.error-content {
  text-align: center;
}

.error-content h3 {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  margin-top: 50px;
  font-size: 50px;
  font-weight: 700;
}

.error-content p {
  color: #c2c0c0;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.error-content .default-btn {
  margin-top: 30px;
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: #f6f7fb;
  background-image: url(../images/main-banner-bg1.jpg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.coming-soon-content {
  max-width: 700px;
  overflow: hidden;
  text-align: center;
  background-color: #22152c;
  padding: 45px 40px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.coming-soon-content .logo {
  display: inline-block;
  margin-bottom: 35px;
}

.coming-soon-content h2 {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0;
}

.coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-content #timer div {
  background-color: #000000;
  width: 100px;
  height: 105px;
  border-radius: 5px;
  font-size: 40px;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
}

.coming-soon-content #timer div span {
  display: block;
  margin-top: -5px;
  font-size: 17px;
  font-weight: 600;
}

.coming-soon-content form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}

.coming-soon-content form .form-group .input-newsletter {
  display: block;
  width: 100%;
  border: none;
  color: var(--whiteColor);
  background-color: #000000;
  height: 55px;
  padding-left: 20px;
  padding-top: 2px;
  padding-right: 0;
  padding-bottom: 0;
  border-radius: 5px;
  font-size: 16.5px;
  font-weight: 500;
}

.coming-soon-content form .form-group .input-newsletter::-webkit-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter:-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter::-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter::placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.coming-soon-content form .form-group .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.coming-soon-content form .form-group .input-newsletter:focus::placeholder {
  color: transparent;
}

.coming-soon-content form .validation-danger {
  margin-top: 15px;
  color: red;
}

.coming-soon-content form .validation-success {
  margin-top: 15px;
}

.coming-soon-content form p {
  margin-bottom: 0;
  margin-top: 30px;
}

/*================================================
Social Area CSS
=================================================*/
.single-social-box {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  text-align: center;
  text-transform: uppercase;
}

.single-social-box .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 400;
}

.single-social-box .content i {
  line-height: 1;
  font-size: 30px;
  position: relative;
  top: 6.5px;
  margin-right: 5px;
}

.single-social-box .shape img:nth-child(1) {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-social-box .shape img:nth-child(2) {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  z-index: -1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0;
  text-align: center;
  margin: 0 auto;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-social-box .link-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: block;
}

.single-social-box:hover .shape img:nth-child(1) {
  opacity: 0;
  visibility: hidden;
}

.single-social-box:hover .shape img:nth-child(2) {
  opacity: 1;
  visibility: visible;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion-tab .tabs {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 60px;
  text-align: center;
}

.faq-accordion-tab .tabs li {
  margin-left: 10px;
  margin-right: 10px;
}

.faq-accordion-tab .tabs li a {
  color: var(--whiteColor);
  text-decoration: none;
  border-radius: 5px;
  padding: 20px;
  display: block;
  text-transform: uppercase;
  background-color: #22152c;
  letter-spacing: 1px;
  font-size: 19px;
  font-weight: 600;
}

.faq-accordion-tab .tabs li a i {
  font-size: 30px;
  font-weight: 400;
}

.faq-accordion-tab .tabs li a span {
  display: block;
  margin-top: 8px;
}

.faq-accordion-tab .tabs li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.faq-accordion-tab .tabs li.current a {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
  border-radius: 5px;
  display: block;
  background: #22152c;
  margin-bottom: 15px;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-title {
  padding: 20px 25px 17px 60px;
  text-decoration: none;
  position: relative;
  color: var(--whiteColor);
  text-transform: uppercase;
  display: block;
  letter-spacing: 1px;
  font-size: 21px;
  font-weight: 600;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  left: 25px;
  top: 20px;
  font-size: 25px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.faq-accordion .accordion .accordion-title.active i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: -5px;
  padding-bottom: 20px;
  padding-right: 25px;
  padding-left: 60px;
}

.faq-accordion .accordion .accordion-content p {
  line-height: 1.8;
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}

.tab .tabs-item {
  display: none;
}

.tab .tabs-item:first-child {
  display: block;
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
  margin-top: 35px;
}

.pagination-area .page-numbers {
  width: 38px;
  height: 38px;
  background-color: var(--whiteColor);
  color: var(--blackColor);
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  line-height: 41px;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 20px;
  font-weight: 600;
}

.pagination-area .page-numbers:hover, .pagination-area .page-numbers.current {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.pagination-area .page-numbers i {
  position: relative;
  top: 2.2px;
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
  padding-left: 15px;
}

.widget-area.widget-left-sidebar {
  padding-right: 15px;
  padding-left: 0;
}

.widget-area .widget {
  margin-bottom: 40px;
}

.widget-area .widget:last-child {
  margin-bottom: 0;
}

.widget-area .widget .widget-title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 6px;
  text-transform: uppercase;
  border-bottom: 1px solid #1f1625;
  letter-spacing: 1px;
  font-size: 23px;
  font-weight: 600;
}

.widget-area .widget .widget-title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: var(--mainColor);
}

.widget-area .widget_search form {
  position: relative;
}

.widget-area .widget_search form .screen-reader-text {
  display: none;
}

.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}

.widget-area .widget_search form .search-field {
  height: 50px;
  color: var(--whiteColor);
  background-color: #22152c;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 2px 0 0 15px;
  border: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-family: var(--fontFamily3);
  font-size: 14px;
  font-weight: 500;
}

.widget-area .widget_search form .search-field::-webkit-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field:-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field::-ms-input-placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field::placeholder {
  color: var(--whiteColor);
  text-transform: uppercase;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  letter-spacing: 1px;
}

.widget-area .widget_search form .search-field:focus::-webkit-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus:-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::-ms-input-placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}

.widget-area .widget_search form .search-field::-webkit-search-cancel-button {
  display: none;
}

.widget-area .widget_search form button {
  border: none;
  background-color: transparent;
  color: var(--whiteColor);
  height: 40px;
  width: 40px;
  position: absolute;
  right: 5px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 5px;
  font-size: 20px;
}

.widget-area .widget_search form button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  border-radius: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.widget-area .widget_zelda_posts_thumb {
  position: relative;
  overflow: hidden;
}

.widget-area .widget_zelda_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}

.widget-area .widget_zelda_posts_thumb .item:last-child {
  margin-bottom: 0;
}

.widget-area .widget_zelda_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(../images/main-blog-img1.jpg);
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(../images/main-blog-img2.jpg);
}

.widget-area .widget_zelda_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(../images/main-blog-img3.jpg);
}

.widget-area .widget_zelda_posts_thumb .item .thumb::before, .widget-area .widget_zelda_posts_thumb .item .thumb::after {
  -webkit-transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: '';
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.widget-area .widget_zelda_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}

.widget-area .widget_zelda_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}

.widget-area .widget_zelda_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}

.widget-area .widget_zelda_posts_thumb .item .info span {
  display: block;
  color: var(--whiteColor);
  text-transform: uppercase;
  margin-top: -2px;
  margin-bottom: 8px;
  font-size: 15px;
}

.widget-area .widget_zelda_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 600;
}

.widget-area .widget_zelda_posts_thumb .item .info .title a {
  display: inline-block;
}

.widget-area .widget_zelda_posts_thumb .item:hover .thumb::before, .widget-area .widget_zelda_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}

.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  padding-left: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 16px;
}

.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
  background: var(--mainColor);
  height: 8px;
  width: 8px;
  content: '';
  border-radius: 50%;
  left: 0;
  top: 7px;
  position: absolute;
}

.widget-area .widget_categories ul li a {
  display: inline-block;
}

.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 22px;
}

.widget-area .tagcloud a {
  display: inline-block;
  background: #f5f5f5;
  color: var(--blackColor);
  padding: 8px 15px 5px;
  border: none;
  border-radius: 3px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px !important;
  margin-top: 8px;
  margin-right: 4px;
}

.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.widget-area .widget_match_list {
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
}

.widget-area .widget_match_list .single-match-list {
  text-align: center;
  position: relative;
  border-bottom: 1px solid #252525;
  padding-top: 22px;
  padding-bottom: 15px;
}

.widget-area .widget_match_list .single-match-list:last-child {
  border-bottom: none;
}

.widget-area .widget_match_list .single-match-list img {
  max-width: 60px;
  position: absolute;
}

.widget-area .widget_match_list .single-match-list img.team-1 {
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-area .widget_match_list .single-match-list img.team-2 {
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.widget-area .widget_match_list .single-match-list .date {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}

.widget-area .widget_match_list .single-match-list ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.widget-area .widget_match_list .single-match-list ul li {
  display: inline-block;
  line-height: 1;
  position: relative;
  font-size: 40px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
}

.widget-area .widget_match_list .single-match-list ul li::before {
  content: ':';
  position: absolute;
  right: -20px;
  top: 5px;
  font-size: 25px;
}

.widget-area .widget_match_list .single-match-list ul li:first-child {
  margin-left: 0;
}

.widget-area .widget_match_list .single-match-list ul li:last-child {
  margin-right: 0;
}

.widget-area .widget_match_list .single-match-list ul li:last-child::before {
  display: none;
}

.widget-area .widget_instagram ul {
  padding-left: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px;
}

.widget-area .widget_instagram ul li {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
}

.widget-area .widget_instagram ul li .box {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.widget-area .widget_instagram ul li .box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.widget-area .widget_instagram ul li .box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.widget-area .widget_instagram ul li .box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-area .widget_instagram ul li .box::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

.widget-area .widget_instagram ul li .box:hover::before {
  opacity: 0.5;
  visibility: visible;
}

.widget-area .widget_instagram ul li .box:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.widget-area .widget_instagram ul li .box:hover i {
  opacity: 1;
  visibility: visible;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table table {
  margin-bottom: 0;
}

.cart-table table thead tr {
  border-color: #2e2e2e;
}

.cart-table table thead tr th {
  border-bottom-width: 0px;
  vertical-align: middle;
  padding: 25px 0 25px;
  text-transform: uppercase;
  color: var(--whiteColor);
  border: none;
  white-space: nowrap;
  font-size: 20px;
  font-weight: 700;
}

.cart-table table tbody tr {
  border-color: #2e2e2e;
}

.cart-table table tbody tr td {
  vertical-align: middle;
  color: var(--whiteColor);
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  padding-left: 0;
  padding-right: 0;
  border-color: #22152c;
  border-left: none;
  border-right: none;
}

.cart-table table tbody tr td.product-thumbnail a {
  display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
  width: 80px;
}

.cart-table table tbody tr td.product-name a {
  display: inline-block;
}

.cart-table table tbody tr td.product-subtotal .remove {
  color: red;
  float: right;
  position: relative;
  top: -1px;
  font-size: 18px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
  max-width: 130px;
  min-width: 130px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
  position: absolute;
  top: 0;
  background-color: #22152c;
  cursor: pointer;
  color: var(--whiteColor);
  width: 40px;
  height: 100%;
  line-height: 56px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
  left: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
  right: 0;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input {
  height: 45px;
  color: var(--whiteColor);
  outline: 0;
  display: block;
  border: none;
  background-color: #000000;
  text-align: center;
  width: 100%;
  padding-top: 4px;
  font-size: 17px;
  font-weight: 600;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
  color: var(--whiteColor);
}

.cart-table table tbody tr td.product-subtotal {
  overflow: hidden;
}

.cart-table .table > :not(:first-child) {
  border-top: none;
}

.cart-buttons {
  margin-top: 30px;
}

.cart-buttons .shopping-coupon-code {
  position: relative;
  max-width: 530px;
}

.cart-buttons .shopping-coupon-code button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  background: var(--whiteColor);
  color: var(--blackColor);
  border: none;
  padding: 0 25px;
  line-height: 55px;
  outline: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  font-size: 17px;
  font-weight: 600;
}

.cart-buttons .shopping-coupon-code button:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.cart-totals {
  background: #22152c;
  padding: 40px;
  max-width: 500px;
  border-radius: 5px;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
}

.cart-totals h3 {
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.cart-totals ul {
  padding-left: 0;
  margin: 0 0 25px;
  list-style-type: none;
}

.cart-totals ul li {
  border: 1px solid #2a2828;
  padding: 15px 15px 10px;
  color: var(--whiteColor);
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.cart-totals ul li:first-child {
  border-bottom: none;
}

.cart-totals ul li:last-child {
  font-size: 22px;
  border-top: none;
}

.cart-totals ul li:last-child span {
  color: var(--whiteColor);
  font-weight: 600;
}

.cart-totals ul li span {
  float: right;
  color: var(--whiteColor);
  font-weight: normal;
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  padding: 15px 20px;
  border-top: 3px solid var(--mainColor);
  position: relative;
  margin-bottom: 40px;
}

.user-actions i {
  margin-right: 2px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.user-actions span {
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
}

.user-actions span a {
  display: inline-block;
}

.billing-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #22152c;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.billing-details .title::before {
  content: '';
  position: absolute;
  background: var(--mainColor);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.billing-details .form-group {
  margin-bottom: 25px;
}

.billing-details .form-group label {
  display: block;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.billing-details .form-group label .required {
  color: red;
}

.billing-details .form-group select {
  background-color: #22152c;
  border: none;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  color: var(--whiteColor);
  padding-top: 2.5px;
  cursor: pointer;
}

.billing-details .form-group .form-control {
  color: var(--whiteColor);
}

.billing-details .form-check {
  margin-bottom: 20px;
}

.billing-details .form-check .form-check-label {
  color: var(--whiteColor);
  font-weight: 500;
  letter-spacing: 1px;
}

.billing-details .form-check label {
  position: relative;
  left: -3px;
  top: 0;
  font-weight: 500;
}

.billing-details .col-lg-12:last-child .form-group {
  margin-bottom: 0;
}

.order-details .title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #22152c;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.order-details .title::before {
  content: '';
  position: absolute;
  background: var(--mainColor);
  bottom: -1px;
  left: 0;
  width: 50px;
  height: 1px;
}

.order-details .order-table table {
  margin-bottom: 0;
}

.order-details .order-table table thead tr {
  border-color: #2e2e2e;
}

.order-details .order-table table thead tr th {
  border-bottom-width: 0;
  vertical-align: middle;
  border-color: #22152c;
  padding: 14px 20px 10px;
  color: var(--whiteColor);
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
}

.order-details .order-table table tbody {
  border-color: #2e2e2e;
}

.order-details .order-table table tbody tr td {
  vertical-align: middle;
  color: #bab9bb;
  white-space: nowrap;
  border-color: #22152c;
  font-size: 16.5px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 12px;
}

.order-details .order-table table tbody tr td.product-name a {
  display: inline-block;
  color: #bab9bb;
}

.order-details .order-table table tbody tr td.product-name a:hover {
  color: var(--mainColor);
}

.order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
  color: var(--whiteColor);
  text-transform: uppercase;
  font-weight: 600;
  font-size: var(--fontSize);
}

.order-details .order-table table tbody tr td.shipping-price, .order-details .order-table table tbody tr td.order-subtotal-price, .order-details .order-table table tbody tr td.product-subtotal {
  color: var(--whiteColor);
  font-weight: 600;
  font-size: var(--fontSize);
}

.order-details .order-table .table > :not(:first-child) {
  border-top: none;
}

.order-details .payment-box {
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  margin-top: 30px;
  padding: 30px;
}

.order-details .payment-box .payment-method p [type="radio"]:checked, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) {
  display: none;
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label {
  padding-left: 27px;
  cursor: pointer;
  display: block;
  color: var(--whiteColor);
  position: relative;
  margin-bottom: 8px;
  font-weight: 600;
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::before, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  height: 18px;
  border: 1px solid #dddddd;
  border-radius: 50%;
  background: var(--whiteColor);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--mainColor);
  position: absolute;
  top: 7px;
  left: 3px;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.order-details .payment-box .payment-method p [type="radio"]:checked + label::after {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.order-details .payment-box .default-btn {
  margin-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info {
  padding-right: 15px;
}

.contact-info .sub-title {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}

.contact-info h2 {
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 50px;
  font-weight: 600;
}

.contact-info ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 35px;
  margin-bottom: 0;
}

.contact-info ul li {
  position: relative;
  margin-bottom: 35px;
  padding-left: 100px;
}

.contact-info ul li .icon {
  width: 75px;
  height: 85px;
  background-color: #22152c;
  border-radius: 3px;
  position: absolute;
  text-align: center;
  left: 0;
  font-size: 45px;
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 0;
}

.contact-info ul li .icon i {
  position: absolute;
  left: 0;
  right: 0;
  top: 55%;
  line-height: 1;
  -webkit-transform: translateY(-55%);
          transform: translateY(-55%);
}

.contact-info ul li h3 {
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: 600;
}

.contact-info ul li p {
  font-weight: 500;
  max-width: 300px;
  margin-bottom: 0;
  margin-top: 2px;
}

.contact-info ul li .social-box {
  margin-top: 15px;
}

.contact-info ul li .social-box a {
  display: inline-block;
  font-size: 25px;
  margin-right: 7px;
}

.contact-info ul li .social-box a:last-child {
  margin-right: 0;
}

.contact-info ul li:hover .icon {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.contact-info ul li:last-child {
  margin-bottom: 0;
}

.contact-form {
  overflow: hidden;
  padding: 35px;
  margin-left: 15px;
  border-radius: 5px;
  background-color: var(--mainColor);
  background-image: url(../images/contact-bg.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-form h2 {
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 50px;
  font-weight: 600;
}

.contact-form p {
  margin-top: 5px;
  margin-bottom: 0;
}

.contact-form form {
  margin-top: 30px;
}

.contact-form form .form-group {
  margin-bottom: 15px;
}

.contact-form form input, .contact-form form textarea {
  display: block;
  width: 100%;
  border: none;
  color: var(--whiteColor);
  background-color: var(--blackColor);
  height: 50px;
  padding-left: 15px;
  padding-top: 2px;
  padding-right: 0;
  padding-bottom: 0;
  border-radius: 5px;
  font-size: 16.5px;
  font-weight: 500;
}

.contact-form form input::-webkit-input-placeholder, .contact-form form textarea::-webkit-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input:-ms-input-placeholder, .contact-form form textarea:-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input::-ms-input-placeholder, .contact-form form textarea::-ms-input-placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input::placeholder, .contact-form form textarea::placeholder {
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.contact-form form input:focus::-webkit-input-placeholder, .contact-form form textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.contact-form form input:focus:-ms-input-placeholder, .contact-form form textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.contact-form form input:focus::-ms-input-placeholder, .contact-form form textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.contact-form form input:focus::placeholder, .contact-form form textarea:focus::placeholder {
  color: transparent;
}

.contact-form form textarea {
  height: auto !important;
  padding-top: 15px;
}

.contact-form form .default-btn {
  margin-top: 10px;
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

.contact-form form .default-btn::before {
  background-color: var(--blackColor);
}

.contact-form form .default-btn:hover {
  color: var(--whiteColor);
}

.contact-form form .help-block ul {
  margin-bottom: 0;
  margin-top: 12px;
}

.contact-form form .help-block ul li {
  color: var(--whiteColor);
}

.contact-form form #msgSubmit {
  color: var(--whiteColor) !important;
  margin: 0 !important;
}

.contact-form form #msgSubmit.text-danger, .contact-form form #msgSubmit.text-success {
  margin-top: 15px !important;
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
  padding: 40px 50px;
  border-radius: 5px;
  background-color: #22152c;
  -webkit-box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
          box-shadow: 0 10px 27px 0 rgba(14, 8, 18, 0.5);
  margin-right: 30px;
}

.login-form h2 {
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 40px;
  font-weight: 600;
}

.login-form form .form-group {
  margin-bottom: 25px;
}

.login-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.login-form form .form-group .form-control {
  background-color: #000000 !important;
}

.login-form form .remember-me-wrap {
  margin-bottom: 0;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) {
  display: none;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--whiteColor);
  font-weight: 600;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  background: #f5f5f5;
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after, .login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--mainColor);
  position: absolute;
  top: 5.5px;
  left: 6px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.login-form form .remember-me-wrap [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.login-form form .remember-me-wrap [type="checkbox"]:hover + label:before {
  border-color: var(--mainColor);
}

.login-form form .remember-me-wrap [type="checkbox"]:checked + label:before {
  border-color: var(--mainColor);
}

.login-form form .lost-your-password-wrap {
  text-align: right;
}

.login-form form .lost-your-password-wrap a {
  display: inline-block;
  position: relative;
  line-height: 1.3;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
}

.login-form form .lost-your-password-wrap a::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: #eeeeee;
}

.login-form form .lost-your-password-wrap a::after {
  width: 0;
  height: 1px;
  position: absolute;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  bottom: 0;
  content: '';
  background-color: var(--mainColor);
}

.login-form form .lost-your-password-wrap a:hover::before {
  width: 0;
}

.login-form form .lost-your-password-wrap a:hover::after {
  width: 100%;
}

.login-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 16px 30px 12px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
}

.login-form form button:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

.register-form {
  padding-left: 30px;
}

.register-form h2 {
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 40px;
  font-weight: 600;
}

.register-form form .form-group {
  margin-bottom: 25px;
}

.register-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.register-form form .description {
  font-style: italic;
  font-size: 13.5px;
  margin-top: -10px;
  margin-bottom: 0;
}

.register-form form button {
  margin-top: 22px;
  border: none;
  display: block;
  text-align: center;
  overflow: hidden;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 16px 30px 12px;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 20px;
}

.register-form form button:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding-bottom: 50px;
}

.footer-area.ptb-70 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-content {
  text-align: center;
}

.footer-content .footer-menu {
  padding-left: 0;
  list-style-type: none;
  margin-top: 40px;
  margin-bottom: 0;
}

.footer-content .footer-menu li {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.footer-content .footer-menu li a {
  display: inline-block;
  text-transform: uppercase;
  padding: 0;
  color: var(--whiteColor);
  font-size: 18px;
  font-weight: 500;
}

.footer-content .footer-menu li a:hover {
  color: var(--mainColor);
}

.footer-content .footer-menu li:first-child {
  margin-left: 0;
}

.footer-content .footer-menu li:last-child {
  margin-right: 0;
}

.copyright-area {
  border-top: 1px solid #1a0c09;
  padding-top: 30px;
  padding-bottom: 30px;
}

.copyright-area p {
  text-transform: uppercase;
  line-height: initial;
  text-transform: uppercase;
  font-size: var(--fontSize);
  font-family: var(--fontFamily1);
  color: white;
}

.copyright-area p i {
  position: relative;
  top: 2.3px;
}

.copyright-area p a {
  color: var(--mainColor);
  position: relative;
  font-weight: 600;
}

.copyright-area p a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 4px;
  right: 0;
  height: 1px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 0;
}

.copyright-area p a:hover::before {
  width: 100%;
}

.copyright-area .lang-switcher {
  text-align: right;
  position: relative;
}

.copyright-area .lang-switcher span {
  display: inline-block;
  margin-right: 15px;
  text-transform: uppercase;
  font-size: var(--fontSize);
}

.copyright-area .lang-switcher select {
  background-color: transparent;
  cursor: pointer;
  color: var(--whiteColor);
  width: 145px;
  max-width: 145px;
  padding: 10px 15px 5px 10px;
  border: 1px solid var(--whiteColor);
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--fontSize);
}

.copyright-area .lang-switcher select option {
  color: var(--blackColor);
  outline: 0 !important;
  cursor: pointer;
  border: none;
}

.copyright-area .lang-switcher::before {
  font-family: Flaticon;
  content: "\f105";
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}

.footer-area-bg-image {
  background-image: url(../images/footer-bg.png);
  background-position: bottom left;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-style-two {
  background-color: #1b060a;
  padding-top: 100px;
  position: relative;
  z-index: 1;
}

.footer-map {
  position: absolute;
  right: 20%;
  top: 40%;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  z-index: -1;
  opacity: .8;
}

.footer-map img {
  -webkit-animation: moveleftbounce 5s linear infinite;
          animation: moveleftbounce 5s linear infinite;
}

.single-footer-widget {
  margin-bottom: 30px;
}

.single-footer-widget .logo {
  margin-bottom: 15px;
  font-size: 15px;
  display: inline-block;
}

.single-footer-widget p {
  color: #d0c6c6;
}

.single-footer-widget .social-link {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 20px;
}

.single-footer-widget .social-link li {
  display: inline-block;
  margin-right: 5px;
}

.single-footer-widget .social-link li:last-child {
  margin-right: 0;
}

.single-footer-widget .social-link li a {
  width: 34px;
  height: 34px;
  text-align: center;
  color: var(--whiteColor);
  background-color: #0c0305;
  font-size: 20px;
  position: relative;
  border-radius: 2px;
}

.single-footer-widget .social-link li a i {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-48%);
          transform: translateY(-48%);
}

.single-footer-widget .social-link li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}

.single-footer-widget h3 {
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600;
}

.single-footer-widget .footer-links-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  font-family: var(--fontFamily3);
}

.single-footer-widget .footer-links-list li {
  margin-bottom: 12px;
  color: #d0c6c6;
  font-size: 16px;
}

.single-footer-widget .footer-links-list li a {
  color: #d0c6c6;
  display: inline-block;
}

.single-footer-widget .footer-links-list li a:hover {
  color: var(--mainColor);
}

.single-footer-widget .footer-links-list li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-contact-info {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  font-family: var(--fontFamily3);
}

.single-footer-widget .footer-contact-info li {
  margin-bottom: 14px;
  color: #d0c6c6;
  position: relative;
  padding-left: 24px;
  font-size: 16px;
}

.single-footer-widget .footer-contact-info li a {
  color: #d0c6c6;
  display: inline-block;
}

.single-footer-widget .footer-contact-info li a:hover {
  color: var(--mainColor);
}

.single-footer-widget .footer-contact-info li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .footer-contact-info li i {
  position: absolute;
  left: 0;
  color: var(--mainColor);
  top: 1px;
  font-size: 18px;
}

.footer-bottom-area {
  margin-top: 70px;
  border-top: 1px solid #220f13;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer-bottom-area p {
  text-transform: uppercase;
  line-height: initial;
  text-transform: uppercase;
  font-size: var(--fontSize);
  font-family: var(--fontFamily1);
}

.footer-bottom-area p i {
  position: relative;
  top: 2.3px;
}

.footer-bottom-area p a {
  color: var(--mainColor);
  position: relative;
  font-weight: 600;
}

.footer-bottom-area p a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 4px;
  right: 0;
  height: 1px;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 0;
}

.footer-bottom-area p a:hover::before {
  width: 100%;
}

.footer-bottom-area ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area ul li {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  text-transform: uppercase;
}

.footer-bottom-area ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area ul li:last-child::before {
  display: none;
}

.footer-bottom-area ul li::before {
  content: '';
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: var(--whiteColor);
}

.footer-bottom-area ul li a {
  display: block;
}

@-webkit-keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes moveleftbounce {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  z-index: 4;
  width: 43px;
  text-align: center;
  height: 45px;
  opacity: 0;
  visibility: hidden;
  font-size: 27px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
}

.go-top i {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.go-top.active {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}

.go-top:hover {
  background-color: var(--whiteColor);
  color: var(--blackColor);
}

/*================================================
Sidebar Modal CSS
=================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  background-color: var(--blackColor);
  border: none;
  border-radius: 0;
}

.modal.right .modal-content button.close {
  float: unset;
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--whiteColor);
  z-index: 2;
  opacity: 1;
  border: none;
  text-shadow: unset;
  -webkit-box-shadow: unset;
          box-shadow: unset;
  font-size: 40px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: transparent;
}

.modal.right .modal-content button.close:hover {
  color: red;
}

.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.sidebarModal.modal .modal-body {
  text-align: center;
  padding: 80px 30px;
}

.sidebarModal.modal .modal-body .instagram-list {
  margin-top: 70px;
}

.sidebarModal.modal .modal-body .instagram-list .row {
  margin-left: -5px;
  margin-right: -5px;
}

.sidebarModal.modal .modal-body .instagram-list .row .col-lg-4 {
  padding-left: 5px;
  padding-right: 5px;
}

.sidebarModal.modal .modal-body .instagram-list .box {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 10px;
}

.sidebarModal.modal .modal-body .instagram-list .box .link-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.sidebarModal.modal .modal-body .instagram-list .box i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.sidebarModal.modal .modal-body .instagram-list .box img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sidebarModal.modal .modal-body .instagram-list .box::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--mainColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}

.sidebarModal.modal .modal-body .instagram-list .box:hover::before {
  opacity: 0.5;
  visibility: visible;
}

.sidebarModal.modal .modal-body .instagram-list .box:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.sidebarModal.modal .modal-body .instagram-list .box:hover i {
  opacity: 1;
  visibility: visible;
}

.sidebarModal.modal .modal-body .sidebar-contact-info {
  margin-top: 80px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info h2 {
  margin-bottom: 0;
  letter-spacing: 1px;
  font-size: 30px;
}

.sidebarModal.modal .modal-body .sidebar-contact-info h2 span {
  display: block;
  font-size: var(--fontSize);
  margin-top: 8px;
  margin-bottom: 8px;
}

.sidebarModal.modal .modal-body .social-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;
}

.sidebarModal.modal .modal-body .social-list li {
  display: inline-block;
  margin-right: 8px;
}

.sidebarModal.modal .modal-body .social-list li span {
  display: block;
  text-transform: uppercase;
  color: var(--mainColor);
  font-size: var(--fontSize);
  font-weight: 400;
}

.sidebarModal.modal .modal-body .social-list li a {
  position: relative;
  top: 1px;
  color: var(--whiteColor);
}

.sidebarModal.modal .modal-body .social-list li a:hover {
  color: var(--mainColor);
}

.sidebarModal.modal .modal-body .social-list li:last-child {
  margin-right: 0;
}

.buy-now-btn {
  left: 30px;
  bottom: 30px;
  z-index: 333;
  position: fixed;
  border-radius: 30px;
  color: var(--whiteColor);
  background-color: #81b441;
  padding: 10px 20px 10px 40px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: var(--fontFamily3);
}

.buy-now-btn img {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  width: 17px;
  left: 15px;
  top: 50%;
}

.buy-now-btn:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  color: var(--whiteColor);
}

.dark-version-btn {
  top: 1px;
  margin-left: 30px;
  position: relative;
}

.dark-version-btn .switch {
  width: 44px;
  height: 44px;
  position: relative;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}

.dark-version-btn .switch input {
  width: 0;
  height: 0;
  opacity: 0;
}

.dark-version-btn .slider {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  color: var(--whiteColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: #3d4152;
}

.dark-version-btn .slider:before {
  left: 0;
  top: 50%;
  right: 0;
  content: "\ee4e";
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 22px;
  font-family: 'boxicons';
}

.dark-version-btn .slider.round {
  border-radius: 50%;
}

.dark-version-btn .slider.round:before {
  border-radius: 50%;
}

.dark-version-btn input:checked + .slider:before {
  content: "\eebe";
}
button.img-btn {
  padding: 0 !important;
  margin: 38px 0;
}
.iframe{
width: 500px;
}
.radius{
  border-radius: 15px;
}
.upper-games {
  overflow: hidden !important;
  padding-top: 35px !important;
}

button.img-btn img {
  animation: animName 4s linear infinite;
  width: 550px;
  /* height: 550px; */
  padding: 2rem;
  margin: -52px 0 -40px 0;
}
.upper-games .btn-box.d-flex {
  margin-top: -35px;
}
@keyframes animName {
  0%{
     transform: rotate(0deg);
    }
    25%{
      transform: rotate(-45deg);
     }
    50%{
      transform: rotate(0deg);
     }
     
     75%{
      transform: rotate(180deg);
     }
 100%{
     transform: rotate(360deg);
    }
 }

@media only screen and (max-width: 768px) {
button.img-btn img {
  /* width: 350px;
  height: 350px; */
}
.upper-games {
  overflow: hidden !important;
  padding-top: 35px !important;
}

}

@media only screen and (max-width: 375px) {

  button.img-btn img {
    /* width: 250px;
    height: 250px; */
  }
  button.img-btn {
    margin-top: 50px;
    margin-bottom: 30px;
}
  }
  @media screen and (max-width: 767px){
    .single-live-stream-item .video-btn {
      font-size: 12px;
      height: 25px;
      line-height: 25px;
      right: 30px;
      top: 10px;
      width: 25px;
  }
  .streams-list {
    max-height: 300px;
    overflow-y: auto;
}
  }
 @media only screen and (max-width: 992px){
    img.sudoku-img {
      max-width: 222px;
    }
}

  @media only screen and (max-width: 768px){
    .score-change .single-live-stream-item .content {
      bottom: 0!important;
      left: 175px!important;
      padding: 30px 10px 25px 16px;
      position: absolute;
      z-index: 2;
  }
  .single-live-stream-box img {
    width: 624px !important;
    height: 145px !important;
  }
  .single-live-stream-box .content h3 {
      font-size: 12px !important;
  } 
  .single-live-stream-box .content {
      padding: 5px !important;
  }
  .single-live-stream-box .content h3 i {
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      margin-left: 10px;
  }
  h3.rating1 {
    width: 40px;
    margin-top: -24px;
    height: 20px;
    background: red;
    border-radius: 0;
    text-align: center;
    display: flex !important;
    padding: 0;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    justify-content: center;
    align-items: center;
}
img.g-image2 {
    position: absolute;
    bottom: 51px;
    z-index: 1;
    left: 27px;
    max-width: 136px;
    width: 100%;
  }
img.g-image3 {
    max-width: 105px;
    position: absolute;
    bottom: 2px;
    left: 107px;
  }
  img.sudoku-img {
      max-width: 114px;
  }
  img.g-image1 {
      max-width: 108px;
  }

}
@media only screen and (max-width: 600px){
  .g-inner-box2 {
      position: absolute;
      top: -12px;
      right: 26px;
  }
  .g-inner-box3 h2 {
      font-size: 32px;
      color: #FFF;
      text-transform: uppercase;
      margin-top: 180px;
      margin-left: 35%;
  }
  img.spin-text-img {
      max-width: 163px;
      position: absolute;
      bottom: 49px;
      left: 78px;
      transform: rotate(1deg);
      z-index: 999;
  }
}
/*# sourceMappingURL=style.css.map */






/* FIXING STYLES CSS... */

#navbar{
  display: block !important;
  width:100% !important;
  position: relative !important;
}

.container{
  width: 100% !important;
  position: relative !important;
}

.zelda-responsive-nav .logo img{
  display: none !important;
}

.page-title-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#music{
  display:none !important;
}

@media screen and (max-width:1199px){
  .zelda-responsive-nav .logo img{
    display: block !important;
  }
  #music{
    display:flex !important;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 24px !important;
  }
}

@media screen and (min-width:700px){
  .text-wrapper h2{
    font-size: 80px !important;
    top: unset !important;
  }
}

@media screen and (min-width:1000px){
  .text-wrapper h2{
    font-size: 100px !important;
    top: -10px !important;
  }
}

@media screen and (min-width:1200px){
  .text-wrapper h2{
    font-size: 130px !important;
    top: unset !important;
  }
}

